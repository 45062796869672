import React, { useState, useEffect } from "react";
import "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextArea from "web/components/form-elements/TextArea";
import NumberInput from "web/components/form-elements/NumberInput";
import InputBox from "web/components/TextInput";
import CheckboxInput from "web/components/form-elements/CheckboxInput";
import { propertyAutoSave } from "redux/actions/property";
import AlertModal from "web/components/AlertModal";
import SelectField from "web/components/form-elements/SelectField";
import BackIcon from "web/assets/images/backIcon.png";
import { MainSectionTwo } from "web/pages/private/PropertyForm/style";
import PlusIcon from "web/assets/svg/PlusIcon.js";
import MinusIcon from "web/assets/svg/MinusIcon.js";
import Spinner from "web/components/Spinner";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core/";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import ReactCalender from "web/components/form-elements/Calender";
import { customTheme } from "web/components/form-elements/Calender";
import BackButton from "web/assets/svg/BackButton.js";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#e45829 !important",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#e45829",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#e45829",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#e45829",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#e45829",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#e45829",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#e45829",
      },
      noPoint: {
        backgroundColor: "#e45829",
      },
      thumb: {
        border: "14px solid #e45829",
      },
    },
  },
});

const Step1 = ({
  props,
  userRole,
  handleBack,
  callBackFun,
  addressData,
  errors,
  comparalableLinks,
}) => {
  const { setFieldValue } = props;
  const [modal, setModal] = useState(false);
  const [getSimilarProperties, setSimilarProperties] =
    useState(comparalableLinks);
  const [randomNo, setRandomNo] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const toggleModal = () => setModal(!modal);
  let title = "";
  const dispatch = useDispatch();

  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);

  let fixAndFlip = [
    {
      label: "Light Rehab",
      value: "lightRehab",
      id: "0",
    },
    {
      label: "Remodel",
      value: "remodel",
      id: "1",
    },
    {
      label: "Remodel + Addition",
      value: "remodelAddition",
      id: "2",
    },
    {
      label: "Remodel + ADU",
      value: "remodelADU",
      id: "3",
    },
    {
      label: "New Construction",
      value: "newConstruction",
      id: "4",
    },
  ];

  const goBack = () => {
    handleBack(props);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formAutoSave = async (value) => {
    const allAddressData = addressData.allAddressData;
    let newPorpertyData = { ...value, ...allAddressData };
    const response = await dispatch(propertyAutoSave(newPorpertyData));
  };
  return (
    <MainSectionTwo>
      {loaderVisible && <Spinner />}
      <Grid className="MainGrid" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box className="loginWrapper">
          <Box className="backIcon">
            {/* <img onClick={goBack} src={BackIcon} alt="BackIcon" /> */}
            <BackButton onClick={goBack} />
          </Box>
          <div className="number_input">
            <Box>
              <h1 className="SubHeaderOne">Add Your Property Details</h1>
              <h2 className="SubHeaderTwo">Asking Price*</h2>
              <NumberInput
                thousandSeparator={true}
                onBlur={(e) => {
                  callBackFun("askingPrice", e);
                  formAutoSave({ askingPrice: e });
                }}
                name="askingPrice"
                placeholder=""
              />
            </Box>
            <Box>
              <h6 className="SubHeaderSecond">Fair Market Price(As-Is)*</h6>
              <NumberInput
                thousandSeparator={true}
                onBlur={(e) => {
                  callBackFun("fairMarketPrice", e);
                  formAutoSave({ fairMarketPrice: e });
                }}
                name="fairMarketPrice"
                placeholder=""
              />
            </Box>
            <Box>
              <h6 className="SubHeaderSecond">After Repair Value*</h6>
              <NumberInput
                thousandSeparator={true}
                onBlur={(e) => {
                  callBackFun("afterRepairValue", e);
                  formAutoSave({ afterRepairValue: e });
                }}
                name="afterRepairValue"
                placeholder=""
              />
            </Box>

            <Box>
              <h6 className="SubHeaderSecond">Address For Comparable Homes</h6>
              {getSimilarProperties.map((item, index) => {
                let val = item?.length ? item : "";
                return (
                  <div className="similarLinkSection" key={index}>
                    <Box className="input_section_one">
                      <InputBox
                        name="similarLinks"
                        value={val}
                        onBlur={(e) => {
                          callBackFun("similarLinks", getSimilarProperties);
                          formAutoSave({ similarLinks: e });
                        }}
                        onChange={(e) => {
                          getSimilarProperties[index] = e.target.value;
                          setRandomNo(Math.random());
                          setSimilarProperties(getSimilarProperties);
                          setFieldValue("similarLinks", getSimilarProperties);
                        }}
                      />

                      <div
                        className="Circle"
                        onClick={() => {
                          if (getSimilarProperties.length < 2) {
                            return;
                          }
                          let newProperties = getSimilarProperties.splice(
                            index,
                            1
                          );
                          setRandomNo(Math.random());
                          setSimilarProperties(getSimilarProperties);
                          setFieldValue("similarLinks", getSimilarProperties);
                        }}
                      >
                        <MinusIcon />
                      </div>
                      <div
                        className="AddCircle"
                        onClick={() => {
                          if (getSimilarProperties.length > 5) {
                            return;
                          }
                          setSimilarProperties((getSimilarProperties) => [
                            ...getSimilarProperties,
                            "",
                          ]);
                          setRandomNo(Math.random());
                        }}
                      >
                        <PlusIcon />
                      </div>
                    </Box>
                    {errors && errors?.similarLinks?.length && (
                      <h6 className="error-message">
                        {errors?.similarLinks[index]}
                      </h6>
                    )}
                  </div>
                );
              })}
            </Box>

            <Box>
              <h6 className="SubHeaderSecond">Suggested Rehab</h6>
              <TextArea
                onBlur={(e) => {
                  callBackFun("suggestedRehab", e);
                  formAutoSave({ suggestedRehab: e });
                }}
                rows="5"
                name="suggestedRehab"
                placeholder=""
              />
            </Box>

            <Box>
              <h6 className="SubHeaderSecond">Estimated Rehab Cost*</h6>
              <NumberInput
                thousandSeparator={true}
                onBlur={(e) => {
                  callBackFun("estimatedRehabCost", e);
                  formAutoSave({ estimatedRehabCost: e });
                }}
                name="estimatedRehabCost"
                placeholder=""
              />
            </Box>

            {(userRole === "AGENT" || userRole === "OTHER") && (
              <Box>
                <h6 className="SubHeaderSecond">Strategy*</h6>
                <SelectField
                  onBlur={(e) => {
                    callBackFun("fixAndFlip", e);
                    formAutoSave({ fixAndFlip: e });
                  }}
                  name="fixAndFlip"
                  data={fixAndFlip}
                  placeholder=""
                />
              </Box>
            )}
            <Box>
              <h6 className="SubHeaderSecond">Add Comments</h6>
              <TextArea
                onBlur={(e) => {
                  callBackFun("comments", e);
                  formAutoSave({ comments: e });
                }}
                rows="5"
                name="comments"
                placeholder=""
              />
            </Box>
            <h6 className="SubHeaderSecond">Offer Submission Deadline*</h6>
            <Box className="offerDeadlineSection">
              <Box className="ButtonsDateTimeTwo">
                <ThemeProvider theme={customTheme}>
                  <ReactCalender
                    placeholder="MM/DD/YY"
                    name="offerDeadLineDate"
                    onBlur={(e) => {
                      callBackFun("offerDeadLineDate", e);
                      formAutoSave({ offerDeadLineDate: e });
                    }}
                  />
                </ThemeProvider>
              </Box>
              <Box className="timeCard">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <TimePicker
                      onChange={(e) => {
                        let time = e.toLocaleTimeString("en-US");
                        setSelectedDate(e);
                        setFieldValue("offerDeadLineTime", time);
                      }}
                      margin="normal"
                      id="time-picker"
                      name="offerDeadLineTime"
                      value={selectedDate}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <div className="checkBox">
              <Box className="Checkbox_section">
                <CheckboxInput
                  name="cashOnlyRequest"
                  label="Cash Only Offer Request"
                  handleSelect={(e) => {
                    callBackFun("cashOnlyRequest", e);
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Grid>
      <AlertModal isOpen={modal} toggle={toggleModal} title={title} />
    </MainSectionTwo>
  );
};

export default Step1;
