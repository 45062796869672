import React, { useEffect, useState } from "react";
import { ButtonSection } from "web/components/MultiSelectButton/style";
import Button from "@material-ui/core/Button";

const MultiSelectButton = ({
  name,
  data,
  setValue,
  valueKey,
  labelKey,
  props,
  inputRef,
  onBlur = () => {},
}) => {
  const { validateForm } = props;
  const [selected, setSelected] = useState({
    type: props?.values?.type,
    occupancyStatus: props?.values?.occupancyStatus,
    poolInfo: props?.values?.poolInfo,
    carParking: props?.values?.carParking,
    propertyStatus: props?.values?.propertyStatus,
    firePlace: props?.values?.firePlace,
    airCondition: props?.values?.airCondition,
  });
  const [checked, setChecked] = useState({});

  useEffect(() => {
    setChecked({ [selected[name]]: true });
  }, []);
  useEffect(() => {
    if (name === "occupancyStatus" && selected.type === "VacantLand") {
      setChecked({ Vacant: true });
    }
  }, [selected.type]);

  useEffect(() => {
    setSelected({
      type: props?.values?.type,
      occupancyStatus: props?.values?.occupancyStatus,
      poolInfo: props?.values?.poolInfo,
      carParking: props?.values?.carParking,
      propertyStatus: props?.values?.propertyStatus,
      firePlace: props?.values?.firePlace,
      airCondition: props?.values?.airCondition,
    });
  }, [props.values]);

  return (
    <>
      <ButtonSection>
        {data.map((buttonName, index) => {
          return (
            <Button
              key={index}
              // ref={inputRef}
              onClick={() => {
                validateForm();
                setValue(
                  checked[buttonName?.value] ? "" : buttonName?.[valueKey]
                );
                onBlur(
                  checked[buttonName?.value] ? "" : buttonName?.[valueKey]
                );
                setChecked({
                  [buttonName?.value]: checked[buttonName?.value]
                    ? !checked[buttonName?.value]
                    : true,
                });
                // setValue(checked[buttonName?.value] ? '' : buttonName?.[valueKey]);
              }}
              className={checked[buttonName?.value] ? "selected" : "First_btn"}
            >
              {buttonName?.[labelKey]}
            </Button>
          );
        })}
      </ButtonSection>
    </>
  );
};

export default MultiSelectButton;
