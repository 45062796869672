import React from "react";
import Button from "web/components/Button";
import CloseIcon from "web/assets/svg/CrossIcon";
import { Modal, ModalBody } from "reactstrap";
import Decision from "web/assets/images/Decision.svg";
import {
	CongButton,
	CloseSection,
} from "web/components/submitPropertyConfirmation/style";

const PropertyModal = ({ isOpen, toggle, title, data, props }) => {
	const redirectLink = (link) => {
		toggle(!isOpen);
	};

	return (
		<>
			<div className="property-modal">
				<Modal
					isOpen={isOpen}
					toggle={toggle}
					backdrop={"static"}
					centered={true}
					className="propertyModal"
				>
					<div className="modal-content">
						<div className="header">
							<CloseSection>
								<CloseIcon
									onClick={() => {
										redirectLink("");
									}}
								></CloseIcon>
							</CloseSection>
						</div>
						<ModalBody>
							<div>
								<h3 className="modal-title">{title}</h3>
							</div>
							<div
								style={{ paddingTop: 40, paddingBottom: 70 }}
								className="comingSoon"
							>
								<img width="200px" src={Decision} alt="Decision" />

								<h2
									style={{
										paddingTop: 40,
										fontWeight: 600,
										fontSize: 25,
										fontColor: "#4F5270",
										width: "65%",
										margin: "0 auto",
										fontFamily: "poppins",
										textAlign: "center",
									}}
								>
									Once you submit you will not be able to make any changes.
									Confirm?
								</h2>
								<br />

								<CongButton>
									<Button
										type="app-orange-button submitBtn comingButtonFirst"
										handleClick={() => {
											redirectLink("");
										}}
									>
										No
									</Button>
									<Button
										type="app-orange-button submitBtn comingButton"
										handleClick={() => {
											data(props);
											redirectLink("");
										}}
									>
										Yes
									</Button>
								</CongButton>
							</div>
						</ModalBody>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default PropertyModal;
