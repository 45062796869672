import * as React from "react";

const BackButton = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
			<defs>
				<filter
					id="prefix__a"
					x={0}
					y={0}
					width={50}
					height={50}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="blur" />
					<feFlood floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="Component 41 \u2013 2">
				<g filter="url(#prefix__a)">
					<circle
						data-name="Ellipse 135"
						cx={18.5}
						cy={18.5}
						r={18.5}
						transform="translate(9 6)"
						fill="#e45829"
					/>
				</g>
				<path
					data-name="Icon ionic-ios-arrow-back"
					d="M23.849 24.278l6.266-6.262a1.184 1.184 0 00-1.676-1.671l-7.1 7.095a1.181 1.181 0 00-.035 1.632l7.135 7.144a1.184 1.184 0 101.676-1.671z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default BackButton;
