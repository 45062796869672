import { SET_THEME_DATA } from 'redux/actions/action_types';

const INITIAL_STATE = {
  appearanceMode: null,
};

const appearanceMode = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_THEME_DATA:
      return { ...state, appearanceMode: payload };
    default:
      return state;
  }
};
export default appearanceMode;
