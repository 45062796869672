import styled from 'styled-components';

const ModalSection = styled.div`
  .footer {
    margin-top: 50px;
    button {
      width: 100%;
      height: 50px;
    }
  }
`;

export { ModalSection };
