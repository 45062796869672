import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@material-ui/core/Box";
import Houses from "web/assets/images/HousesBro(2).svg";
import CloseIcon from "@material-ui/icons/Close";
import PdfLogo from "web/assets/images/pdfLogo.png";

const DragDrop = ({ props }) => {
  const { setFieldValue } = props;
  const [getFileValue, setFileValue] = useState([]);
  let arrayData = [];

  const onDrop = useCallback(
    (acceptedFiles) => {
      let isUniqueFile = getFileValue.filter((item) => {
        return (
          item.name === acceptedFiles[0].name &&
          (acceptedFiles[0].name ||
            acceptedFiles[acceptedFiles.length - 1].name)
        );
      });

      if (!isUniqueFile.length) {
        isUniqueFile = acceptedFiles;
      } else {
        isUniqueFile = [];
      }

      arrayData = [...getFileValue, ...isUniqueFile];
      setFileValue(arrayData);
      setFieldValue("files", arrayData);
    },
    [getFileValue, setFileValue]
  );

  const deletePic = (index) => {
    let filterData = getFileValue.filter((item, i) => i !== index);
    setFileValue(filterData);
    setFieldValue("files", filterData);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      ".jpeg,.png,.jpg,.mpeg,.mov,.x-msvideo,.flv,.tiff,.msword,.pdf,.plain ",
  });

  useEffect(() => {
    setFileValue([...getFileValue, ...props.values.files]);
  }, []);

  return (
    <>
      <Box
        className="dropOrInputSection"
        style={{ cursor: "pointer" }}
        {...getRootProps()}
      >
        <img className="HousesBro(2)" src={Houses} alt="House" />
        <h6 className="dragText">Drag And Drop</h6>

        <div style={{ paddingTop: 21, height: 60 }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drag and Drop....</p>
          ) : (
            <p style={{ color: "#4D9FFF", cursor: "pointer" }}>
              Click to Upload Files
            </p>
          )}
        </div>
      </Box>

      <div className="fileNameList">
        {getFileValue?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                borderRadius: 15,
                color: "#fff",
                position: "relative",
                width: "50px",
                // height: "50px",
                marginRight: "9px",
              }}
            >
              {item.type === "application/pdf" ? (
                <img style={{ width: "84%" }} src={PdfLogo} alt={index} />
              ) : (
                <img
                  style={{ width: "100%" }}
                  src={URL?.createObjectURL(item)}
                  alt={index}
                />
              )}
              <span>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    fill: "#fff",
                    background: "#000",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "-4px",
                    right: "-4px",
                    width: "15px",
                    height: "15px",
                  }}
                  onClick={() => deletePic(index)}
                />
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DragDrop;
