import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import { loginFacebook } from 'redux/actions/facebookGoogle/index';
import AlertModal from 'web/components/AlertModal';
import fb from 'web/assets/svg/fb.svg';
import style from 'web/components/Facebook/facebook.scss';

const Facebook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalLink, setModalLink] = useState('/');
  const toggleModal = () => setModal(!modal);
  let title = '';

  const responseFacebook = async (response) => {
    const accessToken = response.accessToken;
    if (accessToken !== undefined) {
      const responseAuth = await dispatch(
        loginFacebook({ accessToken: response.accessToken })
      );
      if (!responseAuth.error) {
        if (responseAuth?.data?.token) {
          localStorage.setItem('userInfo', responseAuth?.data?.token);
          history.push('/dashboard');
        } else {
          setModalContent('You are not registered user.');
          setModalLink('/registration');
          toggleModal();
        }
      } else {
        setModalContent('You are not registered user.');
        setModalLink('/registration');
        toggleModal();
      }
    }
  };
  return (
    <>
      <FacebookLogin
        className='socialBtn'
        appId='338486037465683'
        fields='name,email,picture'
        textButton='Facebook'
        callback={responseFacebook}
        icon={
          <img
            src={fb}
            alt='fb'
            className='faceBookIcon'
            style={{ paddingRight: 5 }}
          />
        }
      />

      <AlertModal
        isOpen={modal}
        toggle={toggleModal}
        title={title}
        content={modalContent}
        link={modalLink}
      />
    </>
  );
};

export default Facebook;
