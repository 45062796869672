import * as Yup from "yup";

export function INITIAL_VALUES() {
	return {
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	};
}

export function VALIDATION_SCHEMA() {
	let schemaAttributes = {
		currentPassword: Yup.string()
			.label("currentPassword")
			.required("Current Password is a required field.")
			.min(6, "Seems to be a bit short...")
			.max(12, "We prefer secure system, try a shorter password."),
		newPassword: Yup.string()
			.label("newPassword")
			.required("New Password is a required field.")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*#?&]{8,}$/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
			)
			.min(6, "Seems to be a bit short...")
			.max(12, "We prefer secure system, try a shorter password."),
		confirmPassword: Yup.string()
			.required("Confirm Password is a required field.")
			.oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
	};
	return Yup.object().shape(schemaAttributes);
}
