import styled from "styled-components";

const MainSection = styled.div`
  /* background-color: #ffffff; */
  text-align: center;
  padding-top: 80px;
  max-width: 1920px;
  margin: 0 auto;
  .sectionA {
    text-align: left;
    padding-left: 47px;
    max-width: 482px;
    width: 100%;
  }
  .sectionM {
    width: 100%;
    padding-bottom: 222px;
  }
  .about_text {
    font-size: 40px;
    font-family: "Poppins";
    letter-spacing: 3.2px;
    color: ${({ theme }) => theme.colors.homePageTextA};
    font-weight: 600;
  }
  .about_textA {
    font-size: 16px;
    max-width: 420px;
    width: 100%;
    font-family: "Poppins";
    color: ${({ theme }) => theme.colors.homePageTextB};
    padding-top: 32px;
  }
  .moreDetailsBtn {
    display: none;
    /*  margin-top: 32px;
    color: #fff;
    background: #e45829 0% 0% no-repeat padding-box;
    border: 2px solid #e45829;
    border-radius: 10px;
    width: 230px;
    height: 70px;
    font-family: 'Poppins';
    font-weight: 500;
    text-transform: capitalize;
    font-size: 18px;*/
  }
  .moreDetailsBtn:hover {
    background: #e45829 0% 0% no-repeat padding-box;
  }
  .details_icon {
    padding-left: 12px;
  }
  .carousel-indicators {
    width: 100%;
    margin: 0 auto;
    right: 0;
    justify-content: flex-end;
    padding-right: 15px;
    padding-bottom: 15px;

    /* margin-right: 15%; */
    /* margin-left: 15%; */
  }
  .carousel-inner {
    max-height: 60vh;
  }
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    margin-right: 7px;
    margin-left: 7px;
    background-color: unset;
    background-clip: unset;
    border: 1px solid #fff;
    border-radius: 10px;
  }
  .carousel-indicators .active {
    background-color: #fff;
  }
  .second_li {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sectionAfterSlider {
    width: 100%;
    max-width: 824px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #52474729;
    border-radius: 10px;
    position: absolute;
    bottom: -18%;
    left: 0;
  }
  .carouselSection {
    padding-left: 60px;
    position: relative;
  }
  .sectionUnderSlider {
    padding: 33px 15px;
    float: left;
    width: 100%;
  }
  .imageSection {
    img {
      height: 60px;
    }
  }
  .section1 {
    width: 33%;
    float: left;
    /* padding-left: 5px;
    padding-right: 5px; */
  }
  .section1A {
    width: 33%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    border-left: 1.5px solid #e45829;
    border-right: 1.5px solid #e45829;
  }
  /* .trust_img {
    padding-bottom: 10px;
  } */
  .textMax {
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0px;
    color: #00000091;
    text-transform: capitalize;
    font-family: "Poppins";
  }
  .textp {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #00000091;
    text-transform: capitalize;
    font-family: "Poppins";
    padding: 0 8px;
  }
  .textq {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #00000091;
    text-transform: capitalize;
    font-family: "Poppins";
    padding: 0 18px;
  }
  .texta {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #00000091;
    text-transform: capitalize;
    font-family: "Poppins";
    padding: 0 1px;
  }
  .item > img {
    width: 100%;
    max-height: 639px;
  }
  .carousel-indicators .active {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
  }
  @media (max-height: 600px) {
    padding-top: 10px;
  }
  @media (max-width: 1450px) {
    .about_text {
      font-size: 35px;
    }
    .about_textA {
      font-size: 14px;
      /* padding-right: 23%; */
      max-width: 300px;
      width: 100%;
    }
    .textMax {
      font-size: 18px;
    }
    .textp {
      font-size: 12px;
    }
    .sectionAfterSlider {
      max-width: 687px;
      left: 0%;
    }
    .imageSection {
      img {
        height: 40px;
      }
    }
  }
`;

export { MainSection };
