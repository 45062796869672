import React, { useEffect, useState } from "react";
import TextInput from "web/components/form-elements/TextInput";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import NumberInput from "web/components/form-elements/NumberInput";
import TextArea from "web/components/form-elements/TextArea";
import Button from "web/components/Button";
import AlertModal from "web/components/AlertModal";
import { contactUs } from "redux/actions/auth/index";
import { Container, FormSection } from "web/pages/public/contactUs/style";
import {
  getAuthInitialValues,
  getValidationSchema,
} from "web/components/utils/contactUsUtils";
import Typography from "@material-ui/core/Typography";
import "./style.scss";

const Contact = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  let title = "";
  //test
  const signInHandler = async (props) => {
    const response = await dispatch(contactUs(props));
    if (!response?.error) {
      setModalContent("Your query has been submitted successfully.");
      setModalLink("/");
      toggleModal();
    } else {
      if (response.data.response.status === 404) {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
      } else {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <Container>
        <Typography className="SectionOne">Contact Us</Typography>
        <Formik
          initialValues={getAuthInitialValues()}
          validationSchema={getValidationSchema}
          onSubmit={(values) => {
            signInHandler(values);
          }}
        >
          {(props) => {
            const { isValid, dirty } = props;
            return (
              <>
                <Form>
                  <FormSection>
                    <TextInput name="name" placeholder="Name" />
                    <TextInput name="email" placeholder="Email" />
                    <NumberInput
                      name="phoneNumber"
                      placeholder="Phone Number"
                      showErrorMessages={true}
                      format="(###) ###-####"
                    />
                    <TextInput name="subject" placeholder="Subject" />
                    <TextArea name="message" placeholder="Message" />
                    <Button
                      type="app-orange-button"
                      disabled={!(isValid && dirty)}
                      className="send"
                    >
                      Send
                    </Button>
                  </FormSection>
                </Form>
              </>
            );
          }}
        </Formik>
        <AlertModal
          isOpen={modal}
          toggle={toggleModal}
          title={title}
          content={modalContent}
          link={modalLink}
        />
      </Container>
    </div>
  );
};

export default Contact;
