import styled from "styled-components";

const MainSection = styled.div`
	width: 40%;
	.googleAddressBar {
		width: 100%;
		input {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
		.autocomplete-dropdown-container {
			height: 115px !important;
			width: 100% !important;
			padding: 0 4px;
			overflow: hidden;
			margin-bottom: 15px;
			color: ${({ theme }) => theme.colors.automCompleteAddress};
		}
		.suggestion-item {
			background-color: #f6f6f6 !important;
		}
		@media screen and (max-width: 520px) {
			width: 100%;
		}
	}
`;

export { MainSection };
