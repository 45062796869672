import { DialogContent } from "@material-ui/core";
import styled, { css } from "styled-components";

const MainSection = styled.div`
	width: 100%;
	.loginImage {
		width: 100%;
		height: 100%;
	}
	.BoxShowingName {
		border: 1px solid #d2d9e8;
		height: 50px;
		padding: 14px 14px;
		border-radius: 4px;
		margin-bottom: 20px;
	}
	.headerLogo {
		padding-left: 30px;
		cursor: pointer;
	}
	.registerSection {
		padding-left: 20%;
		padding-right: 20%;
		padding-top: 4.5%;
		padding-bottom: 4%;
	}
	.agent_text {
		color: #000;
		font-size: 23px;
		font-family: "Poppins";
		font-weight: 600;
		padding-bottom: 3px;
		text-transform: capitalize;
		text-align: center;
	}

	.TextArea {
		border-radius: 8px;
		width: 100%;
		border: 1px solid #d2d9e8;
		margin-top: 25px;
	}
	.agent_textB {
		color: #6f7172;
		font-size: 20px;
		font-family: "Poppins";
		font-weight: 500;
		text-align: center;
	}
	.socialMediaProfiles {
		text-align: center;
	}
	.socialMediaProfiles img {
		cursor: pointer;
	}
	.avatar {
		width: 80px;
		height: 80px;
		margin-top: 33px;
		margin: auto;
		border: 2px solid #e4582a;
	}
	.sectionA {
		padding-right: 30px;
	}
	.sectionB {
		padding-left: 30px;
	}
	.custom-checkbox-root .MuiSvgIcon-root {
		width: 27px;
		height: 27px;
	}
	.MuiFormControlLabel-label {
		font-size: 14px;
		font-family: "Poppins";
		color: #6f7172;
		font-weight: bold;
	}
	.learnText {
		font-size: 14px;
		font-family: "Poppins";
		color: #2b82d9;
		font-weight: bold;
		padding-left: 34px;
		margin-top: -20px;
		cursor: pointer;
	}
	.submitBtn {
		max-width: 960px;
		width: 100%;
		height: 60px;
		background: #e45829 0% 0% no-repeat padding-box;
		border-radius: 10px;
		color: #fff;
		font-size: 16px;
		font-weight: 600;
		text-transform: capitalize;
		font-family: "Poppins";
		margin-top: 52px;
	}
	.submitBtn:hover {
		background: #e45829 0% 0% no-repeat padding-box;
	}
	.account_txt {
		text-align: center;
		padding-top: 48px;
		font-family: "Poppins";
		color: #4f5270;
		font-size: 20px;
		font-weight: bold;
	}
	.login_txt {
		color: #157dff;
		cursor: pointer;
	}
	.avatarSection {
		position: relative;
		text-align: -webkit-center;
		margin-bottom: 22px;
	}
	.editProfileIcon {
		position: absolute;
		bottom: -12px;
		left: 51%;
		> g {
			cursor: pointer;
		}
	}

	.app-input-text input,
	.custom-select__control,
	textarea {
		border: ${(props) =>
			props.toggled ? "1px solid #e45829" : "1px solid #d2d9e8"};
	}

	:focus {
		outline: none;
	}

	.profilePicSection {
		display: block;
		width: 120px;
		height: 30px;
	}
	#getFile {
		display: none;
	}
	.checkSectionA {
		padding-top: 25px;
	}

	.profileSettings {
		width: 55px;
		cursor: pointer;
	}

	.dropdown {
		position: relative;
		display: inline-block;
		cursor: pointer;
		svg > g {
			cursor: pointer;
			:hover {
				opacity: 0.6;
			}
		}
	}
	// .crop {
	// 	width: 68px;
	// 	height: 68px;
	// 	overflow: hidden;
	// }

	// .crop > img {
	// 	width: 70px;
	// 	height: 70px;
	// }

	.dropdown-content {
		display: none;
		position: absolute;
		right: 0;
		min-width: 160px;
		background: #393939;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 5px;
		z-index: 1;
	}

	.dropdown-content .dropdownContent {
		color: #ffffff;
		font-size: 14px;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		cursor: pointer;
		border: 1px solid #fff;
	}

	.dropdown-content .dropdownContent:hover {
		background-color: #526365;
	}

	.dropdown:hover .dropdown-content {
		cursor: pointer;
		display: block;
	}
	.settingsIconDropDown {
		position: absolute;
		right: 4%;
		top: 20%;
	}
	.sectionA fieldset,
	#custom-select > div:nth-child(2) {
		border: ${(props) => (props.toggled ? "1px solid #e45829 !important" : "")};
	}
	.sectionB fieldset {
		border: ${(props) => (props.toggled ? "1px solid #e45829 !important" : "")};
	}
	#buttonSave {
		width: 100%;
		padding: 17px;
		margin-top: 44px;
		border-radius: 10px;
	}
`;
const Container = styled.div`
	display: flex;
	width: 100%;
	.leftSection {
		flex: 1;
		padding: 2%;
		> div {
			height: 47px;
			border-radius: 4px;
			font-size: 14px;
			color: #4f5270;
		}
		> div > input {
			height: 47px;
			border-radius: 4px;
			font-size: 14px;
		}
		div > textarea {
			font-size: 14px;
			color: #4f5270;
		}
		div > textarea::placeholder {
			font-size: 14px;
			color: #4f5270;
		}
	}
	.rightSection {
		flex: 1;
		padding: 2%;
		> div {
			height: 47px;
			border-radius: 4px;
			font-size: 14px;
			color: #4f5270;
			margin-bottom: 20px;
		}
		> div > input {
			height: 47px;
			border-radius: 4px;
			font-size: 14px;
		}
	}
	#custom-select > div:nth-child(2) {
		height: 50px;
		border-radius: 4px;
		border: solid 1px #e0e0e0;
		width: 100%;
		background: #ffffff;
		padding: 6px 14px;
	}
	.Bio {
		> div > textarea {
			font-size: 14px;
			color: #4f5270;
		}
		> div > textarea::placeholder {
			font-size: 14px;
			color: #4f5270;
		}
	}
`;
const ProfileSection = styled.div``;

const SocialMedia = styled.div`
	.socialMediaProfiles {
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.socialMediaProfiles svg g {
		cursor: pointer;
	}
	.crop {
		width: 68px;
		height: 68px;
		overflow: hidden;
	}

	.crop > img {
		width: 60px;
		height: 60px;
	}
`;

export { MainSection, ProfileSection, Container, SocialMedia };
