import * as React from "react";

const TwitterIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...props}>
			<defs>
				<filter
					id="Ellipse_134"
					x={0}
					y={0}
					width={68}
					height={68}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="blur" />
					<feFlood floodColor="#d1d1d1" floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="Component 76 \u2013 15">
				<g filter="url(#Ellipse_134)">
					<circle
						data-name="Ellipse 134"
						cx={25}
						cy={25}
						r={25}
						transform="translate(9 6)"
						fill="#e25728"
					/>
				</g>
				<path
					data-name="Icon awesome-twitter"
					d="M43.39 25.825c.015.212.015.424.015.635a13.807 13.807 0 01-13.9 13.9A13.808 13.808 0 0122 38.169a10.108 10.108 0 001.18.06 9.786 9.786 0 006.066-2.088 4.895 4.895 0 01-4.569-3.389 6.162 6.162 0 00.923.076 5.168 5.168 0 001.286-.166 4.887 4.887 0 01-3.918-4.8v-.06a4.921 4.921 0 002.209.62 4.893 4.893 0 01-1.513-6.53 13.889 13.889 0 0010.075 5.113 5.516 5.516 0 01-.121-1.119 4.891 4.891 0 018.456-3.343 9.62 9.62 0 003.1-1.18 4.873 4.873 0 01-2.148 2.693 9.8 9.8 0 002.814-.756 10.5 10.5 0 01-2.45 2.525z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default TwitterIcon;
