import styled from 'styled-components';

const ImageSection = styled.div`
  // padding-top: 20px;
  // padding-left: 10px;
  > img {
    cursor: pointer;
    top: 0;
    left: 0;
    transform: translate(0%, 15%);
    padding-left: 28px;
    padding-top: 7px;
  }
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  .leftSection,
  .rightSection {
    flex: 1;
    padding: 2%;
    > div > input {
      height: 47px;
      border-radius: 4px;
      font-size: 14px;
    }
    > div > div > input {
      height: 47px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
  #custom-select > div:nth-child(2) {
    height: 50px;
  }

  .socialMediaProfiles {
    text-align: center;
  }
  .socialMediaProfiles img {
    cursor: pointer;
  }
  .notWorkingCheckbox {
    margin-top: 18px;
  }
  .textSection {
    margin-left: 30px;
    font-size: 10px;
    color: #2b82d9;
  }
`;
const SocialMedia = styled.div`
  .socialMediaProfiles {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .socialMediaProfiles svg g {
    cursor: pointer;
  }

  .social_media > div > input {
    height: 47px;
    border-radius: 4px;
  }
`;

const MainSection = styled.div`
  .loginImage {
    width: 100%;
    height: 100%;
  }
  .headerLogo {
    // padding-left: 30px;
    cursor: pointer;
  }
  .registerSection {
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 50px;
  }
  .agent_text {
    color: ${({ theme }) => theme.colors.loginTextB};
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 600;
    padding-bottom: 17px;
    padding-top: 48px;
    text-align: center;
  }
  .agent_textB {
    color: ${({ theme }) => theme.colors.loginTextA};
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
  }
  .FormSection {
    padding-top: 30px;
  }
  .avatar {
    width: 90px;
    height: 90px;
    margin: auto;
  }
  .sectionA {
    padding-right: 30px;
  }
  .sectionB {
    padding-left: 30px;
  }
  .custom-checkbox-root .MuiSvgIcon-root {
    width: 27px;
    height: 27px;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-family: 'Poppins';
    color: #6f7172;
    font-weight: bold;
  }
  .learnText {
    font-size: 14px;
    font-family: 'Poppins';
    color: #2b82d9;
    font-weight: bold;
    padding-left: 34px;
    margin-top: -20px;
    cursor: pointer;
  }
  .submitBtn {
    width: 100%;
    height: 47px;
    background: #e45829 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins';
    margin-top: 52px;
  }
  .submitBtn:hover {
    background: #e45829 0% 0% no-repeat padding-box;
  }
  .account_txt {
    text-align: center;
    padding-top: 48px;
    font-family: 'Poppins';
    color: ${({ theme }) => theme.colors.loginTextB};
    font-size: 20px;
    font-weight: bold;
  }
  .login_txt {
    color: #157dff;
    cursor: pointer;
  }
  .avatarSection .reactEasyCrop_Container {
    width: 8%;
    height: 10%;
    border-radius: 69px;
    border: 1px solid;
    top: 40%;
    left: 46%;
  }
  .avatarSection {
    text-align: -webkit-center;
    position: relative;
    margin-bottom: 22px;
  }
  .avatarSection .reactEasyCrop_CropArea {
    width: 88px !important;
    height: 88px !important;
  }
  .editProfileIcon {
    // position: absolute;
    bottom: -16px;
    left: 51%;
    position: absolute;
    > g {
      cursor: pointer;
    }
    // top: 47%;
  }
  .SliderSection {
    margin-top: 40px;
  }
  .customSelectBox {
    background-color: #fff;
    border: 1px solid #d2d9e8;
    border-radius: 8px;
    height: 58px;
    line-height: normal;
  }
  .customSelectBox:hover {
    background-color: #fff;
  }
  .customSelectBox::before {
    border-bottom: none;
  }
  .customSelectBox:hover::before {
    border-bottom: none;
  }
  .customSelectBox:hover::after {
    border-bottom: none;
  }
  .customSelectBox select {
    color: #4f5270;
    padding-top: 20px;
    font-size: 18px;
    font-family: 'Poppins';
  }
  .customSelectBox svg {
    // top: calc(50% - 8px);
    display: none;
  }
  .othersSelectBox {
    position: relative;
    width: 100%;
    margin-top: 25px;
  }
  .selectIcons {
    cursor: pointer;
    right: 9px;
    top: 19px;
    position: absolute;
  }
  :focus {
    outline: none;
  }
  .placeholderSelect {
    font-size: 18px;
    font-family: 'Poppins';
    color: #a5a5ad;
  }
  .profilePicSection {
    display: block;
    width: 120px;
    height: 30px;
  }
  #profilePic {
    display: none;
  }
  #getFile {
    display: none;
  }
  .checkSectionA {
    padding-top: 25px;
  }
`;

export { ImageSection, MainSection, Container, SocialMedia };
