import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={14}
      viewBox="0 0 18 14"
      {...props}
    >
      <path
        d="M8.999 10.937a3.99 3.99 0 01-4.019-3.64L2.031 5.081A9.1 9.1 0 00.999 6.603a.863.863 0 000 .8 9.048 9.048 0 008 4.851 8.971 8.971 0 002.191-.286l-1.459-1.1a4.166 4.166 0 01-.732.069zm8.826 1.589l-3.109-2.336a9.105 9.105 0 002.283-2.787.863.863 0 000-.8 9.048 9.048 0 00-8-4.853 8.863 8.863 0 00-4.142 1.031L1.279.092a.459.459 0 00-.632.077L.099.86a.429.429 0 00.079.614l16.544 12.429a.459.459 0 00.632-.077l.552-.691a.429.429 0 00-.08-.609zm-5.166-3.883l-1.105-.831a2.526 2.526 0 00.145-.809 2.566 2.566 0 00-1.052-2.1 2.726 2.726 0 00-2.36-.424 1.279 1.279 0 01.262.771 1.243 1.243 0 01-.043.273L6.437 3.968a4.111 4.111 0 015.428.247 3.881 3.881 0 011.185 2.788 3.756 3.756 0 01-.391 1.64z"
        fill="#6f7172"
      />
    </svg>
  )
}

export default SvgComponent
