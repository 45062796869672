import styled from 'styled-components';

export const ForgotPasswordPage = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20%;

  .innerSection h3 {
    font-size: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.loginTextA};
    padding: 50px 2% 3% 4px;
  }
  .innerSection svg {
    margin-left: -6px;
  }
  .innerSection svg g {
    :hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .innerSection h3 img {
    width: auto;
  }
  .innerSection h6 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.loginTextA};
    padding: 11px 79px 31px 3px;
    font-family: poppinsLight;
    line-height: 30px;
  }
  .innerSection .headerLogo {
    padding-left: 10px;
  }
  .footer {
    margin-top: 50px;
  }
  .footer button {
    width: 100%;
    height: 50px;
  }
  .leftSection,
  .rightSection {
    flex: 1;
  }
`;
