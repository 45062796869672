import axioPath from 'api/axioPath';
import { FACEBOOK_DATA, GOOGLE_DATA } from 'redux/actions/action_types';

export const loginFacebook = (data) => (dispatch) => {
  return axioPath
    .post('/auth/facebook/login', data)
    .then((response) => {
      dispatch({ type: FACEBOOK_DATA, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, ex: ex };
    });
};

export const loginGoogle = (data) => async (dispatch) => {
  return axioPath
    .post('/auth/google/login', data)
    .then((response) => {
      dispatch({ type: GOOGLE_DATA, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, ex: ex };
    });
};
