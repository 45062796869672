import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import useStyles from 'web/components/TextInput/useStyles';

const TextInput = (props) => {
  const classes = useStyles();
  return <TextField className={classes.root} {...props} onBlur={(values) => props.onBlur(values.target.value)} variant='outlined' />;
};

TextInput.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.oneOf(['white', 'purple']),
};

export default TextInput;
