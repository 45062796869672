import * as Yup from "yup";
const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

var linkUrl = RegExp(
  /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi
);

export function getAuthInitialValues(pageType) {
  return {
    agentLiscenceNo: "",
    firstName: "",
    lastName: "",
    phone: "",
    brokerageName: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    role: pageType.toUpperCase(),
    email: "",
    password: "",
    confirmPassword: "",
    zipCode: "",
    termsConditions: false,
    notWorkingWithAnyAgent: false,
    relationWithOwner: "",
    profilePic: "",
  };
}
export function getValidationSchema(pageType) {
  if (pageType === "Agent") {
    let schemaAttributes = {
      agentLiscenceNo: Yup.string()
        .max(15, "Enter valid License Number")
        .matches(/^[^-\s@!$%][a-zA-Z0-9\s]+$/, "Enter valid license Number.")
        .required("License Number is required."),
      firstName: Yup.string()
        .matches(/^[^-\s@!$%][A-Za-z\s]+$/, "Enter valid name.")
        .required("First Name is required."),
      lastName: Yup.string()
        .matches(/^[^-\s@!$%][A-Za-z\s]+$/, "Enter valid name.")
        .required("Last Name is required."),
      phone: Yup.string()
        .matches(phoneRegex, "Phone number is not valid.")
        .required("Phone Number is required."),
      brokerageName: Yup.string()
        .matches(/^[^-\s@!$%][A-Za-z0-9\s]+$/, "Enter valid name.")
        .required("Brokerage Name is required."),
      addressLine1: Yup.string()
        .matches(
          /^[^-\s@!$%][a-zA-Z0-9,_#-/ ]+( [a-zA-Z0-9,_-]+)*$/,
          "Enter valid address."
        )
        .required("Address Line 1 is required."),
      addressLine2: Yup.string().matches(
        /^[^-\s@!$%][a-zA-Z0-9,_#-/ ]+( [a-zA-Z0-9,_-]+)*$/,
        "Enter valid address."
      ),
      state: Yup.string().trim().required("State is required."),
      city: Yup.string().trim().required("City is required."),
      zipCode: Yup.string()
        .required("This field is required.")
        .matches(
          /^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$/,
          "Enter valid zip code"
        ),
      termsConditions: Yup.boolean().oneOf(
        [true],
        "This checkbox is required."
      ),
      email: Yup.string()
        .email("Please enter a valid email.")
        .required("Email is required."),
      password: Yup.string()
        .label("Password")
        .required("Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
        )
        .min(6, "Seems to be a bit short...")
        .max(12, "We prefer secure system, try a shorter password."),

      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .oneOf([Yup.ref("password"), null], "Passwords must match."),
      twitterLink: Yup.string().matches(linkUrl, "Please enter a valid link."),
      fbLink: Yup.string().matches(linkUrl, "Please enter a valid link."),
      igLink: Yup.string().matches(linkUrl, "Please enter a valid link."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (pageType === "Homeowner") {
    let schemaAttributes = {
      firstName: Yup.string()
        .matches(/^[^-\s@!$%][a-zA-Z ]+$/, "Enter valid name.")
        .required("First Name is required."),
      lastName: Yup.string()
        .matches(/^[^-\s@!$%][a-zA-Z ]+$/, "Enter valid name.")
        .required("Last Name is required."),
      phone: Yup.string()
        .matches(phoneRegex, "Phone number is not valid.")
        .required("Phone Number is required."),
      addressLine1: Yup.string()
        .matches(
          /^[^-\s@!$%][a-zA-Z0-9,_#-/ ]+( [a-zA-Z0-9,_-]+)*$/,
          "Enter valid address."
        )
        .required("Address Line 1 is required."),
      addressLine2: Yup.string().matches(
        /^[^-\s@!$%][a-zA-Z0-9,_#-/ ]+( [a-zA-Z0-9,_-]+)*$/,
        "Enter valid address."
      ),
      state: Yup.string().trim().required("State is required."),
      city: Yup.string().trim().required("City is required."),
      zipCode: Yup.string()
        .required("This field is required.")
        .matches(
          /^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$/,
          "Enter valid zip code"
        ),
      email: Yup.string()
        .email("Please enter a valid email.")
        .required("Email is required."),
      password: Yup.string()
        .label("Password")
        .required("Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
        )
        .min(6, "Seems to be a bit short...")
        .max(12, "We prefer secure system, try a shorter password."),

      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .oneOf([Yup.ref("password"), null], "Passwords must match."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (pageType === "Others") {
    let schemaAttributes = {
      firstName: Yup.string()
        .matches(/^[^-\s@!$%][a-zA-Z ]+$/, "Enter valid name.")
        .required("First Name is required."),
      lastName: Yup.string()
        .matches(/^[^-\s@!$%][a-zA-Z ]+$/, "Enter valid name.")
        .required("Last Name is required."),
      phone: Yup.string()
        .matches(phoneRegex, "Phone number is not valid.")
        .required("Phone Number is required."),
      addressLine1: Yup.string()
        .matches(
          /^[^-\s@!$%][a-zA-Z0-9,_#-/ ]+( [a-zA-Z0-9,_-]+)*$/,
          "Enter valid address."
        )
        .required("Address Line 1 is required."),
      addressLine2: Yup.string().matches(
        /^[^-\s@!$%][a-zA-Z0-9,_#-/ ]+( [a-zA-Z0-9,_-]+)*$/,
        "Enter valid address."
      ),
      state: Yup.string().trim().required("State is required."),
      city: Yup.string().trim().required("City is required."),
      relationWithOwner: Yup.string()
        .trim()
        .required("Relation With Owner is required."),
      zipCode: Yup.string()
        .required("This field is required.")
        .matches(
          /^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$/,
          "Enter valid zip code"
        ),
      email: Yup.string()
        .email("Please enter a valid email.")
        .required("Email is required."),
      password: Yup.string()
        .label("Password")
        .required("Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
        )
        .min(6, "Seems to be a bit short...")
        .max(12, "We prefer secure system, try a shorter password."),

      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .oneOf([Yup.ref("password"), null], "Passwords must match."),
    };
    return Yup.object().shape(schemaAttributes);
  }
}
