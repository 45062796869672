import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Field } from 'formik';
import 'web/components/form-elements/form-elements.scss';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    borderRadius: '4px',
    color: '#24272b',
    border: state.isSelected
      ? '1px solid #E4582A !important'
      : '1px solid #e0e0e0',
    boxShadow: 'none',
    '&:hover': { borderColor: '#E4582A !important' },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#E4582A', // Custom colour
    border: '1px solid #E4582A',
    borderRadius: '25px',
    padding: '0',
    marginRight: '11px',
    marginBottom: '0',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#292a2a',
    fontSize: '1rem',
    backgroundColor: state.isSelected ? '#E4582A' : 'initial',
    zIndex: 2,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9c9c9c',
    fontSize: '1rem',
    top: '50%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '1rem',
    top: 'unset',
    position: 'unset',
    transform: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '40px',
    padding: '0 10px',
  }),
  Input: (provided) => ({
    ...provided,
    height: '28px',
  }),
};

const customSelect = ({ data, name, placeholder, id, onChange = () => {} }) => {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <CreatableSelect
              id={id}
              name={name}
              className={`custom-select-input ${
                meta.touched && meta.error ? 'error' : ''
              }`}
              placeholder={placeholder}
              value={data.filter((option) => option.value === field.value)}
              onChange={(value) => {
                form.setFieldValue(name, value?.value);
                onChange(value);
              }}
              options={data}
              styles={customStyles}
            />
          );
        }}
      </Field>
    </>
  );
};

export default customSelect;
