import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import PublicNavbar from 'web/components/PublicNavbar';
import Footer from 'web/components/Footer';
import fb from 'web/assets/images/facebook-icon.png';
import inst from 'web/assets/images/insta-icon.png';
import tw from 'web/assets/images/twitter-icon.png';
import 'web/components/PublicLayout/publicLayout.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PublicLayout = ({ children, transparentHeader, paddingTop }) => {
  const history = useHistory();

  return (
    <Fragment>
      <Helmet>
        <meta name='viewport' content='width=device-width, user-scalable=no' />
      </Helmet>
      <div className='app-container'>
        <PublicNavbar />
        <div className={`app-body ${paddingTop ? 'app-body-top-padding' : ''}`}>
          {children}
        </div>
        <Footer>
          <Footer.Wrapper>
            <Footer.Row>
              <Footer.Column>
                <Footer.Title>Company</Footer.Title>
                <Footer.Link>
                  <span onClick={() => history.push('/contact')}>
                    Contact Us
                  </span>
                </Footer.Link>
                <Footer.Link>
                  <span onClick={() => history.push('/login')}>
                    Log In/Register
                  </span>
                </Footer.Link>
              </Footer.Column>
              <Footer.Column>
                <Footer.Title>Contact</Footer.Title>
                <Footer.Heading>info@ladbrook.com</Footer.Heading>
                <Footer.Heading>(818) 851-9322</Footer.Heading>

                <Footer.Heading>
                  <Footer.SocialIcon>
                    <img src={inst} alt='' />
                  </Footer.SocialIcon>
                  <Footer.SocialIcon>
                    <img src={fb} alt='' />
                  </Footer.SocialIcon>
                  <Footer.SocialIcon>
                    <img src={tw} alt='' />
                  </Footer.SocialIcon>
                </Footer.Heading>
              </Footer.Column>
              <Footer.Column>
                <Footer.Title>Address</Footer.Title>
                <Footer.Heading>
                  31352 Via Colinas,
                  <br />
                  Suite 101, Westlake
                  <br /> Village, CA 91362
                </Footer.Heading>
                {/* <Footer.Heading></Footer.Heading> */}
                {/* <Footer.Heading>91362</Footer.Heading> */}
              </Footer.Column>
            </Footer.Row>
          </Footer.Wrapper>
        </Footer>
      </div>
    </Fragment>
  );
};

PublicLayout.propTypes = {
  transparentHeader: PropTypes.bool,
  paddingTop: PropTypes.bool,
};

PublicLayout.defaultProps = {
  transparentHeader: false,
  paddingTop: true,
};

export default PublicLayout;
