import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={350.49}
      height={350.56}
      viewBox='0 0 604.49 601.56'
      {...props}>
      <defs>
        <filter
          id='prefix__a'
          x={0}
          y={0}
          width={604.49}
          height={601.56}
          filterUnits='userSpaceOnUse'>
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result='b' />
          <feFlood floodOpacity={0.161} />
          <feComposite operator='in' in2='b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <style>
          {
            '.prefix__a{fill:#393939}.prefix__b{fill:#726d6d}.prefix__c{fill:#fff}'
          }
        </style>
      </defs>
      <path
        className='prefix__a'
        d='M61.904 400.929v169.546l105.276-88.764c11.2 8.083 63.179 41.493 108.589 46.111 56.869 5.784 100.7-5.479 137.632-23.226s79.214-56.642 101.8-103.826z'
      />
      <path
        className='prefix__b'
        d='M515.113 400.77c-23.716 36.817-58.272 66.382-89.106 81.2-36.931 17.747-80.763 29.01-137.632 23.226-45.408-4.619-97.387-37.29-108.589-45.373l-94.184 79.413V400.921l-23.7.008v169.546l105.277-88.764c11.2 8.083 63.179 41.492 108.589 46.111 56.869 5.784 100.7-5.479 137.632-23.226s79.214-56.642 101.8-103.826z'
      />
      <path
        className='prefix__a'
        d='M509.467 143.204c-23.956-44.808-59.155-80.019-102.944-102.679v-.48c-62.755-33.378-146.789-33.373-209.542 0v.48c-43.789 22.66-78.988 57.871-102.944 102.679z'
      />
      <path
        className='prefix__b'
        d='M139.884 116.568c18.068-33.8 42.535-62.128 72.257-83.738-5.166 2.209-10.235 4.6-15.161 7.217v.48c-43.788 22.658-78.987 57.87-102.943 102.678h415.43a260.432 260.432 0 00-16.366-26.639H139.884z'
      />
      <path
        className='prefix__c'
        d='M192.526 94.56l-10.1-13.9c5.847-4.25 10.3-7.352 14.45-10.059l9.39 14.39c-3.901 2.546-8.14 5.495-13.74 9.569zm27.74-17.717l-7.73-15.345a139.114 139.114 0 0116.833-7.12l5.674 16.218a122.219 122.219 0 00-14.777 6.247z'
      />
      <path d='M18.021 142.568H586.47v257.735H18.021z' fill='#e45829' />
      <path
        d='M43.389 372.795V142.568H18.021v257.735H586.47v-27.508z'
        fill='#ff7b4e'
      />
      <path
        className='prefix__c'
        d='M482.66 314.964h17.182v17.182H482.66zM104.648 314.964h17.182v17.182h-17.182zM220.144 76.692a122.321 122.321 0 0114.8-6.257l-5.683-16.242a139.455 139.455 0 00-16.858 7.131zM192.363 94.435c5.612-4.079 9.855-7.034 13.756-9.58l-9.4-14.411c-4.152 2.71-8.615 5.817-14.471 10.073zM293.833 520.886l-.788 17.189c3.06.14 6.156.211 9.2.211 2.952 0 5.935-.044 8.866-.131l-.507-17.2c-5.526.164-11.173.187-16.771-.07z'
      />
      <g transform='translate(0 .001)' filter='url(#prefix__a)'>
        <path
          d='M595.49 133.908h-79.583c-21.736-38.134-52.3-70.019-88.7-92.389L418.2 56.18a240.261 240.261 0 0177.7 77.729H108.528C151.371 65.22 224.19 23.206 302.213 23.206a218.677 218.677 0 01100.543 24.292L410.6 32.18A235.717 235.717 0 00302.213 5.998a238.652 238.652 0 00-127.581 37.134 258.731 258.731 0 00-86.12 90.774H9v60.8h17.208v-43.592h552.075V392.02H26.208V211.915H9v197.312h43.958v180.331l114.5-96.545c34.1 23.463 71 38.421 107.1 43.368l2.336-17.048c-37.042-4.867-76.4-22.5-110.333-48.092l-96.4 82.441V409.228h430.048c-36.294 64.647-97.316 103.827-173.15 110.738l1.561 17.137c44.007-4.01 83.408-18.064 117.106-41.769 30.947-21.77 55.819-50.739 74.011-86.106h75.753z'
          fill='#e5e5e5'
        />
      </g>
      <path
        className='prefix__c'
        d='M112.983 194.564h17.24v-17.208h-17.24a43.092 43.092 0 000 86.184l17.259-.031-.031-17.177h-17.177c-34.328-1.191-34.4-50.435-.048-51.769zM500.152 194.564h25.843v-17.208h-25.843a43.069 43.069 0 10.079 86.137h25.728v-51.551H491.68v17.208h17.086l.015 17.169-8.581.016h-.05a25.885 25.885 0 010-51.77zM336.61 177.571h17.208v86.011H336.61zM422.77 231.935l-35.322-54.467h-16.419v85.969h17.208V210.31l34.361 52.99h17.383v-85.729H422.77zM181.761 263.508a34.455 34.455 0 0034.415-34.415v-17.322a34.415 34.415 0 00-68.83 0v17.322a34.454 34.454 0 0034.415 34.415zm-17.208-51.737a17.208 17.208 0 0134.415 0v17.322a17.208 17.208 0 01-34.415 0zM276.716 206.5l-25.9-29.032h-17.542v86.114h17.211v-60.637l26.255 29.424 25.417-28.577v59.79h17.208v-86.114h-16.82zM181.839 349.463h-34.676v17.208h34.753c33.869-1.215 33.769-50.521-.09-51.594l-8.776-.008c-11.159-.306-11.146-16.639.007-16.929h34.4v-17.209h-34.4a25.673 25.673 0 00-.025 51.345l8.777.008c11.174.28 11.188 16.857.03 17.179zM224.658 332.889a34.415 34.415 0 1068.83 0v-17.32a34.415 34.415 0 10-68.83 0zm17.208-17.32a17.208 17.208 0 0134.415 0v17.322a17.208 17.208 0 01-34.415 0zM310.792 315.569v17.322a34.415 34.415 0 0068.83 0v-17.322a34.415 34.415 0 00-68.83 0zm17.208 0a17.208 17.208 0 0134.415 0v17.322a17.208 17.208 0 01-34.415 0zM448.685 335.31l-35.322-54.468h-16.419v85.97h17.208v-53.128l34.361 52.986h17.379v-85.724h-17.207z'
      />
      <path d='M485.463 48.761l34.297-36.5 12.54 11.784-34.297 36.5zM532.916 102.765l43.045-25.606 8.797 14.79-43.044 25.605zM510.614 73.835l39.066-31.353 10.773 13.422-39.067 31.353z' />
      <path
        className='prefix__a'
        d='M73.819 24.266l12.54-11.784 34.297 36.5-12.54 11.784zM21.093 91.656l8.798-14.79 43.044 25.607-8.797 14.789zM45.766 55.793l10.772-13.422 39.067 31.353-10.773 13.422z'
      />
      <path
        className='prefix__c'
        d='M251.551 477.061l6.964-49.599 17.041 2.393-6.963 49.599zM330.108 431.061l17.04-2.393 6.964 49.599-17.04 2.393zM293.684 431.478h17.208v50.084h-17.208zM482.924 315.163h17.208v17.208h-17.208zM104.359 315.163h17.208v17.208h-17.208z'
      />
    </svg>
  );
}

export default SvgComponent;
