import * as Yup from "yup";
import * as moment from "moment";

let y = moment(new Date()).format("YYYY").split("");
let patternStr = `^(1[8-9][0-9]|1[8-9][0-9]\\d|20[0-${y[2] - 1}]\\d|20${
  y[2]
}[0-${y[3]}])$`;

var linkUrl = RegExp(
  /^$|[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi
);

const validYear = RegExp(patternStr);

export function getAuthInitialValues(userRole, response, getCoreLogicResponse) {
  let value = "";
  let garageValue = "";
  let landUseValue = "";
  let firePl = "";
  let airCond = "";
  if (
    getCoreLogicResponse?.data?.poolInfo === "POOL" ||
    getCoreLogicResponse?.data?.poolInfo === "POOL & JACUZZI"
  ) {
    value = "Pool";
  } else if (getCoreLogicResponse?.data?.poolInfo === "") {
    value = "PNone";
  }

  if (getCoreLogicResponse?.data?.carParking === 0) {
    garageValue = "CNone";
  } else if (getCoreLogicResponse?.data?.carParking > 0) {
    garageValue = "Garage";
  }

  if (getCoreLogicResponse?.data?.firePlace === 0) {
    firePl = "No";
  } else if (
    getCoreLogicResponse?.data?.firePlace > 0 ||
    getCoreLogicResponse?.data?.firePlace === "Y"
  ) {
    firePl = "Yes";
  }

  if (
    getCoreLogicResponse?.data?.firePlace === 0 ||
    getCoreLogicResponse?.data?.firePlace === ""
  ) {
    airCond = "No";
  } else if (getCoreLogicResponse?.data?.firePlace > 0) {
    airCond = "Yes";
  }

  if (getCoreLogicResponse?.data?.type === "SFR") {
    landUseValue = "SFR";
  } else if (
    getCoreLogicResponse?.data?.type === "COMMERCIAL BUILDING" ||
    getCoreLogicResponse?.data?.type === "COMMERCIAL (NEC)"
  ) {
    landUseValue = "Commercial And Mixed Use";
  } else if (getCoreLogicResponse?.data?.type === "CONDOMINIUM") {
    landUseValue = "CondoAppartmentTownHouse";
  } else if (getCoreLogicResponse?.data?.type === "VACANT LAND (NEC)") {
    landUseValue = "VacantLand";
  }

  return {
    files: "",
    askingPrice: response?.data?.askingPrice || "",
    afterRepairValue: response?.data?.afterRepairValue || "",
    sellTime: response?.data?.sellTime || "",
    propertyStatus: response?.data?.propertyStatus || "",
    bedrooms:
      getCoreLogicResponse?.data?.bedrooms?.toString() ||
      response?.data?.bedrooms ||
      "",
    bathrooms:
      getCoreLogicResponse?.data?.bathrooms?.toString() ||
      response?.data?.bathrooms ||
      "",
    yearBuilt:
      getCoreLogicResponse?.data?.yearBuilt || response?.data?.yearBuilt || "",
    floors:
      getCoreLogicResponse?.data?.floors?.toString() ||
      response?.data?.floors ||
      "",
    squareFootage:
      getCoreLogicResponse?.data?.totalBuildingArea ||
      response?.data?.squareFootage ||
      "",
    lotSize:
      getCoreLogicResponse?.data?.lotSize || response?.data?.lotSize || "",
    description: response?.data?.description || "",
    type: landUseValue || response?.data?.type || "",
    occupancyStatus: response?.data?.occupancyStatus || "",
    poolInfo: value || response?.data?.poolInfo || "",
    carParking: garageValue || response?.data?.carParking || "",
    firePlace: firePl || response?.data?.firePlace || "",
    airCondition: airCond || response?.data?.airCondition || "",
    firePlaceValue: response?.data?.firePlaceValue || "",

    // Step2
    fairMarketPrice: response?.data?.fairMarketPrice || "",
    suggestedRehab: response?.data?.suggestedRehab || "",
    comments: response?.data?.comments || "",
    fixAndFlip: response?.data?.fixAndFlip || "",
    cashOnlyRequest: response?.data?.cashOnlyRequest || false,
    offerDeadLineDate: response?.data?.offerDeadLine || "",
    similarLinks: "",
    offerDeadLineTime:
      response?.data?.offerDeadLineTime ||
      new Date().toLocaleTimeString("en-US") ||
      "",
  };
}

const step1Schema = (userRole, isVacantSelected) => {
  if (userRole === "OTHER" && !isVacantSelected) {
    let schemaAttributes = {
      sellTime: Yup.string()
        .trim()
        .required("How Soon Do You Want to Sell The Property is required."),
      bedrooms: Yup.string()
        .trim()
        .required("bathrooms dropdown value is required."),
      bathrooms: Yup.string()
        .trim()
        .required("Bed dropdown value is required."),
      yearBuilt: Yup.string()
        .trim()
        .min(4, "Too Short!")
        .matches(validYear, "Enter a valid year")
        .required("Year Built is required in YYYY format."),
      floors: Yup.string().trim().required("Total Floor is required."),
      squareFootage: Yup.string()
        .trim()
        .required("Square Footage is required."),
      lotSize: Yup.string().trim().required("Lot Size is required."),
      type: Yup.string().trim().required("Type is required."),
      occupancyStatus: Yup.string()
        .trim()
        .required("Occupancy Status is required."),
      poolInfo: Yup.string().trim().required("Pool Info is required."),
      carParking: Yup.string().trim().required("Car Parking is required."),
      firePlace: Yup.string().trim().required("Fireplace is required."),
      airCondition: Yup.string().trim().required("Air Condition is required."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (userRole === "OTHER" && isVacantSelected) {
    let schemaAttributes = {
      lotSize: Yup.string().trim().required("Lot Size is required."),
      propertyStatus: Yup.string()
        .trim()
        .required("Property Status is required."),
      type: Yup.string().trim().required("Type is required."),
      occupancyStatus: Yup.string()
        .trim()
        .required("Occupancy Status is required."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (userRole === "AGENT" && !isVacantSelected) {
    let schemaAttributes = {
      bedrooms: Yup.string()
        .trim()
        .required("bathrooms dropdown value is required."),
      bathrooms: Yup.string()
        .trim()
        .required("Bed dropdown value is required."),
      yearBuilt: Yup.string()
        .trim()
        .matches(validYear, "Enter a valid year")
        .min(4, "Too Short!")
        .required("Year Built is required in YYYY format."),
      floors: Yup.string().trim().required("Total Floor is required."),
      squareFootage: Yup.string()
        .trim()
        .required("Square Footage is required."),
      lotSize: Yup.string().trim().required("Lot Size is required."),
      propertyStatus: Yup.string()
        .trim()
        .required("Property Status is required."),
      type: Yup.string().trim().required("Type is required."),
      occupancyStatus: Yup.string()
        .trim()
        .required("Occupancy Status is required."),
      poolInfo: Yup.string().trim().required("Pool Info is required."),
      carParking: Yup.string().trim().required("Car Parking is required."),
      firePlace: Yup.string().trim().required("Fireplace is required."),
      airCondition: Yup.string().trim().required("Air Condition is required."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (userRole === "AGENT" && isVacantSelected) {
    let schemaAttributes = {
      lotSize: Yup.string().trim().required("Lot Size is required."),
      propertyStatus: Yup.string()
        .trim()
        .required("Property Status is required."),
      type: Yup.string().trim().required("Type is required."),
      occupancyStatus: Yup.string()
        .trim()
        .required("Occupancy Status is required."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (userRole === "HOMEOWNER" && !isVacantSelected) {
    let schemaAttributes = {
      askingPrice: Yup.string().trim().required("Asking Price is required."),
      sellTime: Yup.string()
        .trim()
        .required("How Soon Do You Want to Sell The Property is required."),
      bedrooms: Yup.string()
        .trim()
        .required("bathrooms dropdown value is required."),
      bathrooms: Yup.string()
        .trim()
        .required("Bed dropdown value is required."),
      yearBuilt: Yup.string()
        .trim()
        .min(4, "Too Short!")
        .matches(validYear, "Enter a valid year")
        .required("Year Built is required in YYYY format."),
      floors: Yup.string().trim().required("Total Floor is required."),
      squareFootage: Yup.string()
        .trim()
        .required("Square Footage is required."),
      lotSize: Yup.string().trim().required("Lot Size is required."),
      type: Yup.string().trim().required("Type is required."),
      occupancyStatus: Yup.string()
        .trim()
        .required("Occupancy Status is required."),
      poolInfo: Yup.string().trim().required("Pool Info is required."),
      carParking: Yup.string().trim().required("Car parking is required."),
      firePlace: Yup.string().trim().required("Fireplace is required."),
      airCondition: Yup.string().trim().required("Air Condition is required."),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (userRole === "HOMEOWNER" && isVacantSelected) {
    let schemaAttributes = {
      askingPrice: Yup.string().trim().required("Asking price is required."),
      sellTime: Yup.string()
        .trim()
        .required("How Soon Do You Want to Sell The Property is required."),
      lotSize: Yup.string().trim().required("Lot Size is required."),
      type: Yup.string().trim().required("Type is required."),
      occupancyStatus: Yup.string()
        .trim()
        .required("Occupancy Status is required."),
    };
    return Yup.object().shape(schemaAttributes);
  }
};
const step2Schema = (userRole) => {
  if (userRole === "AGENT") {
    let schemaAttributes = {
      askingPrice: Yup.string().trim().required("Asking Price is required."),
      fairMarketPrice: Yup.string()
        .trim()
        .required("Fair Market Price is required."),
      afterRepairValue: Yup.string()
        .trim()
        .required("After Repair Value is required."),
      offerDeadLineDate: Yup.string().trim().required("Date is required."),
      estimatedRehabCost: Yup.string()
        .trim()
        .required("Estimated Rehab Cost is required."),
      fixAndFlip: Yup.string().trim().required("Strategy is required."),
      similarLinks: Yup.array().of(
        Yup.string()
          .required("This field is required")
          .matches(linkUrl, "Please enter a valid link.")
      ),
    };
    return Yup.object().shape(schemaAttributes);
  } else if (userRole === "OTHER") {
    let schemaAttributes = {
      askingPrice: Yup.string().trim().required("Asking Price is required."),
      afterRepairValue: Yup.string()
        .trim()
        .required("After Repair Value is required."),
      fairMarketPrice: Yup.string()
        .trim()
        .required("Fair Market Price is required."),
      estimatedRehabCost: Yup.string()
        .trim()
        .required("Estimated Rehab Cost is required."),
      similarLinks: Yup.array().of(
        Yup.string().matches(linkUrl, "Please enter a valid link.")
      ),
    };
    return Yup.object().shape(schemaAttributes);
  }
};
export function getRegisterSchema() {
  return [step1Schema, step2Schema];
}
