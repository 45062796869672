import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Spinner from 'web/components/Spinner';
import AlertModal from 'web/components/AlertModal';
import AccountContainer from 'web/components/AccountContainer';
import Layout from 'web/components/Layout';
import { confirmEmail } from 'redux/actions/auth'
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'web/components/utils/createPassword';
import styles from 'web/pages/account/emailVerify/emailVerify.module.scss';


const CreatePassword = (props) => {
  const loaderVisible = useSelector(
    (state) => state.appReducer.loaderVisible
  );

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalLink, setModalLink] = useState('/');
  const toggleModal = () => setModal(!modal);
  let title = '';


  useEffect(() => {
    const { location } = props;
    const queryParams = new URLSearchParams(location.search)
    for (const param of queryParams) {
    }

    let obj = {
      email: queryParams.get("email"),
      token: queryParams.get("token"),
    };

    const fetchData = async () => {
      const response = await dispatch(confirmEmail(obj));
      if (!response?.error) {
        setModalContent('Email confirmed successfully.');
        setModalLink('/login');
        toggleModal();
      } else {
        setModalContent(response?.data?.message);
        setModalLink('');
        toggleModal();
      }
    };
    fetchData()
  }, []);


  const formFields = () => {
    return (
      <div className={styles.body}>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values) => { }}>
          {(props) => {
            const { } = props;
            return (
              <>
                <Form className={styles.form}>
                  <div className={styles.innerSection}>
                    <div>
                      <h3>Your Email Address is verifying...</h3>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>

        <AlertModal
          isOpen={modal}
          toggle={toggleModal}
          title={title}
          content={modalContent}
          link={modalLink}
        />
      </div>
    );
  };
  return (
    <Layout transparentHeader={false}>
      <AccountContainer>
        {loaderVisible && <Spinner />}
        {formFields()}
      </AccountContainer>
    </Layout>
  );
};

export default CreatePassword;
