import * as Yup from "yup";
const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

export function getAuthInitialValues() {
  return {
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  };
}

export function getValidationSchema() {
  let schemaAttributes = {
    name: Yup.string()
      // .matches(/^(\w+ ?)*$/, "Enter valid name.")
      .matches(/^[^-\s@!$%][a-zA-Z ]+$/, "Enter valid name.")
      .required("Name is required."),
    email: Yup.string()
      .email("Enter valid email.")
      .required("Email is required."),
    phoneNumber: Yup.string()
      .matches(phoneRegex, "Phone number is not valid.")
      .required("Phone Number is required."),
    subject: Yup.string()
      .matches(
        /^[^-\s@!$%][a-zA-Z0-9\r\n<>?":{}+_()*&^%$#@!-=;'/\,.]+( [a-zA-Z0-9\r\n<>?":{}+_()*&^%$#@!-=;'/\,.]+)*$/,
        "Enter valid subject."
      )
      .required("Subject is required."),
    message: Yup.string()
      .matches(
        /^[^-\s@!$%][a-zA-Z0-9\r\n<>?":{}+_()*&^%$#@!-=;'/\,.]+( [a-zA-Z0-9\r\n<>?":{}+_()*&^%$#@!-=;'/\,.]+)*$/,
        "Enter valid message."
      )
      .required("Message is required."),
  };
  return Yup.object().shape(schemaAttributes);
}
