import styled from "styled-components";

const CongButton = styled.div`
display: flex;
justify-content: center;
padding:44px 0 50px;
  .comingButton{
      padding:10px 10px;
      margin-right:10px;
      width:34%;
      margin-left:1%;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
  }
  .comingButtonFirst{
    padding:10px 10px;
    margin-right:77px;
    width:34%;
    margin-left:1%;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    background-color:unset;
    color: #8B8EA7;
    border: 1px solid #CACACA;
    font-weight: 500;
}
  }
`;

const CloseSection = styled.div`
	margin-bottom: 40px;

	> svg {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
`;
export { CongButton, CloseSection };
