import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  MainSection,
  ProfileSection,
  Container,
  SocialMedia,
} from "web/pages/private/Profile/style";
import Layout from "web/components/Layout";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextInput from "web/components/form-elements/TextInput";
import TextArea from "web/components/form-elements/TextArea";
import NumberInput from "web/components/form-elements/NumberInput";
import CreatableSelect from "web/components/form-elements/creatableSelect";
import SelectField from "web/components/form-elements/SelectField";
import Grid from "@material-ui/core/Grid";
import headerLogo from "web/assets/images/headerLogo.png";
import profileSettings from "web/assets/images/profileEditIcon.svg";
import insta from "web/assets/images/insta.svg";
import facebook from "web/assets/images/facebook.svg";
import twitter from "web/assets/images/twitter.svg";
import InstaIcon from "web/assets/svg/InstaIcon";
import FacebookIcon from "web/assets/svg/FacebookIcon";
import TwitterIcon from "web/assets/svg/TwitterIcon";
import Avatar from "@material-ui/core/Avatar";
import ComingSoon from "web/components/comingSoonModal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfileData,
  getProfileData,
} from "redux/actions/userManagement/index";
import SettingButton from "web/assets/svg/SettingButton";
import {
  getAuthInitialValues,
  getValidationSchema,
} from "web/components/utils/profileUtils";
import editProfileIcon from "web/assets/images/editProfile.svg";
import Button from "web/components/Button";
import AlertModal from "web/components/AlertModal";
import { getCities, getStates } from "redux/actions/auth";
import Spinner from "web/components/Spinner";
import ChangePasswordModal from "web/components/ChangePasswordModal";
import ProfileIconEdit from "web/components/ProfileIconEdit";
import RenderSnackbar from "web/components/ProfileIconEdit/snackbar";
import SimpleBackdrop from "web/components/ProfileIconEdit/backdrop/backdrop";
import { userInitiate } from "redux/actions/action_types";
import EditIcon from "web/assets/svg/EditIcon";
import "./style.scss";

const Profile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { appReducer } = useSelector((state) => state);
  const [userRole, setUserRole] = useState("HOMEOWNER");
  const { userManagement } = useSelector((state) => state);
  const [showInstaTextBox, setShowInstaTextBox] = useState(true);
  const [showFbTextBox, setShowFbTextBox] = useState(true);
  const [showTwitterTextBox, setShowTwitterTextBox] = useState(true);
  const [city, setCity] = useState([]);
  const [getState, setState] = useState([]);
  const [states, setStates] = useState([]);
  const [isDisabled, setDisabled] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [toggleEditOption, settoggleEditOption] = useState(true);
  const [isResponse, setIsResponse] = useState();
  const [userImage, setUserImage] = useState();
  const [comingSoonModal, setComingSoonModal] = useState(false);
  const toggleComingSoonModal = () => setComingSoonModal(!comingSoonModal);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  let title = "";
  const [stateVal, setStateVal] = useState();
  const [showCropper, setShowCropper] = React.useState(false);
  const handleCropper = () => setShowCropper((prevValue) => !prevValue);

  useEffect(() => {
    setUserRole(userManagement?.profileData?.role);
    const fetchDataCity = async (setFieldValue) => {
      const response = await dispatch(getCities());
      const stateResponse =
        getState &&
        getState?.length >= 1 &&
        (await dispatch(getStates(getState)));
      let state = [];
      getState &&
        getState?.length >= 1 &&
        stateResponse?.data?.forEach((item) => {
          state?.push({ label: item, value: item });
        });
      if (setFieldValue) {
        setFieldValue("state", states);
      }
      setStates(state);

      let city = [];
      response?.data?.length &&
        response?.data?.forEach((item) => {
          city.push({ label: item, value: item });
        });
      setCity(city);
    };
    fetchDataCity();
  }, [getState]);

  var styles = {
    body: {
      pointerEvents: "none",
    },
  };

  const toggleChangePasswordModal = () =>
    setChangePasswordModal(!changePasswordModal);
  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);
  useEffect(() => {
    let i = "";
    if (appReducer?.loaderVisible) {
      for (i in styles.body) {
        document.body.style[i] = styles.body[i];
      }
    } else {
      for (i in styles.body) {
        document.body.style[i] = null;
      }
    }
  }, [appReducer]);

  const [relationWithOwnerArr, setRelationWithOwnerArr] = useState([
    {
      label: "Relative",
      value: "Relative",
    },
    {
      label: "Friend",
      value: "Friend",
    },
  ]);

  useEffect(() => {
    let val = isResponse?.data?.relationWithOwner;
    if (val) {
      let found = false;
      relationWithOwnerArr.forEach((item) => {
        if (item.value === val) {
          found = true;
        }
      });
      if (!found) {
        setRelationWithOwnerArr([
          { label: val, value: val },
          ...relationWithOwnerArr,
        ]);
      }
    }
  }, [isResponse]);

  const onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    setDisabled(true);
    let props = values;
    if (userRole === "AGENT") {
      delete props.relationWithOwner;
    } else if (userRole === "HOMEOWNER") {
      delete props.agentLiscenceNo;
      delete props.brokerageName;
      delete props.relationWithOwner;
      delete props.websiteLink;
      delete props.bio;
    } else if (userRole === "OTHER") {
      delete props.agentLiscenceNo;
      delete props.brokerageName;
      delete props.websiteLink;
      delete props.bio;
    }
    delete props.confirmPassword;
    props["profilePic"] = userImage;
    if (props.profilePic === "") {
      delete props.profilePic;
    }
    let formData = new FormData();
    const registerData = props;
    for (var prop in registerData) {
      formData.append(prop, registerData[prop]);
    }
    const response = await dispatch(editProfileData(formData));
    if (!response?.error) {
      setModalContent("Profile updated successfully.");
      setModalLink("/profile");
      setDisabled(true);
      setToggled(false);
      settoggleEditOption(true);
      toggleModal();
      fetchData();
    } else {
      if (response.data.response.status === 404) {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
      } else {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
      }
      if (userRole === "AGENT") {
        props.relationWithOwner = "";
      } else if (userRole === "HOMEOWNER") {
        props.agentLiscenceNo = "";
        props.brokerageName = "";
        props.relationWithOwner = "";
        props.websiteLink = "";
        props.bio = "";
      } else if (userRole === "OTHER") {
        props.agentLiscenceNo = "";
        props.brokerageName = "";
        props.websiteLink = "";
        props.bio = "";
      }
      props.termsConditions = false;
      props.confirmPassword = props.password;
    }
  };
  const profileForm = (roling, setFieldVal) => {
    return (
      <>
        <Container>
          <div className="leftSection">
            {userRole === "AGENT" && (
              <Box className="BoxShowingName">
                {isResponse?.data?.agentLiscenceNo}
              </Box>
            )}
            <Box className="BoxShowingName">{isResponse?.data?.firstName}</Box>
            <Box className="BoxShowingName">{isResponse?.data?.email}</Box>
            <TextInput
              name="lastName"
              placeholder="Last Name*"
              showErrorMessages={true}
              disabled={isDisabled}
            />
            <NumberInput
              name="phone"
              placeholder="Phone&#42;"
              showErrorMessages={true}
              format="(###) ###-####"
              disabled={isDisabled}
            />
            {userRole === "AGENT" && (
              <>
                <TextInput
                  name="websiteLink"
                  placeholder="Personal Website"
                  showErrorMessages={true}
                  disabled={isDisabled}
                />
                <TextArea
                  name="bio"
                  placeholder="Personal Bio"
                  showErrorMessages={true}
                  disabled={isDisabled}
                  rows="3"
                />
              </>
            )}
            {userRole === "OTHER" && (
              <>
                {isDisabled ? (
                  <TextInput name="relationWithOwner" disabled={isDisabled} />
                ) : (
                  <CreatableSelect
                    id="custom-select"
                    data={relationWithOwnerArr}
                    name="relationWithOwner"
                    placeholder="Relationship with owner&#42;"
                    onChange={(value) => {
                      let isUniqueFile = relationWithOwnerArr.filter((item) => {
                        return item.value === value.value;
                      });
                      if (!isUniqueFile.length) {
                        isUniqueFile = value;
                      } else {
                        return;
                        isUniqueFile = [];
                      }
                      let ownerArray = [...relationWithOwnerArr, isUniqueFile];
                      setRelationWithOwnerArr(ownerArray);
                    }}
                  />
                )}
              </>
            )}
          </div>
          <div className="rightSection">
            {userRole === "AGENT" && (
              <TextInput
                name="brokerageName"
                placeholder="Brokerage Name&#42;"
                showErrorMessages={true}
                disabled={isDisabled}
              />
            )}
            <TextInput
              name="addressLine1"
              placeholder={
                userRole === "AGENT"
                  ? "Brokerage Address Line 1*"
                  : "Address Line 1*"
              }
              showErrorMessages={true}
              disabled={isDisabled}
            />
            <TextInput
              name="addressLine2"
              placeholder={
                userRole === "AGENT"
                  ? "Brokerage Address Line 2"
                  : "Address Line 2"
              }
              showErrorMessages={true}
              disabled={isDisabled}
            />
            {isDisabled ? (
              <>
                <TextInput
                  name="city"
                  placeholder="City&#42;"
                  showErrorMessages={true}
                  disabled={isDisabled}
                />
                <TextInput
                  name="state"
                  placeholder="State"
                  disabled={isDisabled}
                />
              </>
            ) : (
              <>
                <div>
                  <SelectField
                    name="city"
                    data={city}
                    placeholder="City&#42;"
                    onChangeHandler={(data) => {
                      setFieldVal("state", null);
                      setStateVal(data.value);
                    }}
                  />
                </div>
                <div>
                  <SelectField
                    name="state"
                    data={states}
                    placeholder="State&#42;"
                    onChangeHandler={(data) => {
                      setStateVal(data.value);
                    }}
                  />
                </div>
              </>
            )}
            <NumberInput
              name="zipCode"
              placeholder={
                userRole === "AGENT" ? "Brokerage Zip Code*" : "Zip Code*"
              }
              disabled={isDisabled}
              maxLength="6"
            />
          </div>
        </Container>
        <SocialMedia>
          {userRole === "AGENT" && (
            <Box className="socialMediaProfiles">
              <InstaIcon
                title="Enter Your Instagram Profile Link"
                onClick={() => {
                  if (isResponse?.data?.igLink || !isDisabled) {
                    setShowInstaTextBox(!showInstaTextBox);
                  }
                }}
              />

              <FacebookIcon
                title="Enter Your Facebook Profile Link"
                onClick={() => {
                  if (isResponse?.data?.fbLink || !isDisabled) {
                    setShowFbTextBox(!showFbTextBox);
                  }
                }}
              />

              <TwitterIcon
                title="Enter Your Twitter Profile Link"
                onClick={() => {
                  if (isResponse?.data?.twitterLink || !isDisabled) {
                    setShowTwitterTextBox(!showTwitterTextBox);
                  }
                }}
              />
            </Box>
          )}
          {showInstaTextBox ? (
            ""
          ) : (
            <TextInput
              name="igLink"
              disabled={isDisabled}
              placeholder="Enter Your Instagram Profile Link"
            />
          )}
          {showFbTextBox ? (
            ""
          ) : (
            <TextInput
              name="fbLink"
              disabled={isDisabled}
              placeholder="Enter Your Facebook Profile Link"
            />
          )}
          {showTwitterTextBox ? (
            ""
          ) : (
            <TextInput
              name="twitterLink"
              disabled={isDisabled}
              placeholder="Enter Your Twitter Profile Link"
            />
          )}
        </SocialMedia>
      </>
    );
  };

  let token = window.localStorage.getItem("userInfo");
  const home = () => {
    if (token) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  };

  const logout = () => {
    window.localStorage.removeItem("userInfo");
    dispatch({ type: userInitiate, payload: null });
    history.push("/login");
  };

  const appearance = () => {
    setComingSoonModal(true);
  };

  const editProfile = () => {
    setDisabled(false);
    setStateVal(isResponse?.data?.state);
    setToggled(true);
    settoggleEditOption(false);
  };

  const fetchData = async () => {
    const response = await dispatch(getProfileData(token));
    if (response.length !== 0) {
      setIsResponse(response);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formFields = () => {
    return (
      <Layout transparentHeader={false} pageType="Dashboard">
        <MainSection toggled={toggled}>
          <ProfileSection>
            <Grid className="sectionM" container spacing={0}>
              <Box className="settingsIconDropDown">
                <div className="dropdown">
                  <img
                    src={profileSettings}
                    alt="profileSettings"
                    className="dropbtn"
                  />
                  <div className="dropdown-content">
                    {toggleEditOption ? (
                      <Typography
                        onClick={editProfile}
                        className="dropdownContent"
                      >
                        Edit Profile
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() => {
                          setDisabled(true);
                          setToggled(false);
                          settoggleEditOption(true);
                        }}
                        className="dropdownContent"
                      >
                        Profile
                      </Typography>
                    )}
                    <Typography
                      onClick={() => {
                        setChangePasswordModal(true);
                      }}
                      className="dropdownContent"
                    >
                      Change Password
                    </Typography>
                    <Typography
                      onClick={appearance}
                      className="dropdownContent"
                    >
                      Appearance
                    </Typography>
                    <Typography onClick={logout} className="dropdownContent">
                      Logout
                    </Typography>
                  </div>
                </div>
              </Box>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className="registerSection">
                  <Box>
                    {isResponse && (
                      <Formik
                        initialValues={getAuthInitialValues(isResponse)}
                        validationSchema={getValidationSchema(
                          userRole,
                          showInstaTextBox,
                          showFbTextBox,
                          showTwitterTextBox
                        )}
                        onSubmit={onSubmit}
                      >
                        {(props) => {
                          const {
                            isValid,
                            dirty,
                            setFieldValue,
                            values,
                            handleSubmit,
                            errors,
                          } = props;
                          setState(values?.city);
                          return (
                            <>
                              <Form onSubmit={handleSubmit}>
                                <Box className="avatarSection">
                                  <Avatar
                                    className="avatar"
                                    alt="Remy Sharp"
                                    src={
                                      userImage
                                        ? URL.createObjectURL(userImage)
                                        : isResponse?.data?.profilePic
                                    }
                                  />
                                  {isDisabled ? (
                                    ""
                                  ) : (
                                    <EditIcon
                                      onClick={() => {
                                        handleCropper();
                                      }}
                                      className="editProfileIcon"
                                    />
                                    // <img
                                    // 	onClick={() => {
                                    // 		handleCropper();
                                    // 	}}
                                    // 	className="editProfileIcon"
                                    // 	src={editProfileIcon}
                                    // 	alt="editProfile"
                                    // />
                                  )}
                                </Box>
                                <Typography className="agent_text">
                                  {isResponse?.data?.firstName}&nbsp;
                                  {isResponse?.data?.lastName}
                                </Typography>

                                {userRole === "AGENT" &&
                                  profileForm("AGENT", setFieldValue)}
                                {userRole === "HOMEOWNER" &&
                                  profileForm("HOMEOWNER", setFieldValue)}
                                {userRole === "OTHER" &&
                                  profileForm("OTHER", setFieldValue)}

                                {isDisabled ? (
                                  ""
                                ) : (
                                  <>
                                    <Button
                                      id="buttonSave"
                                      type="app-orange-button submit"
                                      disabled={
                                        showInstaTextBox ||
                                        showFbTextBox ||
                                        showTwitterTextBox
                                          ? false
                                          : !isValid && !dirty
                                      }
                                    >
                                      Save
                                    </Button>
                                  </>
                                )}
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    )}
                    {showCropper && (
                      <RenderSnackbar>
                        <SimpleBackdrop>
                          <ProfileIconEdit
                            setDisabled={setDisabled}
                            setToggled={setToggled}
                            fetchData={fetchData}
                            sliderHide={isResponse?.data?.profilePic}
                            defaultImage={isResponse?.data?.profilePic}
                            handleCropper={handleCropper}
                            settoggleEditOption={settoggleEditOption}
                          />
                        </SimpleBackdrop>
                      </RenderSnackbar>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ProfileSection>
          <Box style={{ display: "none" }}>
            <ChangePasswordModal
              isOpen={changePasswordModal}
              toggle={toggleChangePasswordModal}
              title=""
            />
          </Box>
          <AlertModal
            isOpen={modal}
            toggle={toggleModal}
            title={title}
            content={modalContent}
            link={modalLink}
          />
          <ComingSoon
            isOpen={comingSoonModal}
            toggle={toggleComingSoonModal}
            title=""
          />
        </MainSection>
      </Layout>
    );
  };
  return (
    <>
      <div style={{ width: "100%" }} className="profileSection">
        {loaderVisible && <Spinner />}
        <img
          style={{ cursor: "pointer" }}
          src={headerLogo}
          alt="dark logo"
          onClick={home}
        />
        {formFields()}
      </div>
    </>
  );
};
export default Profile;
