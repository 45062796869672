import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import { propertyAutoSave } from "redux/actions/property";
import MultiSelectButton from "web/components/MultiSelectButton";
import SelectField from "web/components/form-elements/SelectField";
import NumberInput from "web/components/form-elements/NumberInput";
import { Container } from "web/pages/private/PropertyForm/CommonFields/styles";
import { useEffect } from "react";

const CommonFields = ({
  props,
  callBackFun,
  addressData,
  vacantSelected,
  coreLogicResponse,
}) => {
  const { setFieldValue, setErrors, resetForm } = props;
  const dispatch = useDispatch();
  const [hideMe, setHideMe] = useState(true);
  const [isVacantSelected, seIsVacantSelected] = useState(
    props.values.type == "VacantLand" ? true : false
  );
  useEffect(() => {
    if (coreLogicResponse?.data?.firePlace === 0) {
      setHideMe(true);
    } else if (
      coreLogicResponse?.data?.firePlace > 0 ||
      coreLogicResponse?.data?.firePlace === "Y"
    ) {
      setHideMe(false);
    }
  }, [coreLogicResponse?.data]);

  let bedsArr = [
    {
      label: "1",
      value: "1",
      id: "0",
    },
    {
      label: "2",
      value: "2",
      id: "2",
    },
    {
      label: "3",
      value: "3",
      id: "4",
    },
    {
      label: "4",
      value: "4",
      id: "6",
    },
    {
      label: "5",
      value: "5",
      id: "8",
    },
    {
      label: "6",
      value: "6",
      id: "10",
    },
    {
      label: "7",
      value: "7",
      id: "12",
    },
    {
      label: "8",
      value: "8",
      id: "14",
    },
    {
      label: "9",
      value: "9",
      id: "16",
    },
    {
      label: "10",
      value: "10",
      id: "18",
    },
  ];

  let bathBedArr = [
    {
      label: "1",
      value: "1",
      id: "0",
    },
    {
      label: "1.5",
      value: "1.5",
      id: "1",
    },
    {
      label: "2",
      value: "2",
      id: "2",
    },
    {
      label: "2.5",
      value: "2.5",
      id: "3",
    },
    {
      label: "3",
      value: "3",
      id: "4",
    },
    {
      label: "3.5",
      value: "3.5",
      id: "5",
    },
    {
      label: "4",
      value: "4",
      id: "6",
    },
    {
      label: "4.5",
      value: "4.5",
      id: "7",
    },
    {
      label: "5",
      value: "5",
      id: "8",
    },
    {
      label: "5.5",
      value: "5.5",
      id: "9",
    },
    {
      label: "6",
      value: "6",
      id: "10",
    },
    {
      label: "6.5",
      value: "6.5",
      id: "11",
    },
    {
      label: "7",
      value: "7",
      id: "12",
    },
    {
      label: "7.5",
      value: "7.5",
      id: "13",
    },
    {
      label: "8",
      value: "8",
      id: "14",
    },
    {
      label: "8.5",
      value: "8.5",
      id: "15",
    },
    {
      label: "9",
      value: "9",
      id: "16",
    },
    {
      label: "9.5",
      value: "9.5",
      id: "17",
    },
    {
      label: "10",
      value: "10",
      id: "18",
    },
  ];

  let floorArr = [
    {
      label: "1",
      value: "1",
      id: "1",
    },
    {
      label: "2",
      value: "2",
      id: "2",
    },
    {
      label: "3",
      value: "3",
      id: "3",
    },
    {
      label: "4",
      value: "4",
      id: "4",
    },
    {
      label: "5",
      value: "5",
      id: "5",
    },
  ];
  let airConditioningValues = [
    {
      label: "1",
      value: "1",
      id: "1",
    },
    {
      label: "2",
      value: "2",
      id: "2",
    },
    {
      label: "3",
      value: "3",
      id: "3",
    },
    {
      label: "4",
      value: "4",
      id: "4",
    },
    {
      label: "5",
      value: "5",
      id: "5",
    },
  ];

  const categoryName = [
    { buttonTxt: "SFR", value: "SFR", context: "type" },
    { buttonTxt: "MFR", value: "MFR", context: "type" },
    { buttonTxt: "Vacant Land", value: "VacantLand", context: "type" },
    {
      buttonTxt: "Commercial & Mixed Use",
      value: "CommercialAndMixedUse",
      context: "type",
    },
    {
      buttonTxt: "Condo/Apartment/Town Home",
      value: "CondoAppartmentTownHouse",
      context: "type",
    },
  ];
  const occupancyStatusName = [
    { buttonTxt: "Vacant", value: "Vacant", context: "occupancyStatus" },
    {
      buttonTxt: "Owner Occupied",
      value: "OwnerOccupied",
      context: "occupancyStatus",
    },
    {
      buttonTxt: "Tenant Occupied",
      value: "TenantOccupied",
      context: "occupancyStatus",
    },
  ];
  // poolInfo Button Name
  const poolValue = [
    { buttonTxt: "Pool", value: "Pool", context: "poolInfo" },
    { buttonTxt: "Spa", value: "Spa", context: "poolInfo" },
    { buttonTxt: "Both", value: "PBoth", context: "poolInfo" },
    { buttonTxt: "NA", value: "PNone", context: "poolInfo" },
  ];

  const firePlaceButton = [
    { buttonTxt: "Yes", value: "Yes", context: "firePlace" },
    { buttonTxt: "No", value: "No", context: "firePlace" },
  ];
  const airConditionVal = [
    { buttonTxt: "Yes", value: "Yes", context: "airCondition" },
    { buttonTxt: "No", value: "No", context: "airCondition" },
  ];

  const garageValue = [
    { buttonTxt: "Garage", value: "Garage", context: "carParking" },
    { buttonTxt: "Carport", value: "Carport", context: "carParking" },
    { buttonTxt: "Both", value: "CBoth", context: "carParking" },
    { buttonTxt: "NA", value: "CNone", context: "carParking" },
  ];
  const formAutoSave = async (value) => {
    const allAddressData = addressData.allAddressData;
    let newPorpertyData = { ...value, ...allAddressData };
    // const autoSaveValue = value
    const response = await dispatch(propertyAutoSave(newPorpertyData));
  };

  return (
    <>
      <Box id="propertyType" className="buttonsSection">
        <h6 className="SubHeaderSecond">Select Category*</h6>
        <box className="Buttons">
          <MultiSelectButton
            onBlur={(e) => {
              callBackFun("type", e);
              formAutoSave({ type: e });
              setFieldValue("lotSize", "");
            }}
            props={props}
            name="type"
            data={categoryName}
            labelKey="buttonTxt"
            valueKey="value"
            setValue={(val) => {
              let vacantFlag = false;
              if (val === "VacantLand") {
                vacantFlag = true;
                setFieldValue("occupancyStatus", "Vacant");
              }
              formAutoSave({ occupancyStatus: "Vacant" });
              seIsVacantSelected(vacantFlag);
              setFieldValue("type", val);
              vacantSelected(vacantFlag);
            }}
            defaultIndex={0}
          />
        </box>
      </Box>

      <Box id="OccupanyStatus" className="buttonsSection">
        <h6 className="SubHeaderStatus">Occupancy Status*</h6>
        <box className="Buttons">
          <MultiSelectButton
            onBlur={(e) => {
              callBackFun("occupancyStatus", e);
              formAutoSave({ occupancyStatus: e });
            }}
            props={props}
            name="occupancyStatus"
            data={occupancyStatusName}
            labelKey="buttonTxt"
            valueKey="value"
            setValue={(val) => {
              setFieldValue("occupancyStatus", val);
            }}
            defaultIndex={0}
          />
        </box>
      </Box>

      {!isVacantSelected && (
        <>
          <Box id="propetyDetails">
            <h6 className="propertyDetailsTxt">Property Details</h6>
            <div className="beds_Bath">
              <div className="beds">
                <p className="propertyDetailsTxtBeds"> Beds*</p>
                <SelectField
                  onBlur={(e) => {
                    callBackFun("bedrooms", e);
                    formAutoSave({ bedrooms: e });
                  }}
                  name="bedrooms"
                  data={bedsArr}
                  placeholder=""
                />
              </div>
              <div className="baths">
                <p className="propertyDetailsTxtBeds"> Baths*</p>
                <SelectField
                  onBlur={(e) => {
                    callBackFun("bathrooms", e);
                    formAutoSave({ bathrooms: e });
                  }}
                  name="bathrooms"
                  data={bathBedArr}
                  placeholder=""
                />
              </div>
            </div>
          </Box>
          <Box>
            <h6 className="SubHeaderSecond">Year Built*</h6>
            <NumberInput
              id="yearBuilt"
              onBlur={(e) => {
                callBackFun("yearBuilt", e);
                formAutoSave({ yearBuilt: e });
              }}
              name="yearBuilt"
              format="####"
              placeholder=""
            />
          </Box>
          <Box className="buttonsSection">
            <h6 className="SubHeaderStatus">Pool Information*</h6>
            <box className="Buttons">
              <MultiSelectButton
                onBlur={(e) => {
                  callBackFun("poolInfo", e);
                  formAutoSave({ poolInfo: e });
                }}
                props={props}
                name="poolInfo"
                data={poolValue}
                labelKey="buttonTxt"
                valueKey="value"
                setValue={(val) => {
                  setFieldValue("poolInfo", val);
                }}
              />
            </box>
          </Box>
          <Box className="buttonsSection">
            <h6 className="SubHeaderStatus">Garage Information*</h6>
            <box className="Buttons">
              <MultiSelectButton
                onBlur={(e) => {
                  callBackFun("carParking", e);
                  formAutoSave({ carParking: e });
                }}
                props={props}
                name="carParking"
                data={garageValue}
                labelKey="buttonTxt"
                valueKey="value"
                setValue={(val) => {
                  setFieldValue("carParking", val);
                }}
              />
            </box>
          </Box>{" "}
          <Box className="buttonsSection">
            <h6 className="SubHeaderStatus">Fireplace*</h6>

            <box className="Buttons">
              <Container>
                <MultiSelectButton
                  onBlur={(e) => {
                    if (e === "Yes") {
                      setHideMe(false);
                    } else if (e === "No") {
                      setHideMe(true);
                    }
                    callBackFun("firePlace", e);
                    formAutoSave({ firePlace: e });
                  }}
                  props={props}
                  name="firePlace"
                  data={firePlaceButton}
                  className="multibutton"
                  labelKey="buttonTxt"
                  valueKey="value"
                  setValue={(val) => {
                    setFieldValue("firePlace", val);
                  }}
                />
                {!hideMe && (
                  <div className="selectFireplace">
                    <SelectField
                      onBlur={(e) => {
                        callBackFun("firePlaceValue", e);
                        formAutoSave({ firePlaceValue: e });
                      }}
                      name="firePlaceValue"
                      data={airConditioningValues}
                      placeholder="No. of Fireplace"
                    />
                  </div>
                )}
              </Container>
            </box>
          </Box>
          <Box className="buttonsSection">
            <h6 className="SubHeaderStatus">Air Conditioning*</h6>
            <box className="Buttons">
              <MultiSelectButton
                onBlur={(e) => {
                  callBackFun("airCondition", e);
                  formAutoSave({ airCondition: e });
                }}
                props={props}
                name="airCondition"
                data={airConditionVal}
                labelKey="buttonTxt"
                valueKey="value"
                setValue={(val) => {
                  setFieldValue("airCondition", val);
                }}
              />
            </box>
          </Box>
          <Box>
            <h6 className="SubHeaderStatus">Total Floors*</h6>
            <SelectField
              onBlur={(e) => {
                callBackFun("floors", e);
                formAutoSave({ floors: e });
              }}
              name="floors"
              data={floorArr}
              placeholder=""
            />
          </Box>
          <Box>
            <h6 className="SubHeaderStatus">Square Footage*</h6>
            <NumberInput
              onBlur={(e) => {
                callBackFun("squareFootage", e);
                formAutoSave({ squareFootage: e });
              }}
              name="squareFootage"
              thousandSeparator={true}
              placeholder=""
            />
          </Box>
        </>
      )}

      <Box>
        <h6 className="SubHeaderStatus">Lot Size*</h6>
        <NumberInput
          onBlur={(e) => {
            callBackFun("lotSize", e);
            formAutoSave({ lotSize: e });
          }}
          name="lotSize"
          placeholder=""
          thousandSeparator={true}
        />
      </Box>
    </>
  );
};

export default CommonFields;
