import * as React from "react";

const EditIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={43} height={43} {...props}>
			<defs>
				<filter
					id="prefix__a"
					x={0}
					y={0}
					width={43}
					height={43}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="blur" />
					<feFlood floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="Component 61 \u2013 7">
				<g filter="url(#prefix__a)">
					<circle
						data-name="Ellipse 135"
						cx={12.5}
						cy={12.5}
						r={12.5}
						transform="translate(9 6)"
						fill="#e45829"
					/>
				</g>
				<path
					data-name="Icon material-edit"
					d="M16.615 21.35v2.035h2.035l6-6-2.035-2.037zm9.611-5.541a.54.54 0 000-.765l-1.27-1.27a.54.54 0 00-.765 0l-.993.993 2.035 2.035.993-.993z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default EditIcon;
