import React from "react";
import {
  MainSection,
  SelectTypeSection,
} from "web/pages/account/Registration/style";
import Typography from "@material-ui/core/Typography";
import agentImg from "web/assets/images/real-estate-agent.svg";
import homeOwnerImg from "web/assets/images/homeOwnerSvg.png";
import othersImg from "web/assets/images/otherSvg.png";
import whiteShape from "web/assets/images/Shape.png";
import { useHistory } from "react-router-dom";
import Layout from "web/components/Layout";
import Box from "@material-ui/core/Box";
import AccountContainer from "web/components/AccountContainer";

const Registration = () => {
  const history = useHistory();

  let userRoleArr = [
    {
      title: "Agent",
      des: "If you are a registered real estate agent, please click here.",
      img: agentImg,
    },
    {
      title: "Homeowner",
      des: "If you are a homeowner interested in selling your property, please click here.",
      img: homeOwnerImg,
    },
    {
      title: "Others",
      des: "If you are not a licensed agent but have a prospective lead, please click here.",
      img: othersImg,
    },
  ];

  const roleType = (title) => {
    history.push({
      pathname: "/user-roles",
      search: "?type=" + title.toString(),
    });
  };

  return (
    <Layout transparentHeader={false}>
      <AccountContainer className="12layout" noImage={true}>
        <MainSection>
          <Typography className="textA"> Registering As?</Typography>
          <SelectTypeSection>
            <Box className="sectionMain">
              {userRoleArr.map((item, index) => (
                <Box
                  onClick={() => roleType(item.title)}
                  className="sectionType"
                  key={index}
                >
                  <Box className="boxes">
                    <img
                      className="registerIcon"
                      src={item.img}
                      alt="agentImg"
                    />

                    <img
                      src={whiteShape}
                      className="whiteShape"
                      alt="whiteShape"
                    />
                    <Box className="textBox">
                      <Typography className="desc_text">{item.des}</Typography>
                      <Typography className="type_text">
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </SelectTypeSection>
        </MainSection>
      </AccountContainer>
    </Layout>
  );
};
export default Registration;
