import React, { useState, useEffect } from 'react';
import {
  MainSection,
  SelectTypeSection,
  ImageSection,
} from 'web/pages/account/Registration/agentVerification/style';
import Typography from '@material-ui/core/Typography';
import headerLogo from 'web/assets/images/headerLogo.png';
import { useHistory } from 'react-router-dom';
import Layout from 'web/components/Layout';
import Box from '@material-ui/core/Box';
import Button from 'web/components/Button';
import {
  getAuthInitialValues,
  getValidationSchema,
} from 'web/components/utils/agentVerification';
import { Form, Formik } from 'formik';
import SelectField from 'web/components/form-elements/SelectField';
import TextInput from 'web/components/form-elements/TextInput';
import CheckboxInput from 'web/components/form-elements/CheckboxInput';
import License from 'web/assets/images/License.svg';
import CourtHouse from 'web/assets/images/CourtHouse.svg';

let juris = [
  {
    label: 'AL',
    value: 'AL',
    id: '1',
  },
  {
    label: 'AK',
    value: 'AK',
    id: '2',
  },
];

const Registration = () => {
  const history = useHistory();
  const [active, setActive] = useState(false);

  const homePage = () => {
    let token = window.localStorage.getItem('userInfo');
    if (token) {
      history.push('/dashboard');
    } else {
      history.push('/');
    }
  };

  const handleNext = (props) => {
    history.push({
      pathname: '/user-roles',
      search: '?type=' + 'Agent',
    });
  };

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <Layout transparentHeader={false} pageType='Register'>
      <ImageSection>
        <img
          className='headerLogo'
          src={headerLogo}
          alt='logo'
          onClick={homePage}
        />
      </ImageSection>
      <MainSection>
        <Typography className='textA'>Agent Registration Form</Typography>
        <Typography className='textB'>
          Please Input Your Real Estate License Number To Auto Populate The Form
        </Typography>
        <div className=''>
          <Formik
            initialValues={getAuthInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={(values) => {}}>
            {(props) => {
              const { isValid, dirty, setFieldValue } = props;
              return (
                <>
                  <Box className='loginWrapper'>
                    <Form>
                      <Box className='sectionFirst'>
                        <img className='License' src={License} alt='License' />
                        <TextInput
                          name='agentLicenseNumber'
                          placeholder='Agent License Number'
                          showErrorMessages={true}
                        />
                      </Box>
                      <Box className='sectionSecond'>
                        <img
                          className='CourtHouse'
                          src={CourtHouse}
                          alt='CourtHouse'
                        />
                        <SelectField
                          name='Jurisdiction'
                          data={juris}
                          placeholder='Jurisdiction'
                        />
                      </Box>
                      <CheckboxInput
                        name='withoutLicenseNumber'
                        label='Proceed without agent license number'
                        handleSelect={(e) => {
                          setActive(e);
                        }}
                      />
                      <Box className='sectionThird'>
                        {active ? (
                          <Button
                            type='app-orange-button submitBtn '
                            disabled={!(isValid && dirty)}
                            handleClick={() => {
                              handleNext(props);
                            }}>
                            Submit
                          </Button>
                        ) : (
                          <Button
                            type='app-orange-button submitBtn '
                            disabled={!dirty}
                            handleClick={() => {
                              handleNext(props);
                            }}>
                            Submit
                          </Button>
                        )}
                      </Box>
                    </Form>
                  </Box>
                </>
              );
            }}
          </Formik>
        </div>
      </MainSection>
    </Layout>
  );
};
export default Registration;
