import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { loginGoogle } from 'redux/actions/facebookGoogle/index';
import AlertModal from 'web/components/AlertModal';
import style from 'web/components/Google/google.scss';

const Google = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalLink, setModalLink] = useState('/');
  const toggleModal = () => setModal(!modal);
  let title = '';

  const responseGoogle = async (response) => {
    if (response) {
      let responseAuth = await dispatch(
        loginGoogle({
          accessToken: response.accessToken,
          tokenId: response.tokenId,
        })
      );
      handleToken(responseAuth?.data?.token);
    }
  };
  const handleToken = (token) => {
    if (token) {
      localStorage.setItem('userInfo', token);
      history.push('/dashboard');
    } else {
      setModalContent('You are not registered user.');
      setModalLink('');
      toggleModal();
      history.push('/registration');
    }
  };
  return (
    <>
      <GoogleLogin
        className='socialBtn'
        clientId='324487431251-p844uir791d37r4h6lrua2kesc129mga.apps.googleusercontent.com'
        buttonText='Google'
        onSuccess={(response) => responseGoogle(response)}
        onFailure={(err) => responseGoogle()}
        cookiePolicy={'single_host_origin'}
      />
      <AlertModal
        isOpen={modal}
        toggle={toggleModal}
        title={title}
        content={modalContent}
        link={modalLink}
      />
    </>
  );
};

export default Google;
