import {
  SIGN_IN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  userInitiate,
  SIGN_UP,
  CONFIRM_EMAIL,
  GET_CITIES,
  GET_STATES,
  AGENT_VERIFICATION
} from 'redux/actions/action_types';

const INITIAL_STATE = {
  user: null,
  allUserData: null,
  signedUserData: null,
  forgotPasswordData: null,
  ApiGoogleAddressData: null,
  changePassword: null,
  confirmEmail: null,
  getCities: null,
  getStates: null,
  agentVerification: null,
};
const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case userInitiate:
      return INITIAL_STATE;
    case SIGN_IN:
      return { ...state, allUserData: payload };
    case SIGN_UP:
      return { ...state, signedUserData: payload };
    case FORGOT_PASSWORD:
      return { ...state, forgotPasswordData: payload };
    case CHANGE_PASSWORD:
      return { ...state, changePassword: payload };
    case CONFIRM_EMAIL:
      return { ...state, confirmEmail: payload };
    case GET_CITIES:
      return { ...state, getCities: payload };
    case GET_STATES:
      return { ...state, getStates: payload };
    case AGENT_VERIFICATION:
      return { ...state, agentVerification: payload };
    default:
      return state;
  }
};
export default authReducer;
