import axioPath from 'api/axioPath';
import {
  EDIT_PROFILE,
  GET_PROFILE,
  EDIT_PASSWORD,
} from 'redux/actions/action_types';

export const editProfileData = (data) => async (dispatch) => {
  const token = await window.localStorage.getItem('userInfo');
  return axioPath
    .post('/auth/edit-profile', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: EDIT_PROFILE, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};

export const getProfileData = (token) => async (dispatch) => {
  return axioPath
    .get('/auth/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: GET_PROFILE, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      return ex;
    });
};

export const changeUserPassword = (data) => async (dispatch) => {
  const token = await window.localStorage.getItem('userInfo');
  return axioPath
    .post('/auth/updatePassword', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: EDIT_PASSWORD, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};
