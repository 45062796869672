import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "web/components/Button";
import CloseIcon from "web/assets/images/CloseIcon.png";
import { Modal, ModalBody } from "reactstrap";
import { ModalSection } from "web/components/ChangePasswordModal/style";
import TextInput from "web/components/form-elements/TextInput";
import Layout from "web/components/Layout";
import {
	INITIAL_VALUES,
	VALIDATION_SCHEMA,
} from "web/components/utils/changePassword";
import LockIcon from "web/assets/svg/LockIcon";
import EndIcon from "web/assets/svg/PasswordShowIcon";
import PasswordIconText from "web/assets/svg/PasswordIconText";
import { IconInputField } from "web/styles/Global";
import AlertModal from "web/components/AlertModal";
import Spinner from "web/components/Spinner";
import { changeUserPassword } from "redux/actions/userManagement";
import "web/components/ChangePasswordModal/changePassword.scss";

const PropertyModal = ({ isOpen, toggle }) => {
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [newPassword, setNewPassword] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState(false);

	const [modal, setModal] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [modalLink, setModalLink] = useState("/");
	const toggleModal = () => setModal(!modal);
	let title = "";

	const formData = async (props) => {
		const response = await dispatch(changeUserPassword(props.values));
		if (!response.error) {
			setModalContent("Password changed successfully.");
			setModalLink("/dashboard");
			toggleModal();
		} else {
			if (response.data.response.status === 404) {
				setModalContent(response?.data?.response?.data?.message);
				setModalLink("");
				toggleModal();
			} else {
				setModalContent(response?.data?.response?.data?.message);
				setModalLink("");
				toggleModal();
			}
		}
	};

	const loaderVisible = useSelector(
		(state: any) => state.appReducer.loaderVisible
	);

	const redirectLink = (link) => {
		toggle(!isOpen);
	};

	const formFields = () => {
		return (
			<ModalSection>
				<div className="property-modal">
					<Modal
						isOpen={isOpen}
						toggle={toggle}
						backdrop={"static"}
						centered={true}
						className="changePasswordModal"
					>
						<div className="modal-content">
							<div className="header">
								<Button
									handleClick={() => {
										redirectLink("");
									}}
								>
									<img src={CloseIcon} className="closeIcon" alt="close-icon" />
								</Button>
								<h3>Change Password</h3>
							</div>
							<ModalBody>
								<div
									style={{ paddingTop: 70, paddingBottom: 70 }}
									className="container"
								>
									<Formik
										initialValues={INITIAL_VALUES}
										validationSchema={VALIDATION_SCHEMA}
										onSubmit={(values) => {}}
									>
										{(props) => {
											const { isValid, dirty } = props;
											return (
												<>
													<Form>
														<IconInputField>
															<TextInput
																name="currentPassword"
																type={showPassword ? "text" : "password"}
																placeholder="Current password&#42;"
															/>
															<LockIcon className="startIcon" />
															{!showPassword ? (
																<EndIcon
																	className="passwordIcon"
																	onClick={() => {
																		setShowPassword(!showPassword);
																	}}
																/>
															) : (
																<PasswordIconText
																	className="passwordIcon"
																	onClick={() => {
																		setShowPassword(!showPassword);
																	}}
																/>
															)}
														</IconInputField>
														<IconInputField>
															<TextInput
																name="newPassword"
																type={newPassword ? "text" : "password"}
																placeholder="New Password&#42;"
															/>
															<LockIcon className="startIcon" />
															{!newPassword ? (
																<EndIcon
																	className="passwordIcon"
																	onClick={() => {
																		setNewPassword(!newPassword);
																	}}
																/>
															) : (
																<PasswordIconText
																	className="passwordIcon"
																	onClick={() => {
																		setNewPassword(!newPassword);
																	}}
																/>
															)}
														</IconInputField>

														<IconInputField>
															<TextInput
																name="confirmPassword"
																type={confirmPassword ? "text" : "password"}
																placeholder="Confirm New Password&#42;"
															/>
															<LockIcon className="startIcon" />
															{!confirmPassword ? (
																<EndIcon
																	className="passwordIcon"
																	onClick={() => {
																		setConfirmPassword(!confirmPassword);
																	}}
																/>
															) : (
																<PasswordIconText
																	className="passwordIcon"
																	onClick={() => {
																		setConfirmPassword(!confirmPassword);
																	}}
																/>
															)}
														</IconInputField>

														<div className="modal-footer">
															<Button
																type="app-orange-button submit"
																handleClick={() => {
																	formData(props);
																}}
																disabled={!(isValid && dirty)}
															>
																Save
															</Button>
														</div>
													</Form>
												</>
											);
										}}
									</Formik>
								</div>
							</ModalBody>
						</div>
					</Modal>
				</div>
				<AlertModal
					isOpen={modal}
					toggle={toggleModal}
					title={title}
					content={modalContent}
					link={modalLink}
				/>
			</ModalSection>
		);
	};
	return (
		<Layout transparentHeader={false}>
			<div style={{ width: "100%" }}>
				{loaderVisible && <Spinner />}
				{formFields()}
			</div>
		</Layout>
	);
};

export default PropertyModal;
