import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Layout from "web/components/Layout";
import Box from "@material-ui/core/Box";
import { MainSection, StatusSection } from "web/pages/private/Dashboard/style";
import PropertyModal from "./PropertyModal";
import AlertModal from "web/components/AlertModal";
import plus from "web/assets/images/Icon awesome-plus-circle.svg";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getPropertyData } from "redux/actions/property";
import { getProfileData } from "redux/actions/userManagement";
import { useHistory } from "react-router-dom";
import Card from "web/components/Cards";
import showAll from "web/assets/images/All.png";
import Spinner from "web/components/Spinner";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import GoogleAddressComponent from "web/components/GoogleAddress/index";
import { Typography } from "@material-ui/core";
import "./style.css";

const schema = yup.object().shape({});

const Dashboard = () => {
	const [open, setOpen] = useState(false);
	const [addressLineData, setAddressLineData] = useState("");
	const [setAddress, getAddress] = useState("");
	const [setAllAddress, getAllAddress] = useState({});
	const [formattedData, setFormattedData] = useState({});
	const [response, setResponse] = useState();
	const [showFilters, setShowFilters] = useState(false);
	const [filteredData, setFilteredData] = useState();
	const [showAllProperties, setShowAllProperties] = useState(false);
	const [noProperty, setNoProperty] = useState(false);
	const [modal, setModal] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [modalLink, setModalLink] = useState("/");
	const toggleModal = () => setModal(!modal);
	let title = "";

	const [propertyModal, setPropertyModal] = useState(false);
	const togglePropertyModal = () => setPropertyModal(!propertyModal);
	const history = useHistory();
	const dispatch = useDispatch();
	const modalOpen = () => {
		togglePropertyModal();
		if (!errors.googleAddress) {
			setOpen(true);
		}
	};
	// test
	const { register, handleSubmit, errors, control } = useForm({
		mode: "onSubmit",
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
		shouldFocusError: true,
	});

	const formData = async (data) => {
		const addressData = setAddressLineData(data);
	};

	const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);

	let token = localStorage.getItem("userInfo");
	useEffect(() => {
		const fetchData = async () => {
			const response = await dispatch(getPropertyData(token));
			const profile = await dispatch(getProfileData(token));
			if (response.length !== 0) {
				setResponse(response);
				setFilteredData(response);
			}
			if (response.length === 0) {
				setNoProperty(true);
			} else {
				setNoProperty(false);
			}
			if (response.length >= 2) {
				setShowFilters(true);
			} else {
				setShowFilters(false);
			}
		};
		fetchData();
	}, []);

	const handleSelect = (address) => {
		geocodeByAddress(address)
			.then(async (results) =>
				getAllAddress({ results: results, lat: await getLatLng(results[0]) })
			)
			.catch((error) => console.error("Error", error));
	};

	return (
		<Layout transparentHeader={false} pageType="Dashboard">
			{loaderVisible && <Spinner />}
			<MainSection className="">
				<div>
					<form onSubmit={handleSubmit(formData)}>
						<div className="form_feild_section">
							<GoogleAddressComponent
								name="googleAddress"
								inputRef={register}
								value={setAddress}
								onSuggestionClick={(value) => {
									getAddress(value);
									handleSelect(value);
								}}
								onChange={(address) => {
									getAddress(address);
								}}
								onSelect={handleSelect}
							/>
							<Button
								type="submit"
								disabled={!setAddress}
								className="verifyBtn"
								onClick={modalOpen}
							>
								<img src={plus} alt="plus" />
							</Button>
						</div>
						{
							<h3 className="error-message">
								{errors.googleAddress && errors.googleAddress.message}
							</h3>
						}
					</form>
				</div>

				{filteredData && showFilters && (
					<StatusSection>
						<Box className="section">
							<Box
								className="submittedA"
								title="Congratuations! Your property has been   submitted successfully."
								onClick={() => {
									const filteredData =
										response &&
										response.filter(
											(response) => response.reviewStatus === "SUBMITTED"
										);
									setFilteredData(filteredData);
									{
										filteredData && filteredData.length === 0
											? setNoProperty(true)
											: setNoProperty(false);
									}
									setShowAllProperties(true);
								}}
							></Box>
							<Typography className="Status_text">Submitted</Typography>
						</Box>
						<Box className="section">
							<Box
								className="under_reviewA"
								title="Your property is under review. Please check back in sometime."
								onClick={() => {
									const filteredData =
										response &&
										response.filter(
											(response) => response.reviewStatus === "UNDER REVIEW"
										);
									setFilteredData(filteredData);
									{
										filteredData && filteredData.length === 0
											? setNoProperty(true)
											: setNoProperty(false);
									}
									setShowAllProperties(true);
								}}
							></Box>
							<Typography className="Status_text">Under Review</Typography>
						</Box>
						<Box className="section">
							<Box
								className="acceptedA"
								title="Congratulations! This property fits our parameters, expect an offer shortly."
								onClick={() => {
									const filteredData =
										response &&
										response.filter(
											(response) => response.reviewStatus === "ACCEPTED"
										);
									setFilteredData(filteredData);
									{
										filteredData && filteredData.length === 0
											? setNoProperty(true)
											: setNoProperty(false);
									}
									setShowAllProperties(true);
								}}
							></Box>
							<Typography className="Status_text">Accepted</Typography>
						</Box>
						<Box className="section">
							<Box
								className="passA"
								title="Thank you for your submission. Unfortunately, this property does not fit in our parameters at this time."
								onClick={() => {
									const filteredData =
										response &&
										response.filter(
											(response) => response.reviewStatus === "PASS"
										);
									setFilteredData(filteredData);
									{
										filteredData && filteredData.length === 0
											? setNoProperty(true)
											: setNoProperty(false);
									}
									setShowAllProperties(true);
								}}
							></Box>
							<Typography className="Status_text">Pass</Typography>
						</Box>
						{showAllProperties ? (
							<Box className="section">
								<Box
									className="showALl"
									onClick={() => {
										const filteredData =
											response &&
											response.filter(
												(response) =>
													response.reviewStatus === "PASS" ||
													response.reviewStatus === "ACCEPTED" ||
													response.reviewStatus === "UNDER REVIEW" ||
													response.reviewStatus === "SUBMITTED"
											);
										setFilteredData(filteredData);
										{
											filteredData && filteredData.length === 0
												? setNoProperty(true)
												: setNoProperty(false);
										}
										setShowAllProperties(false);
									}}
								>
									<img
										className="showAllimgSection"
										src={showAll}
										alt="showAll"
									></img>
								</Box>
								<Typography className="Status_text">All</Typography>
							</Box>
						) : (
								""
							)}
					</StatusSection>
				)}

				{noProperty ? (
					<Box className="noPropertyHereText">
						<Typography>
							Tell us about your property.
							<br />
							Enter property address above.{" "}
						</Typography>
					</Box>
				) : (
						""
					)}

				<Box className="card_section">
					{filteredData &&
						filteredData?.length > 0 &&
						filteredData?.map((item) => <Card data={item} />)}
				</Box>
			</MainSection>

			{propertyModal && setAllAddress && (
				<PropertyModal
					data={setAllAddress}
					isOpen={propertyModal}
					toggle={togglePropertyModal}
					title=""
				/>
			)}
			<AlertModal
				isOpen={modal}
				toggle={toggleModal}
				title={title}
				content={modalContent}
				link={modalLink}
			/>
		</Layout>
	);
};

export default Dashboard;
