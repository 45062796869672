import axioPath from 'api/axioPath';
import {
  GET_PROPERTY_DATA,
  ADD_PROPERTY_DATA,
  PROPERTY_AUTOSAVE,
  AUTOSAVE_GET_PROPERTY_DATA,
  DELETE_AUTOSAVE,
  GET_PROPERTY_DATA_BY_ID,
  GET_REDFIN_DATA,
  CORELOGIC_PROPERTY_DATA,
} from 'redux/actions/action_types';

export const addPropertyData = (data) => async (dispatch) => {
  let token = await window.localStorage.getItem('userInfo');
  return axioPath
    .post('/property', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: ADD_PROPERTY_DATA, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      return { error: true, ex: ex };
    });
};

export const propertyAutoSave = (data) => async (dispatch) => {
  let token = await window.localStorage.getItem('userInfo');
  return axioPath
    .post('/property/autoSave', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      hideLoader: true,
    })
    .then((response) => {
      dispatch({ type: PROPERTY_AUTOSAVE, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      return { error: true, ex: ex };
    });
};
export const getPropertyAutoSave = (token, AddressData) => async (dispatch) => {
  let token = await window.localStorage.getItem('userInfo');
  return axioPath
    .get('/property/autoSave', {
      params: {
        addressLine1: AddressData.addressLine1,
        addressLine2: AddressData.addressLine2,
        city: AddressData.city,
        state: AddressData.state,
        zipCode: AddressData.zipCode,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: AUTOSAVE_GET_PROPERTY_DATA, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      return { error: true, ex: ex };
    });
};
export const getCoreLogicData = (token, AddressData) => async (dispatch) => {
  let token = await window.localStorage.getItem('userInfo');
  return axioPath
    .get('/property-details', {
      params: {
        address: AddressData.addressLine1 + ' ' + AddressData.addressLine2,
        city: AddressData.city,
        state: AddressData.state,
        zipCode: AddressData.zipCode,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: CORELOGIC_PROPERTY_DATA, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      return { error: true, ex: ex };
    });
};

export const deleteAutosaveProperty = (allAddressData) => async (dispatch) => {
  let token = await window.localStorage.getItem('userInfo');
  return axioPath
    .delete('/property/autoSave', {
      params: {
        addressLine1: allAddressData.addressLine1,
        addressLine2: allAddressData.addressLine2,
        city: allAddressData.city,
        state: allAddressData.state,
        zipCode: allAddressData.zipCode,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: DELETE_AUTOSAVE, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      return { error: true, ex: ex };
    });
};

export const getPropertyData = (token) => (dispatch) => {
  return axioPath
    .get('/property', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: GET_PROPERTY_DATA, payload: response.data });
      return response.data;
    })
    .catch((ex) => {
      return ex;
    });
};
export const getPropertyById = (token, id) => (dispatch) => {
  return axioPath
    .get('/property/' + `${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: GET_PROPERTY_DATA_BY_ID, payload: response.data });
      return response.data;
    })
    .catch((ex) => {
      return ex;
    });
};

export const redfinData = (data) => (dispatch) => {
  return axioPath
    .get('/link-preview?link=' + `${data}`, { hideLoader: true })
    .then((response) => {
      dispatch({
        type: GET_REDFIN_DATA,
        payload: { ...response.data, link: data },
      });
      return response.data;
    })
    .catch((ex) => {
      return ex;
    });
};
