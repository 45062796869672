import styled from "styled-components";

const CloseSection = styled.div`
	margin-bottom: 40px;
	> svg {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
	}
`;
export { CloseSection };
