import React from "react";
import {
  Container,
  Wrapper,
  Row,
  Column,
  Link,
  Title,
  Term,
  SocialIcon,
  TermWrapper,
  FooterLogo,
  Heading,
} from "web/components/Footer/styles/footer";

export default function Footer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Footer.Wrapper = function FooterWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}> {children}</Wrapper>;
};

Footer.Row = function FooterRow({ children, ...restProps }) {
  return <Row {...restProps}> {children}</Row>;
};

Footer.Column = function FooterColumn({ children, ...restProps }) {
  return <Column {...restProps}>{children}</Column>;
};

Footer.Link = function FooterLink({ children, ...restProps }) {
  return <Link {...restProps}> {children}</Link>;
};

Footer.Heading = function FooterLink({ children, ...restProps }) {
  return <Heading {...restProps}> {children}</Heading>;
};

Footer.Title = function FooterTitle({ children, ...restProps }) {
  return <Title {...restProps}> {children}</Title>;
};

Footer.Term = function FooterTerm({ children, ...restProps }) {
  return <Term {...restProps}> {children}</Term>;
};
Footer.SocialIcon = function FooterSocialIcon({ children, ...restProps }) {
  return <SocialIcon {...restProps}> {children}</SocialIcon>;
};

Footer.TermWrapper = function FooterTermWrapper({ children, ...restProps }) {
  return <TermWrapper {...restProps}> {children}</TermWrapper>;
};

Footer.FooterLogo = function FooterFooterLogo({ children, ...restProps }) {
  return <FooterLogo {...restProps}> {children}</FooterLogo>;
};
