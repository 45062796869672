import React from 'react';
import { Field } from 'formik';

const TextInput = ({
  name,
  placeholder,
  id,
  maxLength,
  type,
  showErrorMessages = true,
  disabled,
  value,
  onBlur = () => { },
}) => (
    <Field name={name}>
      {({ field, meta, form }) => {
          
        //form.validateForm();
        if (type === undefined) {
          type = 'text';
        }
        if (disabled === undefined) disabled = false;
        return (
          <div className='app-input-text'>
            <input
              onBlurCapture={(values) => {
                onBlur(values.target.value)
              }}
              id={id}
              className={`${meta.touched && meta.error ? 'error' : ''}`}
              name={field.name}
              placeholder={placeholder}
              type={type}
              value={field.value || ''}
              maxLength={maxLength}
              disabled={disabled}
              onChange={(evt) => {
              }}
              {...field}
            />
            {/* )} */}
            {meta.touched && meta.error && showErrorMessages && (
              <p className='error-message'>{meta.error}</p>
            )}
          </div>
        );
      }}
    </Field>
  );

export default TextInput;
