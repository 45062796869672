import * as Yup from "yup";

const INITIAL_VALUES = {
	password: "",
	confirmPassword: "",
};

const VALIDATION_SCHEMA = Yup.object().shape({
	password: Yup.string()
		.label("Password")
		.required("Password is required.")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*#?&]{8,}$/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
		)
		.min(5, "Seems to be a bit short...")
		.max(12, "We prefer secure system, try a shorter password."),

	confirmPassword: Yup.string()
		.required("Confirm Password is required.")
		.oneOf([Yup.ref("password"), null], "Passwords must match."),
});

export { INITIAL_VALUES, VALIDATION_SCHEMA };
