import React, { useState } from "react";
import "./App.css";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import getCroppedImg, { generateDownload } from "./cropImage";
import { IconButton, makeStyles } from "@material-ui/core";
import { SnackbarContext } from "./snackbar";
import { dataURLtoFile } from "./dataURLtoFile";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfileData,
  getProfileData,
} from "redux/actions/userManagement/index";
import AlertModal from "web/components/AlertModal";
import { showAppLoader, hideAppLoader } from "redux/actions/app";
import { useEffect } from "react";

const useStyles = makeStyles({
  iconButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  cancelIcon: {
    color: "#d4c4c4",
    fontSize: "50px",
    "&:hover": {
      color: "red",
    },
  },
});

export default function RenderCropper({
  handleCropper,
  defaultImage,
  callbackFun,
  fetchData,
  setToggled,
  setDisabled,
  sliderHide,
  settoggleEditOption
}) {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = React.useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();
  const { userManagement } = useSelector((state) => state);
  const setStateSnackbarContext = React.useContext(SnackbarContext);
  const [image, setImage] = React.useState(defaultImage);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [hide, setHide] = useState(false);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setHide(false);
      });
    }
  };
  const onUpload = async () => {
    if (!image)
      return setStateSnackbarContext(
        true,
        "Please select an image!",
        "warning"
      );
    dispatch(showAppLoader());
    const canvas = await getCroppedImg(image, croppedArea);
    dispatch(hideAppLoader());
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(
      canvasDataUrl,
      "cropped-image.jpeg"
    );

    if (userManagement?.profileData?.role) {
      const data = [];
      data["profilePic"] = convertedUrlToFile;

      let formData = new FormData();
      const registerData = data;
      for (var prop in registerData) {
        formData.append(prop, registerData[prop]);
      }
      const response = await dispatch(editProfileData(formData));
      if (!response?.error) {
        setModalContent("Profile updated successfully.");
        setModalLink("");
        setDisabled(true);
        setToggled(false);
        toggleModal();
        fetchData();
        handleCropper();
        settoggleEditOption(true)
      } else {
        if (response.data.response.status === 404) {
          setModalContent(response?.data?.response?.data?.message);
          setModalLink("");
          toggleModal();
        } else {
          setModalContent(response?.data?.response?.data?.message);
          setModalLink("");
          toggleModal();
        }
      }
    } else {
      callbackFun(convertedUrlToFile);
      handleCropper();
    }
  };

  useEffect(() => {
    if (sliderHide) {
      setHide(true);
    }
  }, [sliderHide]);

  return (
    <div className="Maincontainer">
      {/* <div className="Wrapper"> */}
      <IconButton
        style={{ zIndex: 1 }}
        className={classes.iconButton}
        onClick={handleCropper}
      >
        <CancelIcon className={classes.cancelIcon} />
      </IconButton>

      <div className="container-cropper">
        {image ? (
          <>
            <div className="cropper">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={!hide ? setZoom : false}
                onCropComplete={onCropComplete}
              />
            </div>

            {!hide && (
              <div className="slider">
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e, zoom) => setZoom(zoom)}
                  color="secondary"
                />
              </div>
            )}
          </>
        ) : null}
      </div>

      <div className="container-buttons">
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        <Button
          variant="contained"
          className="choose-btn"
          onClick={triggerFileSelectPopup}
        >
          Choose
        </Button>
        <Button variant="contained" className="upload-btn" onClick={onUpload} disabled={!hide ? false :true}>
          Upload
        </Button>
      </div>
      <AlertModal
        isOpen={modal}
        toggle={toggleModal}
        content={modalContent}
        link={modalLink}
      />
      {/* </div> */}
    </div>
  );
}
