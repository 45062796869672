import * as Yup from 'yup';

export function getAuthInitialValues(obj) {
  if (obj.isChecked) {
    obj.isChecked = true;
  }
  return {
    email: obj.email || '',
    password: obj.password || '',
    rememberMe: obj.isChecked,
  };
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is mandatory.'),
  password: Yup.string()
    .label('Password')
    .required('Password is mandatory.')
    .min(6, 'Seems to be a bit short...')
    .max(12, 'We prefer secure system, try a shorter password.'),
});

export { VALIDATION_SCHEMA };
