import * as Yup from "yup";
const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

var linkUrl = RegExp(
  /[^\s][-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi
);

export function getAuthInitialValues(isResponse) {
  return {
    firstName: isResponse?.data?.firstName || "",
    lastName: isResponse?.data?.lastName || "",
    phone: isResponse?.data?.phone || "",
    email: isResponse?.data?.email || "",
    addressLine1: isResponse?.data?.addressLine1 || "",
    addressLine2: isResponse?.data?.addressLine2 || "",
    state: isResponse?.data?.state || "",
    city: isResponse?.data?.city || "",
    zipCode: isResponse?.data?.zipCode || "",
    // AGENT
    agentLiscenceNo: isResponse?.data?.agentLiscenceNo || "",
    brokerageName: isResponse?.data?.brokerageName || "",
    websiteLink: isResponse?.data?.websiteLink || "",
    bio: isResponse?.data?.bio || "",
    igLink: isResponse?.data?.igLink || "",
    fbLink: isResponse?.data?.fbLink || "",
    twitterLink: isResponse?.data?.twitterLink || "",

    // OTHER
    relationWithOwner: isResponse?.data?.relationWithOwner || "",

    profilePic: "",
  };
}

export function getValidationSchema(
  userRole,
  showInstaTextBox,
  showFbTextBox,
  showTwitterTextBox
) {
  let schema1;
  let schema2 = "";
  let schema3 = "";
  if (!showInstaTextBox) {
    schema1 = Yup.string()
      .matches(linkUrl, "Please enter a valid link.")
      .required("Please enter your Instagram profile link.");
  }
  if (!showFbTextBox) {
    schema2 = Yup.string()
      .matches(linkUrl, "Please enter a valid link.")
      .required("Please enter Facebook profile link.");
  }
  if (!showTwitterTextBox) {
    schema3 = Yup.string()
      .matches(linkUrl, "Please enter a valid link.")
      .required("Please enter Twitter profile link.");
  }
  if (userRole === "AGENT") {
    let schemaAttributes = {
      phone: Yup.string()
        .matches(phoneRegex, "Phone number is not valid.")
        .required("Phone Number is required."),
      addressLine1: Yup.string()
        .matches(
          /^[^\s][a-zA-Z0-9,_#/ ]+( [a-zA-Z0-9,_]+)*$/,
          "Enter valid address."
        )
        .required("Brokerage Address Line 1 is required."),
      addressLine2: Yup.string().matches(
        /^[^\s][a-zA-Z0-9,_#/ ]+( [a-zA-Z0-9,_]+)*$/,
        "Enter valid address."
      ),
      bio: Yup.string().matches(
        /^[^-\s@!$%][a-zA-Z0-9\r\n<>?":{}+_()*&^%$#@!-=;'/\,.]+( [a-zA-Z0-9\r\n<>?":{}+_()*&^%$#@!-=;'/\,.]+)*$/,
        "Enter valid bio."
      ),
      lastName: Yup.string()
        .matches(/^[^\s][a-zA-Z\s]+$/, "Enter valid name.")
        .required("Last Name is required."),
      state: Yup.string("State is required.")
        .trim("State is required.")
        .nullable("State is required")
        .required("State is required."),
      city: Yup.string().trim().required("City is required."),
      brokerageName: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9 ]+$/, "Enter valid name.")
        .required("Brokerage Name is required."),
      zipCode: Yup.string()
        .min(2, "Too Short!")
        .matches(/^[^\s]+$/, "Enter valid zipCode.")
        .required("Zip Code is required.")
        .max(5, "Maximum zip code length."),
      igLink: schema1,
      fbLink: schema2,
      twitterLink: schema3,
      websiteLink: Yup.string().matches(linkUrl, "Please enter a valid link."),
    };
    return Yup.object().shape(schemaAttributes);
  } else {
    let schemaAttributes = {
      phone: Yup.string()
        .matches(phoneRegex, "Phone number is not valid.")
        .required("Phone Number is required."),
      addressLine1: Yup.string()
        .matches(
          /^[^\s][a-zA-Z0-9,_#/ ]+( [a-zA-Z0-9,_]+)*$/,
          "Enter valid address."
        )
        .required("Brokerage Address Line 1 is required."),
      addressLine2: Yup.string().matches(
        /^[^\s][a-zA-Z0-9,_#/ ]+( [a-zA-Z0-9,_]+)*$/,
        "Enter valid address."
      ),
      state: Yup.string("State is required.")
        .trim("State is required.")
        .nullable("State is required.")
        .required("State is required."),
      city: Yup.string().trim().required("City is required."),
      zipCode: Yup.string()
        .min(2, "Too Short!")
        .matches(/^[^\s]+$/, "Enter valid zipCode.")
        .required("Zip Code is required.")
        .max(5, "Maximum zip code length."),
      lastName: Yup.string()
        .matches(/^[^\s][a-zA-Z\s]+$/, "Enter valid name.")
        .required("Last Name is required."),
    };
    return Yup.object().shape(schemaAttributes);
  }
}
