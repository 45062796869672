import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'web/theme/GlobalStyles';
import { useTheme } from 'web/theme/useTheme';
import { Helmet } from 'react-helmet';
import ReactRouter from 'web/components/Router';
import 'web/components/App/App.css';
import ThemeContext from './context.js';

const App = () => {
  const { theme, themeLoaded } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded]);

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <div className='App'>
            <Helmet title='Ladbrook'>
              <meta
                name='viewport'
                content='width=device-width, user-scalable=no'
              />
            </Helmet>
            <ThemeContext.Provider value={{ setSelectedTheme }}>
              <ReactRouter />
            </ThemeContext.Provider>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
