import React from 'react';
import { useHistory } from 'react-router-dom';
import headerLogo from 'web/assets/images/headerLogo.png';
import loginImage from 'web/assets/images/loginImage.png';
import blackLogo from 'web/assets/images/blackLogo.png';
import orangeLogo from 'web/assets/images/Orangelogo.png';
import styles from 'web/components/AccountContainer/accountContainer.module.scss'

const AccountContainer = ({ children, noImage }) => {
  const history = useHistory();
  const homePage = () => {
    let token = window.localStorage.getItem('userInfo');
    if (token) {
      history.push('/dashboard')
    } else {
      history.push('/')
    }
  }
  return (
    <>
      <div
        className={styles.leftSection}
      >
        <img onClick={homePage} className={`${styles.headerLogo} logo--light`} src={blackLogo} alt='logo' />
        {/* <img onClick={homePage} src={orangeLogo} alt='dark logo' className="logo--dark" /> */}
        {children}
      </div>
      {!noImage && <div className={styles.rightSection}>
        <img className={styles.loginImage} src={loginImage} alt='login' />
      </div>}

    </>
  );
};

export default AccountContainer;
