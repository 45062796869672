import React, { useEffect, useState } from "react";
import {
  MainSection,
  Container,
  SocialMedia,
} from "web/pages/account/Registration/UserRoles/style";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import {
  getAuthInitialValues,
  getValidationSchema,
} from "web/components/utils/userRolesUtils";
import { IconInputFields } from "web/styles/Global";
import editProfileIcon from "web/assets/images/editProfile.svg";
import EditIcon from "web/assets/svg/EditIcon";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EndIcon from "web/assets/svg/PasswordShowIcon";
import PasswordIconText from "web/assets/svg/PasswordIconText";
import SelectField from "web/components/form-elements/SelectField";
import NumberInput from "web/components/form-elements/NumberInput";
import CreatableSelect from "web/components/form-elements/creatableSelect";
import TextInput from "web/components/form-elements/TextInput";
import CheckboxInput from "web/components/form-elements/CheckboxInput";
import Button from "web/components/Button";
import AlertModal from "web/components/AlertModal";
import Layout from "web/components/Layout";
import Spinner from "web/components/Spinner";
import { signUp, getCities, getStates } from "redux/actions/auth";
import Box from "@material-ui/core/Box";
import insta from "web/assets/images/insta.svg";
import facebook from "web/assets/images/facebook.svg";
import twitter from "web/assets/images/twitter.svg";
import InstaIcon from "web/assets/svg/InstaIcon";
import FacebookIcon from "web/assets/svg/FacebookIcon";
import TwitterIcon from "web/assets/svg/TwitterIcon";
import Avatar from "@material-ui/core/Avatar";
import defaultProfileImage from "web/assets/images/profileEditIcon.png";
import NotWorkingWithAnyAgent from "web/components/NotWorkingWithAnyAgent";
import AccountContainer from "web/components/AccountContainer";
import ProfileIconEdit from "web/components/ProfileIconEdit";
import RenderSnackbar from "web/components/ProfileIconEdit/snackbar";
import SimpleBackdrop from "web/components/ProfileIconEdit/backdrop/backdrop";
import PropertyModal from "web/components/comingSoonModal";

const UserRoles = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userImage, setUserImage] = useState();
  const [pageType, setPageType] = useState("");
  const [city, setCity] = useState([]);
  const [propertyModal, setPropertyModal] = useState(false);
  const [getState, setState] = useState([]);
  const [states, setStates] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showInstaTextBox, setShowInstaTextBox] = useState(true);
  const [showFbTextBox, setShowFbTextBox] = useState(true);
  const [showTwitterTextBox, setShowTwitterTextBox] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  let title = "";
  const [stateVal, setStateVal] = useState();
  const [showCropper, setShowCropper] = React.useState(false);
  const handleCropper = () => setShowCropper((prevValue) => !prevValue);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const toggleCheckBoxModal = () => setCheckBoxChecked(!checkBoxChecked);
  const togglePropertyModal = () => setPropertyModal(!propertyModal);

  const modalOpen = () => {
    togglePropertyModal();
  };

  useEffect(() => {
    const fetchData = async (setFieldValue) => {
      const response = await dispatch(getCities());
      const stateResponse =
        getState &&
        getState?.length >= 1 &&
        (await dispatch(getStates(getState)));
      let state = [];
      getState &&
        getState?.length >= 1 &&
        stateResponse?.data?.forEach((item) => {
          state?.push({ label: item, value: item });
        });
      if (setFieldValue) {
        setFieldValue("state", states);
      }
      setStates(state);

      let city = [];
      response?.data?.forEach((item) => {
        city.push({ label: item, value: item });
      });
      setCity(city);
    };
    const { location } = props;
    const queryParams = new URLSearchParams(location.search);
    setPageType(queryParams.get("type"));
    fetchData();
  }, [getState]);

  const [relationWithOwnerArr, setRelationWithOwnerArr] = useState([
    {
      label: "Relative",
      value: "Relative",
    },
    {
      label: "Friend",
      value: "Friend",
    },
  ]);
  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);

  const submitHandler = async (props) => {
    if (pageType !== "Agent" && props?.notWorkingWithAnyAgent === false) {
      setCheckBoxChecked(true);
    } else {
      if (pageType === "Agent") {
        delete props.relationWithOwner;
        delete props.notWorkingWithAnyAgent;
      } else if (pageType === "Homeowner") {
        delete props.agentLiscenceNo;
        delete props.brokerageName;
        delete props.relationWithOwner;
        // delete props.notWorkingWithAnyAgent;
      } else if (pageType === "Others") {
        delete props.agentLiscenceNo;
        delete props.brokerageName;
        delete props.notWorkingWithAnyAgent;
        props["role"] = "OTHER";
      }
      props["profilePic"] = userImage;

      delete props.confirmPassword;
      //   delete props.notWorkingWithAnyAgent;
      //   delete props.termsConditions;

      if (props.profilePic === "") {
        delete props.profilePic;
      }
      let formData = new FormData();
      const registerData = props;
      for (var prop in registerData) {
        formData.append(prop, registerData[prop]);
      }
      const response = await dispatch(signUp(formData));
      if (!response?.error) {
        setModalContent(
          "User registered successfully, please confirm your email address before login."
        );
        setModalLink("/login");
        toggleModal();
      } else {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
        if (pageType === "Agent") {
          props.relationWithOwner = "";
        } else if (pageType === "Homeowner") {
          props.agentLiscenceNo = "";
          props.brokerageName = "";
          props.relationWithOwner = "";
        } else if (pageType === "Others") {
          props.agentLiscenceNo = "";
          props.brokerageName = "";
        }

        // props.termsConditions = false;
        // props.termsConditions = false;
        props.confirmPassword = props.password;
      }
    }
  };

  const formatZipCode = (zip) => {
    if (zip.length < 6) {
      return zip;
    }
    if (zip.length > 5) {
      zip = zip.replace("-", "");
      zip = zip.substring(0, 5) + "-" + zip.substring(5, zip.length);
      return zip;
    }
  };

  const registrationForm = (type) => {
    console.log(type);
    return (
      <>
        <Container>
          <div className="leftSection">
            {type === "Agent" && (
              <TextInput
                name="agentLiscenceNo"
                placeholder="Agent License Number&#42;"
                showErrorMessages={true}
              />
            )}
            <TextInput
              name="firstName"
              placeholder="First Name&#42;"
              showErrorMessages={true}
            />
            <TextInput
              name="lastName"
              placeholder="Last Name&#42;"
              showErrorMessages={true}
            />
            <NumberInput
              name="phone"
              placeholder="Phone&#42;"
              showErrorMessages={true}
              format="(###) ###-####"
            />
            <TextInput
              name="email"
              placeholder="Email&#42;"
              showErrorMessages={true}
            />
            <IconInputFields>
              <TextInput
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password*"
              />
              {!showPassword ? (
                <EndIcon
                  className="passwordIcon"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <PasswordIconText
                  className="passwordIcon"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </IconInputFields>
            <IconInputFields>
              <TextInput
                name="confirmPassword"
                placeholder="Confirm Password&#42;"
                type={showConfirmPassword ? "text" : "password"}
                showErrorMessages={true}
              />
              {!showConfirmPassword ? (
                <EndIcon
                  className="passwordIcon"
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                />
              ) : (
                <PasswordIconText
                  className="passwordIcon"
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                />
              )}
            </IconInputFields>

            {type === "Others" && (
              <Box>
                <CheckboxInput
                  name="notWorkingWithAnyAgent"
                  label="Not working with any agent"
                  handleSelect={(e) => {}}
                />
                <div className="textSection">
                  <a href="#" onClick={modalOpen}>
                    Learn more
                  </a>
                </div>
              </Box>
            )}
          </div>
          <div className="rightSection">
            {type === "Agent" && (
              <>
                <TextInput
                  name="brokerageName"
                  placeholder="Brokerage Name&#42;"
                  showErrorMessages={true}
                />
              </>
            )}
            <TextInput
              name="addressLine1"
              placeholder={
                type === "Agent"
                  ? "Brokerage Address Line 1*"
                  : "Address Line 1*"
              }
              showErrorMessages={true}
            />

            <TextInput
              name="addressLine2"
              placeholder={
                type === "Agent" ? "Brokerage Address Line 2" : "Address Line 2"
              }
              showErrorMessages={true}
            />

            <SelectField
              name="city"
              data={city}
              placeholder={type === "Agent" ? "Brokerage City*" : "City*"}
              onChangeHandler={(data) => {
                setStateVal(data.value);
              }}
            />

            <SelectField
              name="state"
              data={states}
              placeholder={type === "Agent" ? "Brokerage State*" : "State*"}
              onChangeHandler={(data) => {
                setStateVal(data.value);
              }}
            />

            <NumberInput
              name="zipCode"
              placeholder={
                type === "Agent" ? "Brokerage Zip Code*" : "Zip Code*"
              }
              format={formatZipCode}
              maxLength="10"
            />

            {type === "Others" && (
              <CreatableSelect
                id="custom-select"
                data={relationWithOwnerArr}
                name="relationWithOwner"
                placeholder="Relationship with owner&#42;"
                onChange={(value) => {
                  let isUniqueFile = relationWithOwnerArr.filter((item) => {
                    return item.value === value.value;
                  });
                  if (!isUniqueFile.length) {
                    isUniqueFile = value;
                  } else {
                    return;
                    isUniqueFile = [];
                  }
                  let ownerArray = [...relationWithOwnerArr, isUniqueFile];
                  setRelationWithOwnerArr(ownerArray);
                }}
              />
            )}

            {type === "Agent" && (
              <Box className="notWorkingCheckbox">
                <CheckboxInput
                  name="termsConditions"
                  label="Terms and conditions"
                  handleSelect={(e) => {}}
                />
                <div className="textSection">
                  <a href="#" onClick={modalOpen}>
                    Learn more
                  </a>
                </div>
              </Box>
            )}

            {type === "Homeowner" && (
              <Box className="notWorkingCheckbox">
                <CheckboxInput
                  name="notWorkingWithAnyAgent"
                  label="Not working with any agent"
                  handleSelect={(e) => {}}
                />
                <div className="textSection">
                  <a href="#" onClick={modalOpen}>
                    Learn more
                  </a>
                </div>
              </Box>
            )}
          </div>
        </Container>
        <SocialMedia>
          {type === "Agent" && (
            <Box className="socialMediaProfiles">
              <InstaIcon
                title="Enter Your Instagram Profile Link"
                onClick={() => {
                  setShowInstaTextBox(!showInstaTextBox);
                }}
              />

              <FacebookIcon
                title="Enter Your Facebook Profile Link"
                onClick={() => {
                  setShowFbTextBox(!showFbTextBox);
                }}
              />

              <TwitterIcon
                title="Enter Your Twitter Profile Link"
                onClick={() => {
                  setShowTwitterTextBox(!showTwitterTextBox);
                }}
              />
            </Box>
          )}
          <div className="social_media">
            {showInstaTextBox ? (
              ""
            ) : (
              <TextInput
                name="igLink"
                placeholder="Enter Your Instagram Profile Link"
              />
            )}
            {showFbTextBox ? (
              ""
            ) : (
              <TextInput
                name="fbLink"
                placeholder="Enter Your Facebook Profile Link"
              />
            )}
            {showTwitterTextBox ? (
              ""
            ) : (
              <TextInput
                name="twitterLink"
                // type="url"
                placeholder="Enter Your Twitter Profile Link"
              />
            )}
          </div>
        </SocialMedia>
      </>
    );
  };
  return (
    <>
      {loaderVisible && <Spinner />}
      <Layout transparentHeader={false}>
        <AccountContainer className="12layout" noImage={true}>
          <MainSection>
            <Grid className="sectionM" container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className="registerSection">
                  <Typography className="agent_text">
                    {pageType === "Others"
                      ? "Others Registration Form"
                      : pageType + " " + "Registration Form"}
                  </Typography>
                  <Typography className="agent_textB">Hello!</Typography>
                  <Typography className="agent_textB">
                    Register To Get Started
                  </Typography>
                  <Box className="FormSection">
                    {pageType && states && (
                      <Formik
                        initialValues={getAuthInitialValues(pageType, states)}
                        validationSchema={getValidationSchema(pageType)}
                        onSubmit={(values) => {
                          submitHandler(values);
                        }}
                      >
                        {(props) => {
                          const { isValid, dirty, setFieldValue, values } =
                            props;
                          setState(values?.city);
                          return (
                            <>
                              <Form>
                                <Box className="avatarSection">
                                  <Avatar
                                    className="avatar"
                                    alt="Remy Sharp"
                                    src={
                                      userImage
                                        ? URL.createObjectURL(userImage)
                                        : defaultProfileImage
                                    }
                                  />
                                  <EditIcon
                                    onClick={() => {
                                      handleCropper();
                                    }}
                                    className="editProfileIcon"
                                  />
                                </Box>
                                {pageType === "Agent" &&
                                  registrationForm("Agent")}
                                {pageType === "Homeowner" &&
                                  registrationForm("Homeowner")}
                                {pageType === "Others" &&
                                  registrationForm("Others")}

                                <Button
                                  type="app-orange-button submitBtn"
                                  disabled={!(isValid && dirty)}
                                >
                                  Submit
                                </Button>
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    )}
                    {showCropper && (
                      <RenderSnackbar>
                        <SimpleBackdrop>
                          <ProfileIconEdit
                            callbackFun={(val) => {
                              // setFieldValue('profilePic', val);
                              setUserImage(val);
                            }}
                            defaultImage={
                              userImage && URL.createObjectURL(userImage)
                            }
                            handleCropper={handleCropper}
                          />
                        </SimpleBackdrop>
                      </RenderSnackbar>
                    )}
                    <Typography className="account_txt">
                      Already have an account?
                      <span
                        className="login_txt"
                        onClick={() => history.push("/login")}
                      >
                        &nbsp; Log In
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <NotWorkingWithAnyAgent
              isOpen={checkBoxChecked}
              toggle={toggleCheckBoxModal}
              title=""
            />
            <PropertyModal
              isOpen={propertyModal}
              toggle={togglePropertyModal}
              title=""
            />
            <AlertModal
              isOpen={modal}
              toggle={toggleModal}
              title={title}
              content={modalContent}
              link={modalLink}
            />
          </MainSection>
        </AccountContainer>
      </Layout>
    </>
  );
};
export default UserRoles;
