import React from 'react';
import Button from 'web/components/Button';
import CloseIcon from 'web/assets/images/CloseIcon.png';
import { Modal,  ModalBody } from 'reactstrap';
import ComingSoon from 'web/assets/svg/CommingSoon';
import CrossIcon from "web/assets/svg/CrossIcon"


const PropertyModal = ({ isOpen, toggle, title, addressLineData, data }) => {
  const redirectLink = (link) => {
    toggle(!isOpen);
  };
const crossIconStyle={
  marginTop:"20px",
  marginRight:"20px",
  cursor:"pointer",
}
  return (
      <div className='property-modal'>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          backdrop={'static'}
          centered={true}
          className='propertyModal'>
          <div className='modal-content'>
            <div className='header'>
              {/* <Button
                handleClick={() => {
                  redirectLink('');
                }}> */}
                  <CrossIcon onClick={() => {
								redirectLink("");
							}} style={crossIconStyle} />
                {/* <img src={CloseIcon} className='closeIcon' alt='close-icon' /> */}
              {/* </Button> */}
            </div>
            <ModalBody>
              <div>
                <h3 className='modal-title'>{title}</h3>
              </div>

              <div
                style={{ paddingTop: 70, paddingBottom: 70 }}
                className='comingSoon'>
                <ComingSoon />
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
  );
};

export default PropertyModal;
