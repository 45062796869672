import * as React from "react";
import loader from "../../assets/images/loader.gif";
import styled from "styled-components";
const Spinner = () => (
	<div
		style={{
			display: "flex",
			position: "fixed",
			top: 0,
			bottom: 0,
			right: 0,
			left: 0,
			justifyContent: "center",
			alignItems: "center",
			zIndex: 99999,
		}}
	>
		<img src={loader} alt="loader" style={{ width: "80px", zIndex: 99999 }} />
	</div>
);

export default Spinner;

// const LdsGrid = styled.div`
//   display: inline-block;
//   position: relative;
//   width: 80px;
//   height: 80px;
//   > div {
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #e45829 0% 0% no-repeat padding-box;
//     animation: lds-grid 1.2s linear infinite;

//     :nth-child(1) {
//       top: 8px;
//       left: 8px;
//       animation-delay: 0s;
//     }
//     :nth-child(2) {
//       top: 8px;
//       left: 32px;
//       animation-delay: -0.4s;
//     }
//     :nth-child(3) {
//       top: 8px;
//       left: 56px;
//       animation-delay: -0.8s;
//     }
//     :nth-child(4) {
//       top: 32px;
//       left: 8px;
//       animation-delay: -0.4s;
//     }
//     :nth-child(5) {
//       top: 32px;
//       left: 32px;
//       animation-delay: -0.8s;
//     }
//     :nth-child(6) {
//       top: 32px;
//       left: 56px;
//       animation-delay: -1.2s;
//     }
//     :nth-child(7) {
//       top: 56px;
//       left: 8px;
//       animation-delay: -0.8s;
//     }
//     :nth-child(8) {
//       top: 56px;
//       left: 32px;
//       animation-delay: -1.2s;
//     }
//     :nth-child(9) {
//       top: 56px;
//       left: 56px;
//       animation-delay: -1.6s;
//     }
//     @keyframes lds-grid {
//       0%,
//       100% {
//         opacity: 1;
//       }
//       50% {
//         opacity: 0.5;
//       }
//     }
//   }
// `;
