import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: (props) => ({
    width: '100%',
    background: '#ffffff',
    borderRadius: 10,
    border: 'none',
    fontFamily: 'Poppins',
    '& label': {
      fontFamily: 'Poppins',
      color: '#eee',
      fontWeight: 600,
      fontSize: 22,
    },
    '& label.Mui-focused': {
      color: '#7B7575',
    },
    '& label.Mui-error': {
      color: 'red',
    },
    '& .MuiInputLabel-outlined': {
      transform: props.transform,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 20,
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Poppins',
      '& input': {
        color: '#4F5270',
        fontSize: 17,
      },
      '& input::placeholder': {
        opacity: 1,
        color: '#a5a5ad',
        fontSize: 16,
      },
      '& fieldset': {
        borderColor: '#D2D9E8',
        borderRadius: 8,
      },
      '&:hover fieldset': {
        borderColor: '#D2D9E8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D2D9E8',
      },
    },
  }),

  textField: {
    width: '25ch',
  },
});

export default useStyles;
