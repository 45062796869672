import styled from "styled-components";

const IconInputField = styled.div`
	position: relative;
	.startIcon {
		position: absolute;
		top: 14px;
		left: 14px;
	}
	.emailIcon {
		position: absolute;
		top: 17px;
		left: 14px;
	}
	.passwordIcon {
		position: absolute;
		top: 17px;
		right: 14px;
		cursor: pointer;
	}
	input {
		padding-left: 40px !important;
	}
	> div > input {
		font-size: 14px;
	}
`;
const IconInputFields = styled.div`
	position: relative;
	.startIcon {
		position: absolute;
		top: 14px;
		left: 14px;
	}
	.emailIcon {
		position: absolute;
		top: 20px;
		left: 14px;
	}
	.passwordIcon {
		position: absolute;
		top: 17px;
		right: 14px;
		cursor: pointer;
	}
	input {
		padding-left: 14px;
	}
`;

export { IconInputField, IconInputFields };
