import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import blackLogo from "web/assets/images/blackLogo.png";
import { useSelector } from "react-redux";
import { CustomNavbar } from "web/components/PublicNavbar/styles";
// test
const Navbar = () => {
  const history = useHistory();
  const { userManagement } = useSelector((state) => state);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  let token = localStorage.getItem("userInfo");
  const closeMobileMenu = () => {
    if (token === null) {
      history.push("/");
    } else {
      history.push("/dashboard");
    }
    setClick(false);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const profilePage = () => {
    history.push("/profile");
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);
  return (
    // <IconContext.Provider value={{ color: '#fff' }}>
    <CustomNavbar>
      <nav className="navbar">
        <div className="navbar-container">
          <img src={blackLogo} alt="logo" onClick={closeMobileMenu} />
          <ul className="right-menu">
            {history?.location?.pathname === "/contact" ||
            history?.location?.pathname === "/profile" ? (
              ""
            ) : (
              <li
                onClick={() => history.push("/contact")}
                className="contactUs"
              >
                Contact Us
              </li>
            )}
            {history?.location?.pathname === "/profile" ? (
              ""
            ) : (
              <li onClick={profilePage} className="nav-item">
                <Avatar
                  alt="Remy Sharp"
                  src={userManagement?.profileData?.profilePic}
                />
              </li>
            )}
          </ul>
        </div>
      </nav>
    </CustomNavbar>
    // </IconContext.Provider>
  );
};

export default Navbar;
