import * as React from 'react';

function Icon(props) {
  return (
    <svg width={15.5} height={18} viewBox='0 0 15.5 18' {...props}>
      <path
        d='M14.3 12.643h1.2m-.6-2.429V9a1.222 1.222 0 00-.351-.859 1.193 1.193 0 00-.849-.356h-12a1.193 1.193 0 00-.849.356A1.222 1.222 0 00.5 9v7.286a1.222 1.222 0 00.351.859 1.193 1.193 0 00.849.355h12a1.193 1.193 0 00.849-.356 1.222 1.222 0 00.351-.859v-6.071zM4.1 7.786V4.143a3.665 3.665 0 011.054-2.576 3.57 3.57 0 015.091 0A3.665 3.665 0 0111.3 4.143v3.643z'
        fill='none'
        stroke='#4f5270'
      />
    </svg>
  );
}

export default Icon;
