import React from 'react';
import Select from 'react-select';
import { Field } from 'formik';
import 'web/components/form-elements/form-elements.scss';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    borderRadius: '4px',
    color: '#24272b',
    border: '1px solid #e0e0e0',
    '&:hover': { borderColor: '#E4582A' },
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#E4582A",// Custom colour
    border: "1px solid #E4582A",
    borderRadius: "25px",
    padding: "0",
    marginRight: "11px",
    marginBottom: "0px",
    '&:hover': { color: '#E4582A' },

  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#292a2a',
    fontSize: '1rem',
    backgroundColor: state.isSelected ? '#E4582A' : 'initial',
    zIndex: 2,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9c9c9c',
    fontSize: '1rem',

  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '1rem',
    top: 'unset',
    position: 'unset',
    transform: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '50px',
    padding: "0 10px",
  }),
  Input: (provided) => ({
    ...provided,
    height: '28px',
  }),
};
const SelectField = ({
  name,
  placeholder,
  disabled,
  data = () => { },
  id,
  inputRef,
  onBlur = () => { },
  onChangeHandler = () => { },
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <div className='app-input-text'>
            <Select
              id={id}
              className={`custom-select-input ${meta.touched && meta.error ? 'error' : ''
                }`}
              classNamePrefix='custom-select'
              name={name}
              ref={inputRef}
              value={data?.filter((option, index) =>
                option?.value === field?.value
              )}
              options={data}
              isDisabled={disabled}
              placeholder={placeholder}
              styles={customStyles}
              onChange={(data) => {
                onChangeHandler(data);
                form.setFieldValue(name, data.value);
                onBlur(data.value)
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#E4582A',
                  primary: '#E4582A',
                },
              })}
            />
            {meta.touched && meta.error && (
              <p className='error-message'>{meta.error}</p>
            )}
          </div>
        );
      }}
    </Field>
  );
};
export default SelectField;
