import axioPath from 'api/axioPath';
import {
  SIGN_IN,
  SIGN_UP,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_EMAIL,
  GET_CITIES,
  GET_STATES,
  AGENT_VERIFICATION,
  CONTACT_US,
} from 'redux/actions/action_types';

export const auth = (data) => (dispatch) => {
  return axioPath
    .post('/auth/login', data)
    .then((response) => {
      dispatch({ type: SIGN_IN, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};
export const signUp = (data) => (dispatch) => {
  return axioPath
    .post('/auth/signUp', data)
    .then((response) => {
      dispatch({ type: SIGN_UP, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};

export const forgotPassword = (data, token) => (dispatch) => {
  return axioPath
    .post('/auth/forgetPassword', data, {
      headers: {
        token: `${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: FORGOT_PASSWORD, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((e) => {
      return { error: true, data: e.response };
    });
};
export const changePassword = (data) => (dispatch) => {
  return axioPath
    .post('/auth/changePassword', data)
    .then((response) => {
      dispatch({ type: CHANGE_PASSWORD, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((e) => {
      return { error: true, data: e.response };
    });
};
export const confirmEmail = (data) => (dispatch) => {
  return axioPath
    .post('/auth/confirmEmail', data)
    .then((response) => {
      dispatch({ type: CONFIRM_EMAIL, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};

export const getCities = () => (dispatch) => {
  return axioPath
    .get('/address/cities', { hideLoader: true })
    .then((response) => {
      dispatch({ type: GET_CITIES, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};
export const getStates = (data) => (dispatch) => {
  return axioPath
    .get('/address/states?city=' + `${data}`, { hideLoader: true })
    .then((response) => {
      dispatch({ type: GET_STATES, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};

export const agentVerification = (data) => (dispatch) => {
  return axioPath
    .get('/address/states')
    .then((response) => {
      dispatch({ type: AGENT_VERIFICATION, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};

export const contactUs = (data) => (dispatch) => {
  return axioPath
    .post('/contactus', data)
    .then((response) => {
      dispatch({ type: CONTACT_US, payload: response.data });
      return { error: false, data: response.data };
    })
    .catch((ex) => {
      if (typeof ex == 'string') {
        return { ex: { message: ex } };
      }
      return { error: true, data: ex };
    });
};
