export function getAuthInitialValues(userRole, response) {
  return {
    files: "",
    askingPrice: response?.askingPrice?.toString() || "",
    afterRepairValue: response?.afterRepairValue?.toString() || "",
    propertyStatus: response?.propertyStatus || "",
    sellTime: response?.sellTime || "",
    bedrooms: response?.bedrooms?.toString() || "",
    bathrooms: response?.bathrooms?.toString() || "",
    yearBuilt: response?.yearBuilt?.toString() || "",
    floors: response?.floors?.toString() || "",
    squareFootage: response?.squareFootage?.toString() || "",
    lotSize: response?.lotSize?.toString() || "",
    description: response?.description || "",
    type: response?.type || "",
    occupancyStatus: response?.occupancyStatus || "",
    poolInfo: response?.poolInfo || "",
    carParking: response?.carParking || "",
    fairMarketPrice: response?.fairMarketPrice || "",
    suggestedRehab: response?.suggestedRehab || "",
    comments: response?.comments || "",
    fixAndFlip: response?.fixAndFlip || "",
    cashOnlyRequest: response?.cashOnlyRequest || false,
    offerDeadLine: response?.offerDeadLine || "",
    similarLinks: response?.similarLinks || "",
    firePlaceValue: response?.firePlaceValue?.toString() || "",
  };
}
