import * as Yup from "yup";

export function getAuthInitialValues(formattedData) {
	return {
		addressLine1: formattedData?.addressLine1 || "",
		addressLine2: formattedData?.addressLine2 || "",
		city: formattedData?.city || "",
		state: formattedData?.state || "",
		zipCode: formattedData?.zipcode || "",
		correctAddress: false,
	};
}

const VALIDATION_SCHEMA = Yup.object().shape({
	addressLine1: Yup.string().trim().required("Address Line 1 is required."),
	addressLine2: Yup.string().trim().required("Address Line 2 is required."),
	city: Yup.string().trim().required("City is required."),
	state: Yup.string().trim().required("State is required."),
	zipCode: Yup.string().min(2, "Too Short!").required("Zip Code is required."),
	correctAddress: Yup.boolean().oneOf([true], "required."),
});

export { VALIDATION_SCHEMA };
