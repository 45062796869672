import * as React from "react"

const Icon=(props)=> {
  return (
    <svg
      data-name="Icon awesome-minus-circle"
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      {...props}
    >
      <path
        data-name="Icon awesome-minus-circle"
        d="M14.001.001a14 14 0 1014 14 14 14 0 00-14-14zM6.549 16.259a.679.679 0 01-.677-.677V12.42a.679.679 0 01.677-.677h14.9a.679.679 0 01.677.677v3.161a.679.679 0 01-.677.677z"
        fill="#fff"
      />
    </svg>
  )
}

export default Icon
