import styled from 'styled-components';

export const CustomNavbar = styled.div`
  background-color: ${({ theme }) => theme.colors.body};
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0 !important;
  position: fixed !important;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  .navbar {
    width: 100%;
    padding: 0;
  }
  .navbar .button {
    width: 200px;
    height: 50px;
    font-size: 16px;
    border-radius: 6px;
    margin-top: 0px;
    font-family: 'poppinsMedium';
    display: inline-block;
  }
  .navbar-container {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin-top: 20px;
    padding: 0 28px;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
  .right-menu {
    margin-bottom: 0;
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: auto;
  }
  .right-menu .nav-links {
    margin: 0px 24px;
    padding: 0px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-logo {
    cursor: pointer;
    margin-top: 0px;
    > img {
      width: 170px;
    }
  }
  .navbar-icon {
    margin-right: 0.5rem;
  }
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
    margin-bottom: 0;
    margin: 0 auto;
    height: 67px;
  }
  .nav-item {
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
  }
  .nav-item button {
    color: #fff;
    letter-spacing: 0.58px;
    font-size: 14px;
    font-family: 'Poppins';
  }
  .nav-item .userPic {
    width: 22px;
  }
  .contactUs {
    margin: 0 60px;
    cursor: pointer;
  }
  .nav-links {
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.colors.headerText} !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'poppinsMedium';
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    font-size: 18px;
  }
  .fa-bars {
    color: #fff;
  }
  .menu-icon {
    display: none;
  }
  @media screen and (max-width: 1250px) {
    .right-menu {
      position: absolute;
      right: 46px;
    }
    .right-menu li a {
      display: flex;
    }
    .right-menu li a img {
      width: 20px;
    }
    .NavbarItems {
      position: relative;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 64px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding: 0;
    }
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
    .nav-item:hover {
      border-color: transparent;
    }
    .nav-item {
      width: 100%;
    }
    .navbar-logo {
      /* position: absolute;
      top: 0;
      left: 0;
      transform: translate(0%, 15%); */
    }
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 20px;
      cursor: pointer;
      line-height: 27px;
    }
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }
`;
