import React, { useState, useEffect, useCallback } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextInput from "web/components/form-elements/TextInput";
import CheckboxInput from "web/components/form-elements/CheckboxInput";
import Button from "web/components/Button";
import EmailIcon from "web/assets/svg/EmailIcon";
import LockIcon from "web/assets/svg/LockIcon";
import EndIcon from "web/assets/svg/PasswordShowIcon";
import PasswordIconText from "web/assets/svg/PasswordIconText";
import { IconInputField } from "web/styles/Global";
import Spinner from "web/components/Spinner";
import AlertModal from "web/components/AlertModal";
import AccountContainer from "web/components/AccountContainer";
import Layout from "web/components/Layout";
import Facebook from "web/components/Facebook";
import Google from "web/components/Google";
import { auth } from "redux/actions/auth/index";
import {
  getAuthInitialValues,
  VALIDATION_SCHEMA,
} from "web/components/utils/authUtils";
import {
  LoginPage,
  ForgotPassword,
  Register,
} from "web/pages/account/Auth/styles.js";

const Auth = () => {
  const [showPassword, setShowPassword] = useState(false);
  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);
  const [rememberMeObj, setRememberMeObj] = useState({
    email: "",
    password: "",
    isChecked: false,
  });

  let history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  let title = "";

  const fetchMyAPI = useCallback(async () => {
    if (localStorage.getItem("checkbox")) {
      setRememberMeObj({
        ...rememberMeObj,
        email: localStorage.getItem("email"),
        password: localStorage.getItem("password"),
        isChecked: localStorage.getItem("checkbox"),
      });
    }
  });

  useEffect(() => {
    if (localStorage.getItem("checkbox")) {
      setRememberMeObj({
        ...rememberMeObj,
        email: localStorage.getItem("email"),
        password: localStorage.getItem("password"),
        isChecked: localStorage.getItem("checkbox"),
      });
    }
    // console.log("here", localStorage.getItem("checkbox"));
  }, []);

  const signInHandler = async (props) => {
    localStorage.clear();
    let data = {
      email: props.email,
      password: props.password,
    };
    const response = await dispatch(auth(data));
    if (!response.error) {
      if (props.rememberMe) {
        localStorage.email = props.email;
        localStorage.password = props.password;
        localStorage.checkbox = props.rememberMe;
      }
      localStorage.setItem("userInfo", response?.data?.token);
      history.push("/dashboard");
    } else {
      if (response?.data?.response?.status === 404) {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
      } else {
        setModalContent(response?.data?.response?.data?.message);
        setModalLink("");
        toggleModal();
      }
    }
  };

  const formFields = () => {
    return (
      <LoginPage>
        <Formik
          initialValues={getAuthInitialValues(rememberMeObj)}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values) => {
            signInHandler(values);
          }}
        >
          {(props) => {
            const { isValid, dirty } = props;
            return (
              <>
                <Form>
                  <div className="innerSection">
                    <div className="authPage">
                      <h3>Log In</h3>
                      <IconInputField>
                        <TextInput
                          name="email"
                          placeholder="Email"
                          showErrorMessages={true}
                        />
                        <EmailIcon className="emailIcon" />
                      </IconInputField>
                      <IconInputField>
                        <TextInput
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                        />
                        <LockIcon className="startIcon" />
                        {!showPassword ? (
                          <EndIcon
                            className="passwordIcon"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          />
                        ) : (
                          <PasswordIconText
                            className="passwordIcon"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          />
                        )}
                      </IconInputField>
                      <div className="rememberSection">
                        <CheckboxInput
                          name="rememberMe"
                          label="Remember me"
                          handleSelect={(e) => {}}
                        />
                        <ForgotPassword
                          onClick={() => history.push("/forgot-password")}
                        >
                          Forgot Password?
                        </ForgotPassword>
                      </div>
                      <div className="footer">
                        {rememberMeObj.isChecked ? (
                          <Button
                            type="app-orange-button submitBtn"
                            disabled={!isValid}
                          >
                            Log In
                          </Button>
                        ) : (
                          <Button
                            type="app-orange-button submitBtn"
                            disabled={!(isValid && dirty)}
                          >
                            Log In
                          </Button>
                        )}
                        <div className="fieldGroup">
                          <div className="field">
                            <Facebook />
                          </div>
                          <div className="field googleButton">
                            <Google />
                          </div>
                        </div>

                        <div className="registerBox">
                          <span className="account_span">
                            Don’t have an account?
                          </span>
                          <Register
                            onClick={() => history.push("/registration")}
                          >
                            Register
                          </Register>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>

        <AlertModal
          isOpen={modal}
          toggle={toggleModal}
          title={title}
          content={modalContent}
          link={modalLink}
        />
      </LoginPage>
    );
  };
  return (
    <Layout transparentHeader={false}>
      <AccountContainer className="12layout">
        {loaderVisible && <Spinner />}
        {rememberMeObj.email !== "" && formFields("")}
        {rememberMeObj.email === "" && formFields()}
      </AccountContainer>
    </Layout>
  );
};

export default Auth;
