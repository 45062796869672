import React from 'react';
import { Field } from 'formik';
import 'web/components/form-elements/form-elements.scss';

const CheckboxInput = ({ name, handleSelect, label,disabled }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div className='checkbox-field'>
        <label className='checkbox-container'>
          <input
            id={name}
            name={name}
            type='checkbox'
            checked={form.values[name]}
            onClick={() => handleSelect(form.values[name])}
            {...field}
            disabled={disabled}
          />
          <span className='checkMark'>{form.values[name]}</span>
          {label}
        </label>
      </div>
    )}
  </Field>
);

export default CheckboxInput;
