import * as React from "react";

const SettingButton = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={73} height={73} {...props}>
			<defs>
				<filter
					id="prefix__a"
					x={0}
					y={0}
					width={73}
					height={73}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="blur" />
					<feFlood floodColor="#d1d1d1" floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="Component 71 \u2013 2">
				<g filter="url(#prefix__a)" data-name="Component 59 \u2013 2">
					<circle
						data-name="Ellipse 134"
						cx={27.5}
						cy={27.5}
						r={27.5}
						transform="translate(9 6)"
						fill="#e25728"
					/>
				</g>
				<path
					data-name="Icon ionic-ios-settings"
					d="M46.268 33.498a3.011 3.011 0 011.933-2.81 11.935 11.935 0 00-1.444-3.479 3.051 3.051 0 01-1.225.262 3 3 0 01-2.748-4.229 11.9 11.9 0 00-3.475-1.444 3.008 3.008 0 01-5.618 0 11.935 11.935 0 00-3.479 1.444 3 3 0 01-2.748 4.229 2.952 2.952 0 01-1.225-.262 12.2 12.2 0 00-1.438 3.489 3.01 3.01 0 01.006 5.618 11.935 11.935 0 001.444 3.476 3.005 3.005 0 013.967 3.966 12 12 0 003.483 1.44 3 3 0 015.6 0 11.935 11.935 0 003.479-1.444 3.008 3.008 0 013.967-3.967 12 12 0 001.444-3.479 3.025 3.025 0 01-1.923-2.81zm-9.713 4.869a4.875 4.875 0 114.875-4.875 4.873 4.873 0 01-4.875 4.874z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default SettingButton;
