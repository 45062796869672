import {
  GET_PROPERTY_DATA,
  ADD_PROPERTY_DATA,
  userInitiate,
  PROPERTY_AUTOSAVE,
  AUTOSAVE_GET_PROPERTY_DATA,
  DELETE_AUTOSAVE,
  GET_PROPERTY_DATA_BY_ID,
  GET_REDFIN_DATA,
  CORELOGIC_PROPERTY_DATA,
} from 'redux/actions/action_types';

const INITIAL_STATE = {
  user: null,
  addPropertyData: null,
  getPropertyData: null,
  propertyAutoSave: null,
  getPropertyAutoSave: null,
  deletePropertyAutoSave: null,
  getPropertyById: null,
  redfinData: null,
  getCoreLoginData: null,
};

const property = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case userInitiate:
      return INITIAL_STATE;
    case ADD_PROPERTY_DATA:
      return { ...state, addPropertyData: payload };
    case GET_PROPERTY_DATA:
      return { ...state, getPropertyData: payload };
    case PROPERTY_AUTOSAVE:
      return { ...state, propertyAutoSave: payload };
    case AUTOSAVE_GET_PROPERTY_DATA:
      return { ...state, getPropertyAutoSave: payload };
    case CORELOGIC_PROPERTY_DATA:
      return { ...state, getCoreLoginData: payload };
    case DELETE_AUTOSAVE:
      return { ...state, deletePropertyAutoSave: payload };
    case GET_PROPERTY_DATA_BY_ID:
      return { ...state, getPropertyById: payload };
    case GET_REDFIN_DATA:
      return {
        ...state,
        redfinData: {
          ...state?.redfinData,
          [payload?.link?.split('/')[
            payload?.link?.split('/').length - 1
          ]]: payload,
        },
      };
    default:
      return state;
  }
};
export default property;
