import * as React from "react"

const Icon=(props)=> {
  return (
    <svg
      data-name="Icon awesome-plus-circle"
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      {...props}
    >
      <path
        data-name="Icon awesome-plus-circle"
        d="M14.001.001a14 14 0 1014 14 14 14 0 00-14-14zm8.129 15.581a.679.679 0 01-.677.677h-5.194v5.194a.679.679 0 01-.677.677H12.42a.679.679 0 01-.677-.677v-5.194H6.549a.679.679 0 01-.677-.677V12.42a.679.679 0 01.677-.677h5.189V6.549a.679.679 0 01.677-.677h3.161a.679.679 0 01.677.677v5.189h5.194a.679.679 0 01.677.677z"
        fill="#fff"
      />
    </svg>
  )
}

export default Icon
