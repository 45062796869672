import React from 'react';
import Button from 'web/components/Button';
import CloseIcon from 'web/assets/images/CloseIcon.png';
import { Modal, ModalBody } from 'reactstrap';
import Decision from 'web/assets/images/agentPopup.svg';

const NotWorkingWithAnyAgent = ({ isOpen, toggle, title, props }) => {
  const redirectLink = (link) => {
    toggle(!isOpen);
  };
  return (
    <>
      <div className='property-modal'>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          backdrop={'static'}
          centered={true}
          className='propertyModal'>
          <div className='modal-content'>
            <div className='header'>
              <Button
                handleClick={() => {
                  redirectLink('');
                }}>
                <img
                  style={{ width: 21, marginRight: 5, marginTop: 5 }}
                  src={CloseIcon}
                  alt='close'
                />
              </Button>
            </div>
            <ModalBody>
              <div>
                <h3 className='modal-title'>{title}</h3>
              </div>
              <div
                style={{ paddingTop: 15, paddingBottom: 70 }}
                className='comingSoon'>
                <img width='200px' src={Decision} alt='Decision' />

                <h2
                  style={{
                    paddingTop: 90,
                    fontWeight: 600,
                    fontSize: 28,
                    color: ' #4F5270',
                    fontFamily: 'poppins',
                  }}>
                  If you’re already working with an agent, please have your
                  agent register and enter the property.
                </h2>
                <br />
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default NotWorkingWithAnyAgent;
