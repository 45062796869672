import React from 'react';
import Button from '@material-ui/core/Button';
import test from 'web/assets/images/pexels-pixabay-259588.jpg';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { MainSection } from 'web/components/Cards/style';
import { useHistory } from 'react-router-dom';

const Cards = ({ data }) => {
  const history = useHistory();

  const EditProperty = () => {
    history.push({
      pathname: '/show-property',
      state: { id: data?.id },
    });
  };
  // code

  return (
    <MainSection>
      <Card onClick={EditProperty} className='property_cards'>
        <CardActionArea className='cards_inner'>
          <Button
            className={
              data?.reviewStatus === 'UNDER REVIEW'
                ? 'underReview'
                : data?.reviewStatus === 'ACCEPTED'
                ? 'accepted'
                : data?.reviewStatus === 'pass'
                ? 'pass'
                : 'submitted'
            }>
            {data?.reviewStatus.toLowerCase()}
          </Button>
          <img
            className='propertyImg'
            src={data?.images[0]?.link || test}
            alt='cardImage'
          />
          <CardContent className='card_content'>
            <Typography className='card_textA'>
              {data?.addressLine1 + ' ' + data?.addressLine2}
            </Typography>
            {data?.type !== 'VacantLand' ? (
              <Typography className='card_textB'>
                {data?.squareFootage} sqFt &nbsp;
                {data?.bedrooms === 1
                  ? '|' + ' ' + data?.bedrooms + ' ' + 'bed' + ' '
                  : data?.bedrooms > 1
                  ? '|' + ' ' + data?.bedrooms + ' ' + 'beds' + ' '
                  : ''}
                {data?.bathrooms === 1
                  ? '|' + ' ' + data?.bathrooms + ' ' + 'bath'
                  : data?.bathrooms > 1
                  ? '|' + ' ' + data?.bathrooms + ' ' + 'baths'
                  : ''}
              </Typography>
            ) : (
              <Typography className='card_textB'>
                {data?.lotSize} lot size
              </Typography>
            )}
            <Typography className='card_textC'>
              ${' '}
              {data?.askingPrice
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </MainSection>
  );
};
export default Cards;
