import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Layout from "web/components/Layout";
import BackButton from "web/assets/svg/BackButton.js";
import styles from "web/pages/private/EditProperty/form.module.scss";
import { getAuthInitialValues } from "web/components/utils/editPropertyUtils";
import TextArea from "web/components/form-elements/TextArea";
import SelectField from "web/components/form-elements/SelectField";
import NumberInput from "web/components/form-elements/NumberInput";
import Box from "@material-ui/core/Box";
import { getPropertyById } from "redux/actions/property";
import { useHistory } from "react-router-dom";
import {
  Container,
  Mainsection,
  ImageSection,
} from "web/pages/private/EditProperty/style";
import Spinner from "web/components/Spinner";
import CheckboxInput from "web/components/form-elements/CheckboxInput";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Image } from "antd";
import "antd/dist/antd.css";
import { relativeTimeRounding } from "moment";

let daysArr = [
  {
    label: "Within 15 Days",
    value: "fifteenDays",
    id: "0",
  },
  {
    label: "30 Days",
    value: "thirtyDays",
    id: "1",
  },
  {
    label: "45 Days",
    value: "fortyFiveDays",
    id: "2",
  },
  {
    label: "60 Days",
    value: "sixtyDays",
    id: "3",
  },
];

const PropertyDetail = (props) => {
  const [response, setResponse] = useState();
  const { userManagement } = useSelector((state) => state);
  const [userRole, setUserRole] = useState("HOMEOWNER");
  const history = useHistory();
  const dispatch = useDispatch();
  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);
  const { location } = props;

  let token = localStorage.getItem("userInfo");

  useEffect(() => {
    window.scrollTo(0, 0);
    setUserRole(userManagement?.profileData?.role);
    let id = location?.state;
    const fetchData = async () => {
      const response = await dispatch(getPropertyById(token, id?.id));
      if (response.length !== 0) {
        setResponse(response);
      }
    };
    fetchData();
  }, []);

  const backPage = () => {
    history.push("/dashboard");
  };
  return (
    <Mainsection>
      <Layout transparentHeader={false} pageType="Dashboard">
        {loaderVisible && <Spinner />}
        <Container>
          <div className={styles.section}>
            {userRole && response && (
              <Formik
                initialValues={getAuthInitialValues(userRole, response)}
                onSubmit={(values) => {}}
              >
                {(props) => {
                  return (
                    <>
                      <Box className="loginWrapper">
                        <Form>
                          <Box className="backIcon">
                            <BackButton onClick={backPage} />
                          </Box>
                          <ImageSection>
                            <div className="image-section">
                              <img
                                src={response?.images[0]?.link}
                                alt="ImgModal"
                                className="firstImagemain"
                              />
                              <div className="previewWrapper">
                                <Image.PreviewGroup>
                                  {response &&
                                    response?.images.map((item, index) => {
                                      return (
                                        <Image
                                          key={index}
                                          className="previewImages"
                                          src={item?.link}
                                          preview={{
                                            src: item?.link,
                                          }}
                                          alt="ImgModal"
                                          style={
                                            index
                                              ? {
                                                  opacity: 0,
                                                  width: 0,
                                                }
                                              : {}
                                          }
                                        />
                                      );
                                    })}
                                </Image.PreviewGroup>
                                <div className="imageText">
                                  <h3>{response?.images?.length}+</h3>
                                </div>
                              </div>
                            </div>
                          </ImageSection>
                          {/* <Carousel>
                            {response &&
                              response?.images.map((item) => {
                                return (
                                  <div>
                                    {" "}
                                    <img src={item.link} alt="grid" />{" "}
                                  </div>
                                );
                              })}
                          </Carousel> */}
                          {userRole === "HOMEOWNER" && (
                            <>
                              <Box>
                                <h6 className="SubHeaderTwo">Asking Price</h6>
                                <div className="askingPrice">
                                  <NumberInput
                                    disabled
                                    thousandSeparator={true}
                                    name="askingPrice"
                                    placeholder=""
                                  />
                                </div>
                              </Box>

                              <Box>
                                <h6 className="SubHeader">
                                  How Soon Do You Want To Sell The Property?
                                </h6>
                                <SelectField
                                  name="sellTime"
                                  data={daysArr}
                                  placeholder=""
                                  disabled
                                />
                              </Box>
                            </>
                          )}
                          {userRole === "OTHER" && (
                            <Box>
                              <h6 className="propertyDetailsTxt">
                                How soon is the seller looking to sell the
                                property?
                              </h6>
                              <Box>
                                <SelectField
                                  name="sellTime"
                                  data={daysArr}
                                  placeholder="Days&#42;"
                                  disabled
                                />
                              </Box>
                            </Box>
                          )}
                          <Box className="buttonsSectionMain">
                            {userRole !== "HOMEOWNER" && (
                              <Box className="buttonsSection">
                                <h6 className="SubHeaderSecond">
                                  Property Status
                                </h6>
                                <box className="Buttons">
                                  <div className="multiSelectButtons">
                                    {response &&
                                    response.propertyStatus === "offMarket"
                                      ? "Off Market"
                                      : response.propertyStatus === "onMarket"
                                      ? "On Market"
                                      : "Backup Offers"}
                                  </div>
                                </box>
                              </Box>
                            )}

                            <Box className="buttonsSection">
                              <h6 className="SubHeaderSecond">
                                Select Category
                              </h6>
                              <box className="Buttons">
                                <div className="multiSelectButtons">
                                  {response && response.type === "VacantLand"
                                    ? "Vacant Land"
                                    : response.type}
                                </div>
                              </box>
                            </Box>
                            <Box
                              style={{ marginLeft: 10 }}
                              className="buttonsSection"
                            >
                              <h6 className="SubHeaderStatus">
                                Occupancy Status
                              </h6>
                              <box className="Buttons">
                                <div className="multiSelectButtons">
                                  {response &&
                                  response.occupancyStatus === "OwnerOccupied"
                                    ? "Owner Occupied"
                                    : response.occupancyStatus ===
                                      "TenantOccupied"
                                    ? "Tenant Occupied"
                                    : response.occupancyStatus}
                                </div>
                              </box>
                            </Box>
                          </Box>
                          {response.type !== "VacantLand" && (
                            <>
                              <Box className="SubHeaderStatusMain">
                                <Box className="SubHeaderStatusSub">
                                  <h6 className="SubHeaderStatus">Beds</h6>
                                  <div className="bedsBox">
                                    {response?.bedrooms}
                                  </div>
                                </Box>

                                <Box className="SubHeaderStatusSub">
                                  <h6 className="SubHeaderStatus">Baths</h6>
                                  <div className="bedsBox">
                                    {response?.bathrooms}
                                  </div>
                                </Box>
                              </Box>

                              <Box>
                                <h6 className="SubHeaderSecond">Year Built</h6>
                                <div clasName="yearBuilt">
                                  <NumberInput
                                    disabled
                                    id="yearBuilt"
                                    name="yearBuilt"
                                    format="####"
                                    placeholder="YYYY*"
                                  />
                                </div>
                              </Box>
                              <Box className="buttonsSectionMain">
                                <Box className="buttonsSection">
                                  <h6 className="SubHeaderStatus">Pool</h6>
                                  <box className="Buttons">
                                    <div className="multiSelectButtons">
                                      {response.poolInfo === "PBoth"
                                        ? "Both"
                                        : response.poolInfo === "PNone"
                                        ? "NA"
                                        : response.poolInfo}
                                    </div>
                                  </box>
                                </Box>
                                <Box className="buttonsSection">
                                  <h6 className="SubHeaderStatus">Garage</h6>
                                  <box className="Buttons">
                                    <div className="multiSelectButtons">
                                      {response.carParking === "CBoth"
                                        ? "Both"
                                        : response.carParking === "CNone"
                                        ? "NA"
                                        : response.carParking}
                                    </div>
                                  </box>
                                </Box>
                                <Box className="buttonsSection">
                                  <h6 className="SubHeaderStatus">
                                    Air Conditioning
                                  </h6>
                                  <box className="Buttons">
                                    <div className="multiSelectButtons">
                                      {response?.airCondition}
                                    </div>
                                  </box>
                                </Box>
                                <Box className="buttonsSection">
                                  <h6 className="SubHeaderStatus">
                                    Fire Place
                                  </h6>
                                  <box className="Buttons">
                                    <div className="multiSelectButtons">
                                      {response?.firePlace}
                                    </div>
                                  </box>
                                </Box>
                              </Box>
                              {response?.firePlaceValue && (
                                <Box className="SubHeaderStatusSub">
                                  <h6 className="SubHeaderStatus">
                                    Fire Place Value
                                  </h6>
                                  <div className="firePlaceBox">
                                    {response?.firePlaceValue
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </div>
                                </Box>
                              )}
                              <Box className="SubHeaderStatusMain">
                                <Box className="SubHeaderStatusSub">
                                  <h6 className="SubHeaderStatus">
                                    Total Floors
                                  </h6>
                                  <div className="bedsBox">
                                    {response?.floors}
                                  </div>
                                </Box>

                                <Box className="SubHeaderStatusSub">
                                  <h6 className="SubHeaderStatus">
                                    Square Footage
                                  </h6>

                                  <div className="bedsBox">
                                    {response?.squareFootage
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </div>
                                </Box>
                              </Box>
                            </>
                          )}

                          <Box className="SubHeaderMain">
                            <Box className="SubHeaderStatusSub">
                              <h6 className="SubHeaderTwo">Lot Size</h6>
                              <div className="bedsBox">
                                {response?.lotSize
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            </Box>

                            {(response?.afterRepairValue ||
                              response?.afterRepairValue === 0) && (
                              <Box className="SubHeaderStatusSub">
                                <h6 className="SubHeaderSecond">
                                  After Repair Value*
                                </h6>
                                <div className="bedsBox">
                                  {response?.afterRepairValue
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                              </Box>
                            )}
                          </Box>
                          {!response.description.includes(" ") &&
                            response.description && (
                              <Box>
                                <h6 className="SubHeaderStatus">
                                  Enter Description
                                </h6>
                                <TextArea disabled name="description" />
                              </Box>
                            )}
                          {userRole !== "HOMEOWNER" && (
                            <>
                              <Box className="SubHeaderMain">
                                {(response?.askingPrice ||
                                  response?.askingPrice === 0) && (
                                  <Box className="SubHeaderStatusSub">
                                    <h6 className="SubHeaderTwo">
                                      Asking Price
                                    </h6>
                                    <div className="bedsBox">
                                      {response?.askingPrice
                                        ?.toString()
                                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                  </Box>
                                )}
                                <Box className="SubHeaderStatusSub">
                                  <h6 className="SubHeaderSecond">
                                    Fair Market Price(As-Is)
                                  </h6>
                                  <div className="bedsBox">
                                    {response?.fairMarketPrice
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </div>
                                </Box>
                              </Box>
                              {userRole !== "HOMEOWNER" &&
                                response?.estimatedRehabCost && (
                                  <Box className="SubHeaderStatusSub">
                                    <h6 className="SubHeaderSecond">
                                      Estimated Rehab Cost
                                    </h6>
                                    <div className="bedsBox">
                                      {response?.estimatedRehabCost
                                        ?.toString()
                                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                  </Box>
                                )}
                              {response.suggestedRehab && (
                                <Box>
                                  <h6 className="SubHeaderSecond">
                                    Suggested Rehab
                                  </h6>
                                  <div className="spacing">
                                    <TextArea
                                      disabled
                                      name="suggestedRehab"
                                      placeholder=""
                                    />
                                  </div>
                                </Box>
                              )}
                            </>
                          )}

                          {(userRole === "AGENT" || userRole === "OTHER") && (
                            <Box>
                              <h6 className="SubHeaderSecond">Strategy*</h6>
                              <div className="bedsBox">
                                {response.fixAndFlip === "lightRehab"
                                  ? "Light Rehab"
                                  : response.fixAndFlip === "remodelAddition"
                                  ? "Remodel + Addition"
                                  : response.fixAndFlip === "remodelADU"
                                  ? "Remodel + ADU"
                                  : response.fixAndFlip === "newConstruction"
                                  ? "New Construction"
                                  : response.fixAndFlip}
                              </div>
                            </Box>
                          )}
                          {userRole !== "HOMEOWNER" && (
                            <Box>
                              {response.similarLinks.length >= 1 && (
                                <h6 className="SubHeaderSecond">
                                  Links To Similar Properties
                                </h6>
                              )}
                              {response.similarLinks.map((item, index) => {
                                return (
                                  <div
                                    className="similar_LinkSection"
                                    key={index}
                                  >
                                    <Box className="input_section_one">
                                      <Box className="similarLinks">
                                        {item.link}
                                      </Box>
                                    </Box>
                                  </div>
                                );
                              })}
                            </Box>
                          )}
                          {userRole !== "HOMEOWNER" && (
                            <>
                              {response.comments && (
                                <Box>
                                  <h6 className="SubHeaderSecond">
                                    Add Comments
                                  </h6>
                                  <div className="spacing">
                                    <TextArea
                                      disabled
                                      name="comments"
                                      placeholder=""
                                    />
                                  </div>
                                </Box>
                              )}

                              {response?.offerDeadLineDate && (
                                <Box>
                                  <h6 className="SubHeaderSecond">
                                    Offer Submission Deadline
                                  </h6>
                                  <box className="ButtonsDateTimeTwo show-property-datetime">
                                    <div className="offerDate">
                                      {response?.offerDeadLineDate}
                                    </div>
                                    <div className="offerTime">
                                      {response?.offerDeadLineTime}
                                    </div>
                                  </box>
                                </Box>
                              )}
                            </>
                          )}
                          {userRole !== "HOMEOWNER" &&
                            response.cashOnlyRequest && (
                              <Box>
                                <div className="showPropertyCheckbox">
                                  <CheckboxInput
                                    name="cashOnlyRequest"
                                    label="Cash Only Offer Request"
                                    handleSelect={(e) => {}}
                                    disabled
                                  />
                                </div>
                              </Box>
                            )}
                        </Form>
                      </Box>
                    </>
                  );
                }}
              </Formik>
            )}
          </div>
        </Container>
      </Layout>
    </Mainsection>
  );
};

export default PropertyDetail;
