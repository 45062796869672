import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import NumberInput from "web/components/form-elements/NumberInput";
import TextArea from "web/components/form-elements/TextArea";
import AlertModal from "web/components/AlertModal";
import DragDrop from "web/components/DragDrop";
import MultiSelectButton from "web/components/MultiSelectButton";
import SelectField from "web/components/form-elements/SelectField";
import { propertyAutoSave } from "redux/actions/property";
import CommonFields from "web/pages/private/PropertyForm/CommonFields";
import { useHistory } from "react-router-dom";
import { MainSection } from "web/pages/private/PropertyForm/style";
import BackIcon from "web/assets/images/backIcon.png";
import Spinner from "web/components/Spinner";
import BackButton from "web/assets/svg/BackButton.js";

const Step1 = ({
  props,
  userRole,
  dataResponse,
  callBackFun,
  addressData,
  vacantSelected,
  inputRef,
  coreLogicResponse,
}) => {
  const { setFieldValue, setErrors } = props;
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  let title = "";
  const history = useHistory();
  const dispatch = useDispatch();

  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);

  let daysArr = [
    {
      label: "Within 15 Days",
      value: "fifteenDays",
      id: "0",
    },
    {
      label: "30 Days",
      value: "thirtyDays",
      id: "1",
    },
    {
      label: "45 Days",
      value: "fortyFiveDays",
      id: "2",
    },
    {
      label: "60 Days",
      value: "sixtyDays",
      id: "3",
    },
  ];

  const PropertyStatus = [
    { buttonTxt: "Off Market", value: "offMarket", context: "propertyStatus" },
    { buttonTxt: "On Market", value: "onMarket", context: "propertyStatus" },
    {
      buttonTxt: "Backup Offers",
      value: "backupOffers",
      context: "propertyStatus",
    },
  ];

  const formAutoSave = async (value) => {
    const allAddressData = addressData.allAddressData;
    const coreLogic = coreLogicResponse?.data;
    let newPorpertyData = { ...value, ...allAddressData, ...coreLogic };
    const response = await dispatch(propertyAutoSave(newPorpertyData));
  };

  // const [isEditing, setEditing] = useState(false);
  // const inputRef = useRef(null);
  // useEffect(() => {
  //   if (isEditing) {
  //     inputRef.current.focus();
  //   }
  // }, [isEditing]);
  const backPage = () => {
    //toggleEditing()

    history.push("/dashboard");
  };
  // const toggleEditing = () => {
  //   setEditing(!isEditing);
  // };
  return (
    <MainSection>
      {loaderVisible && <Spinner />}
      <Grid className="MainGrid" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box className="loginWrapper">
          <Box className="backIcon">
            {/* <img onClick={backPage} src={BackIcon} alt="BackIcon" /> */}
            <BackButton onClick={backPage} />
          </Box>
          <h4 className="MainText">Add Your Property Details</h4>
          <DragDrop props={props} />
          <div>
            {userRole === "HOMEOWNER" && (
              <>
                <Box>
                  <h6 className="SubHeaderTwo">Asking Price*</h6>
                  <NumberInput
                    onChangeHandler={(e) => {}}
                    onBlur={(e) => {
                      callBackFun("askingPrice", e);
                      formAutoSave({ askingPrice: e });
                    }}
                    name="askingPrice"
                    placeholder=""
                    thousandSeparator={true}
                  />
                </Box>
                <Box>
                  <h6 className="SubHeaderSecond">After Repair Value</h6>
                  <NumberInput
                    thousandSeparator={true}
                    onBlur={(e) => {
                      callBackFun("afterRepairValue", e);
                      formAutoSave({ afterRepairValue: e });
                    }}
                    name="afterRepairValue"
                    placeholder=""
                  />
                </Box>
                <Box>
                  <h6 className="SubHeader">
                    How Soon Do You Want To Sell The Property?*
                  </h6>
                  <SelectField
                    onBlur={(e) => {
                      callBackFun("sellTime", e);
                      formAutoSave({ sellTime: e });
                    }}
                    name="sellTime"
                    data={daysArr}
                    placeholder=""
                  />
                </Box>
              </>
            )}
            {userRole === "OTHER" && (
              <Box>
                <h6 className="propertyDetailsTxt">
                  How Soon Do You Want To Sell The Property?*
                </h6>
                <Box>
                  <SelectField
                    onBlur={(e) => {
                      callBackFun("sellTime", e);
                      formAutoSave({ sellTime: e });
                    }}
                    name="sellTime"
                    data={daysArr}
                    placeholder="Days&#42;"
                  />
                </Box>
              </Box>
            )}
            {userRole !== "HOMEOWNER" && (
              <Box className="buttonsSection">
                <h6 className="SubHeaderSecond">Property Status*</h6>
                <box className="Buttons">
                  <MultiSelectButton
                    onBlur={(e) => {
                      callBackFun("propertyStatus", e);
                      formAutoSave({ propertyStatus: e });
                    }}
                    props={props}
                    name="propertyStatus"
                    data={PropertyStatus}
                    labelKey="buttonTxt"
                    valueKey="value"
                    setValue={(val) => {
                      setFieldValue("propertyStatus", val);
                    }}
                    defaultIndex={0}
                  />
                </box>
              </Box>
            )}
            <CommonFields
              dataResponse={dataResponse}
              coreLogicResponse={coreLogicResponse}
              inputRef={inputRef}
              addressData={addressData}
              props={props}
              callBackFun={(name, val) => {
                callBackFun(name, val);
              }}
              vacantSelected={(value) => {
                vacantSelected(value);
              }}
            />
            <Box>
              <h6 className="SubHeaderStatus">Enter Description</h6>
              <TextArea
                placeholder="Type Here"
                rows="5"
                onBlur={(e) => {
                  formAutoSave({ description: e });
                }}
                name="description"
              />
            </Box>
          </div>
        </Box>
      </Grid>
      <AlertModal
        isOpen={modal}
        toggle={toggleModal}
        title={title}
        content={modalContent}
        link={modalLink}
      />
    </MainSection>
  );
};

export default Step1;
