const Icon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 17.186 17.186'
    {...props}>
    <path d='M17.186 1.719L15.468 0 8.593 6.877 1.719 0 0 1.719l6.877 6.874L0 15.468l1.718 1.718 6.874-6.874 6.874 6.874 1.718-1.718-6.874-6.874z' />
  </svg>
);

export default Icon;
