export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CONTACT_US = 'CONTACT_US';
export const SIGN_UP = 'SIGN_UP';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const UPDATE_DATA = 'UPDATE_DATA';
export const FACEBOOK_DATA = 'FACEBOOK_DATA';
export const GOOGLE_DATA = 'GOOGLE_DATA';
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER';
export const userInitiate = 'userInitiate';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const EDIT_PASSWORD = 'EDIT_PASSWORD';
export const PROPERTY_AUTOSAVE = 'PROPERTY_AUTOSAVE';
export const ADD_PROPERTY_DATA = 'ADD_PRPERTY_DATA';
export const GET_PROPERTY_DATA = 'GET_PROPERTY_DATA';
export const AUTOSAVE_GET_PROPERTY_DATA = 'AUTOSAVE_GET_PROPERTY_DATA';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const DELETE_AUTOSAVE = 'DELETE_AUTOSAVE';
export const GET_CITIES = 'GET_CITIES';
export const GET_STATES = 'GET_STATES';
export const GET_PROPERTY_DATA_BY_ID = 'GET_PROPERTY_DATA_BY_ID';
export const AGENT_VERIFICATION = 'AGENT_VERIFICATION';
export const APPEARANCE_MODE = 'APPEARANCE_MODE';
export const GET_REDFIN_DATA = 'GET_REDFIN_DATA';
export const CORELOGIC_PROPERTY_DATA = 'CORELOGIC_PROPERTY_DATA';
export const SET_THEME_DATA = 'SET_THEME_DATA';
