import styled, { css } from 'styled-components';

const ImageSection = styled.div`
  > img {
    cursor: pointer;
    top: 0;
    left: 0;
    transform: translate(0%, 15%);
  }
`;
const MainSection = styled.div`
  justify-items: center;
  padding: 107px 0 210px 0;
  display: grid;
  align-items: center;
  background-color: #ffffff;
  text-align: center;
  .textA {
    color: #4F5270;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Poppins';
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 26px;
  }
  .textB {
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #6F7172;
    padding-bottom: 29px;
    width: 29%;
}
.checkbox-field{
    padding:20px 0 30px !important;
}
label{
    font-size:14px !important;
    color:#6F7172;
}
.sectionFirst{
    position: relative;

   .License{
    position: absolute;
    top: 15px;
    left: 16px;
    z-index: 1;
   }
    .app-input-text input{
        padding:0 143px 0 57px !important;
    }
    TextInput{
        border:1px solid #D2D9E8 !important;
        padding:0 60px;
    }
    Input{
       padding-left:20px !important;
    }
}
.sectionSecond{
    position: relative;
    
    .CourtHouse{
      position: absolute;
      top: 15px;
      left: 16px;
      z-index: 1;
    }
    .custom-select__control{
        padding-left:37px;   
    }
}
.sectionThird{
    button{
        width: 100%;
    padding: 10px 0;
    }
}
  }
`;

const SelectTypeSection = styled.div`
  justify-self: center;
  margin-top: 60px;
  .selectTypeBox {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 29px #73849326;
    border-radius: 22px;
    height: 370px;
    cursor: pointer;
    position: relative;
    width: 370px;
  }
  .image {
    width: 370px;
  }
  .sectionMain {
    width: 100%;
  }
  .sectionType {
    width: 370px;
    cursor: pointer;
    float: left;
    transition: transform 0.2s;
  }
  .sectionType:hover {
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1.1);
  }
  .textBox {
    position: inherit;
  }
  .boxes {
    padding: 40px 10px 20px 10px;
    position: relative;
    background: #6f6c6c;
    box-shadow: 0px 0px 35px #e458293d;
    border-radius: 10px;
    margin-right: 60px;
  }
  .desc_text {
    padding-top: 60px;
    color: #4d4949;
    font-size: 13px;
    font-family: 'Poppins';
    padding-bottom: 27px;
  }
  .type_text {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Poppins';
    color: #656565;
  }
  .registerIcon {
    width: 85px;
    height: 85px;
  }
  .whiteShape {
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
`;

export { MainSection, SelectTypeSection, ImageSection };
