import styled from "styled-components";

export const Container = styled.div`
  padding: 65px 20px;
  background: #393939;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &:before {
    content: "";
    display: inline-block;
    width: 215px;
    height: 210px;
    -webkit-transform: rotate(-31deg);
    -ms-transform: rotate(-31deg);
    -webkit-transform: rotate(-31deg);
    -ms-transform: rotate(-31deg);
    transform: rotate(-31deg);
    opacity: 0.14;
    border-radius: 53px;

    position: absolute;
    left: -109px;
    top: -96px;
    z-index: 0;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 215px;
    height: 210px;
    transform: rotate(-21deg);
    opacity: 0.14;
    border-radius: 56px;

    position: absolute;
    right: -104px;
    bottom: 0;
    z-index: 0;
  }

  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

export const Heading = styled.div`
  padding: 10px 6px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(110vw - 250px);
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  padding: 0 20px;
  @media (max-width: 602px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: #fff;
  grid-gap: 52px;
  grid-template-columns: repeat(auto-fill, minmax(334px, 1fr));
  /* @media (max-width: 1310px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  } */
`;

export const Link = styled.a`
  color: #dedede;
  margin-bottom: 8px;
  font-size: 14px;
  text-decoration: none;
  cursor: auto;

  @media (max-width: 602px) {
    margin: auto;
    margin-bottom: 8px;
  }
  > span:hover {
    color: #ff9c00;
    transition: 200ms ease-in;
    cursor: pointer;
  }
`;

export const Title = styled.p`
  font-size: 30px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: bold;
`;
export const Term = styled.p`
  color: #dedede;
  text-align: center;
  font-size: 13px;
  display: flex;
  grid-gap: 10px;
  padding: 0 10px;
`;

export const SocialIcon = styled.span`
  margin-right: 10px;
`;

export const Divider = styled.div`
  height: 1px;

  margin-top: 30px;
  margin-bottom: 10px;
`;

export const TermWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FooterLogo = styled.div`
  margin: 30px 0 20px 0;
`;
