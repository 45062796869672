import React from "react";
import NumberFormat from "react-number-format";
import { Field } from "formik";

const NumberInput = ({
  name,
  placeholder,
  showErrorMessages = true,
  format,
  maxLength,
  onChangeHandler,
  floating = true,
  displayType,
  thousandSeparator,
  decimalScale,
  isAllowed,
  fixedDecimalScale,
  disabled,
  prefix,
  suffix,
  inputRef,
  onBlur = () => {},
  id,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        // ;
        return (
          <div className="app-input-text">
            <NumberFormat
              onBlurCapture={(values) => {
                onBlur(values.target.value);
              }}
              id={id}
              ref={inputRef}
              prefix={prefix}
              suffix={suffix}
              placeholder={placeholder}
              format={format}
              min="1"
              allowNegative={false}
              fixedDecimalScale={fixedDecimalScale}
              decimalScale={decimalScale}
              displayType={displayType}
              disabled={disabled}
              thousandSeparator={thousandSeparator}
              value={field.value || ""}
              isAllowed={isAllowed}
              maxLength={maxLength}
              onValueChange={(e) => {
                form.validateForm();
                if (onChangeHandler !== undefined) onChangeHandler(e.value);
              }}
              name={field.name}
              className={`${meta.touched && meta.error ? "error" : ""}`}
              {...field}
            />
            {meta.touched && meta.error && showErrorMessages && (
              <p className="error-message">{meta.error}</p>
            )}
          </div>
        );
      }}
    </Field>
  );
};
export default NumberInput;
