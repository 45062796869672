import React, { useContext } from "react";
import { MainSection, ProfileSection } from "web/pages/private/Profile/style";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Layout from "web/components/Layout";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DarkMode from "web/assets/images/DarkMode.png";
import LightMode from "web/assets/images/LightMode.png";
import profileSettings from "web/assets/images/profileEditIcon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "web/components/Spinner";
import { userInitiate } from "redux/actions/action_types";
import "./style.css";
import _ from "lodash";
import ThemeContext from "web/components/App/context.js";
import { getFromLS } from "web/utils/storage";
import SettingButton from "web/assets/svg/SettingButton";

const Appearance = (props) => {
	const themes = getFromLS("all-themes");
	const history = useHistory();
	const dispatch = useDispatch();
	const theme = useContext(ThemeContext);
	const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);

	const profile = () => {
		history.push("/profile");
	};

	const logout = () => {
		window.localStorage.removeItem("userInfo");
		dispatch({ type: userInitiate, payload: null });
		history.push("/login");
	};

	const appearance = () => {
		history.push("/appearance");
	};

	const [value, setValue] = React?.useState("Light");

	const handleChange = (e) => {
		let value = e?.target?.value;
		theme.setSelectedTheme(themes?.data?.[value.toLowerCase()]);
		setValue(value);
	};

	const formFields = (props) => {
		return (
			<Layout transparentHeader={false} pageType="Dashboard">
				<MainSection>
					<ProfileSection>
						<Grid className="sectionMode" container spacing={0}>
							<Box className="settingsIconDropDown">
								<div className="dropdown">
									<SettingButton className="dropbtn" />
									{/* <img
										src={profileSettings}
										alt="profileSettings"
										className="dropbtn"
									/> */}
									<div className="dropdown-content">
										<Typography onClick={profile} className="dropdownContent">
											Profile
										</Typography>
										<Typography
											onClick={appearance}
											className="dropdownContent"
										>
											Appearance
										</Typography>
										<Typography onClick={logout} className="dropdownContent">
											Logout
										</Typography>
									</div>
								</div>
							</Box>
							<RadioGroup
								name="AppearanceMode"
								onChange={handleChange}
								value={value}
							>
								<Grid container className="gridWrapper" spacing={12}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="">
										<FormControlLabel
											value="Light"
											control={<Radio />}
											label={
												<>
													<img src={LightMode} className="modeImg" />{" "}
													<span className="mode--label">Light</span>
												</>
											}
											labelPlacement="top"
											className="FormControlLabel"
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="">
										<FormControlLabel
											value="Dark"
											control={<Radio />}
											label={
												<>
													<img src={DarkMode} className="modeImg" />{" "}
													<span className="mode--label">Dark</span>
												</>
											}
											labelPlacement="top"
											className="FormControlLabel"
										/>
									</Grid>
								</Grid>
							</RadioGroup>
						</Grid>
					</ProfileSection>
				</MainSection>
			</Layout>
		);
	};

	return (
		<>
			<div style={{ width: "100%" }} className="AppearanceSection">
				{loaderVisible && <Spinner />}
				{formFields()}
			</div>
		</>
	);
};
export default Appearance;
