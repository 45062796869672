import * as React from "react";

const InstaIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...props}>
			<defs>
				<filter
					id="Ellipse_132"
					x={0}
					y={0}
					width={68}
					height={68}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="blur" />
					<feFlood floodColor="#d1d1d1" floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="Component 74 \u2013 6">
				<g filter="url(#Ellipse_132)">
					<circle
						data-name="Ellipse 132"
						cx={25}
						cy={25}
						r={25}
						transform="translate(9 6)"
						fill="#e25728"
					/>
				</g>
				<path
					data-name="Icon awesome-instagram"
					d="M34.004 24.218A6.782 6.782 0 1040.786 31a6.772 6.772 0 00-6.782-6.782zm0 11.192a4.409 4.409 0 114.409-4.409 4.417 4.417 0 01-4.409 4.409zm8.642-11.474a1.582 1.582 0 11-1.582-1.582 1.578 1.578 0 011.582 1.582zm4.492 1.606a7.829 7.829 0 00-2.137-5.543 7.88 7.88 0 00-5.543-2.137c-2.184-.124-8.73-.124-10.914 0a7.869 7.869 0 00-5.543 2.136 7.854 7.854 0 00-2.136 5.538c-.124 2.184-.124 8.73 0 10.914a7.829 7.829 0 002.137 5.543 7.89 7.89 0 005.542 2.143c2.184.124 8.73.124 10.914 0a7.829 7.829 0 005.543-2.137 7.88 7.88 0 002.137-5.543c.124-2.184.124-8.724 0-10.908zm-2.822 13.256a4.464 4.464 0 01-2.515 2.515c-1.741.691-5.873.531-7.8.531s-6.062.153-7.8-.531a4.464 4.464 0 01-2.515-2.515c-.691-1.741-.531-5.873-.531-7.8s-.153-6.062.531-7.8a4.464 4.464 0 012.521-2.51c1.741-.691 5.873-.531 7.8-.531s6.062-.153 7.8.531a4.464 4.464 0 012.515 2.515c.691 1.741.531 5.873.531 7.8s.154 6.059-.537 7.795z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default InstaIcon;
