import React from "react";
import { MainSection } from "./style";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import PublicLayout from "web/components/PublicLayout";
import carouselA from "web/assets/images/carousel.png";
import choice from "web/assets/images/choice.svg";
import security from "web/assets/images/05-security.svg";
import rating from "web/assets/images/rating.svg";
import carouselB from "web/assets/images/pexels-pixabay-259588.jpg";
import carouselC from "web/assets/images/pexels-pixabay-280229.jpg";
import { useSelector } from "react-redux";
import "./style.scss";

const LandingPage = () => {
  return (
    <PublicLayout>
      <MainSection>
        <Grid className="sectionM" container spacing={0}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box className="sectionA">
              <Typography className="about_text">About Company</Typography>
              <Typography className="about_textA">
                Ladbrook Capital is a technology-based investment firm,
                providing alternative investment opportunities through diverse
                real estate holdings ranging from single-family residence to
                hospitality.Our broad yet focused strategy gives us the ability
                to look at unique opportunities to maximize returns for our
                investors.
              </Typography>
            </Box>
          </Grid>

          <Grid
            className="carouselSection"
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
          >
            <div
              id="myCarousel"
              className="carousel slide"
              data-ride="carousel"
              data-interval="3000"
            >
              <div>
                <ol className="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    className="second_li"
                    data-target="#myCarousel"
                    data-slide-to="1"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="2"></li>
                </ol>
              </div>

              <div className="carousel-inner">
                <div className="item active">
                  <img
                    className="carouselImg"
                    src={carouselA}
                    alt="Los Angeles"
                  />
                </div>

                <div className="item">
                  <img className="carouselImg" src={carouselB} alt="Chicago" />
                </div>

                <div className="item">
                  <img className="carouselImg" src={carouselC} alt="New York" />
                </div>
              </div>
            </div>
            <Box className="sectionAfterSlider">
              <Box className="sectionUnderSlider">
                <Box className="section1">
                  <div className="imageSection">
                    <img src={choice} alt="choice" />
                  </div>
                  <Typography className="textMax">Maximum Choices</Typography>
                  <Typography className="texta">
                    8 Lac + & counting.
                    <br /> New properties every hour
                    <br /> to help buyers find the right home
                  </Typography>
                </Box>
                <Box className="section1A">
                  <div className="imageSection">
                    <img className="trust_img" src={security} alt="choice" />
                  </div>
                  <Typography className="textMax">Buyers Trust Us </Typography>
                  <Typography className="textp">
                    Over 4 million user visit us Every month for buying and
                    renting
                  </Typography>
                </Box>
                <Box className="section1">
                  <div className="imageSection">
                    <img src={rating} alt="choice" />
                  </div>
                  <Typography className="textMax">Expert Guidance </Typography>
                  <Typography className="textq">
                    experts to help you make smart property decisions
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </MainSection>
    </PublicLayout>
  );
};

export default LandingPage;
