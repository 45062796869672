import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: (props) => ({
		marginTop: 25,
		width: "100%",
		background: "#ffffff",
		borderRadius: "4px 0px 0px 4px",
		border: "none",
		fontFamily: "Poppins",

		"& label": {
			fontFamily: "Poppins",
			color: "#eee",
			fontWeight: 600,
			fontSize: 22,
		},
		"& label.Mui-focused": {
			color: "#7B7575",
		},
		"& label.Mui-error": {
			color: "red",
		},
		// '& .MuiInput-underline': {
		//   fontFamily: 'Poppins',
		//   '& input': {
		//     color: '#eee',
		//     paddingBottom: 15,
		//   },
		//   '&::before': {
		//     borderColor: '#000',
		//   },
		//   '&:hover': {
		//     '&:not(.Mui-disabled)::before': {
		//       borderColor: 'red',
		//     },
		//   },
		//   '&:not(.Mui-disabled)::after': {
		//     borderColor: props.borderColor,
		//   },
		// },
		"& .MuiInputLabel-outlined": {
			transform: props.transform,
		},
		"& .MuiOutlinedInput-adornedStart": {
			paddingLeft: 50,
		},
		"& .MuiOutlinedInput-root": {
			fontFamily: "Poppins",

			"& input": {
				color: "#4F5270",
				fontSize: 17,
			},
			"& input::placeholder": {
				opacity: 1,
				// color: '#4F5270',
				color: "#a5a5ad",
				fontSize: 16,
			},
			"& fieldset": {
				borderColor: "#D2D9E8",
				borderRadius: "4px 0px 0px 4px",
			},
			"&:hover fieldset": {
				borderColor: "#D2D9E8",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#D2D9E8",
			},
		},
	}),

	textField: {
		width: "25ch",
	},
});

export default useStyles;
