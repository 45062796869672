import styled from "styled-components";

const Container = styled.div`
	justify-content: center;
	width: 25%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 50px;
	.SectionOne {
		text-align: CENTER;
		font-family: "Poppins";
		font-size: 26px;
		font-weight: 500;
		padding: 144px 0 54px 0;
		color: #6f7172;
	}
	textarea {
		height: 150px !important;
	}
	.button {
		width: 100%;
		height: 47px;
		margin-top: 35px;
		border-radius: 8px;
	}
`;
const FormSection = styled.div`
	> div > input {
		height: 47px;
		border-radius: 8px;
		font-size: 14px;
	}
	> div > textarea {
		border-radius: 8px;
		font-size: 14px;
	}
	> div > textarea::placeholder {
		font-size: 14px;
		color: #4f5270;
	}
`;

export { Container, FormSection };
