import styled from "styled-components";

const Container = styled.div`
  .ButtonsDateTimeTwo {
    display: flex;
    div {
      border: solid 1px #e0e0e0;
      border-radius: 4px;
      padding: 7px 14px;
      color: #212529;
      width: 100%;
    }
    .offerTime {
      margin-left: 30px;
    }
  }

  .loginWrapper {
    justify-content: center;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 50px;

    .carousel .slider-wrapper {
      max-width: 430px;
    }
    .carousel .slider-wrapper img {
      height: 169px;
      object-fit: fill;
    }
    .carousel .control-prev {
      background: #e45829;
    }
    .carousel .control-next {
      background: #e45829;
    }
    .carousel .carousel-status {
      top: -8px;
      right: 35px;
    }
    .dropOrInputSection {
      padding: 35px 0 20px 0;
      border: 1px dashed #e45829;
      border-radius: 8px;
      text-align-last: center;
    }
    .carousel-indicators li {
      width: 6px;
      height: 6px;
      margin-right: 7px;
      margin-left: 7px;
      background-color: unset;
      background-clip: unset;
      border: 1px solid #fff;
      border-radius: 10px;
    }
    .carousel-indicators .active {
      background-color: #fff;
    }

    .SubHeader {
      font-size: 18px;
      padding: 15px 0 21px;
    }

    .MainText {
      font-size: 30px;
      font-weight: 600;
      text-align: left;
      padding: 25px 0;
    }
    .SubHeaderSecond {
      font-size: 16px;
      text-align: left;
      padding: 20px 0;
    }
    .similarLinkSection {
      margin-top: 18px;
    }
    .showPropertyCheckbox {
      margin-top: 20px;
      > div > label {
        cursor: auto;
      }
    }
    .similarLinks {
      height: auto;
      border-radius: 4px;
      border: solid 1px #e0e0e0;
      width: 100%;
      background: #ffffff;
      padding: 12px 14px;
      overflow: hidden;
    }

    .multiSelectButtons {
      background: #e4582a;
      border: 1px solid #e45829;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      padding: 13px 30px;
      width: fit-content;
      text-transform: capitalize;
      font-family: "Poppins";
      font-weight: 500;
      height: 47px;
      margin-right: 35px;
      // margin: 10px 19px 10px 0;
    }
    .buttonsSectionMain {
      display: flex;
      justify-content: space-between;
    }
    .buttonsSection {
      text-align: -webkit-left;
    }
    .SubHeaderStatus {
      font-size: 16px;
      text-align: left;
      padding: 18px 34px 21px 0;
    }
    .bedsBox {
      border: solid 1px #e0e0e0;
      border-radius: 4px;
      padding: 7px 14px;
      color: #212529;
      width: 100%;
    }
    .propertyDetailsTxt {
      text-align: left;
      padding: 30px 0;
      font-size: 18px;
      // font-weight:500;
    }
    .propertyDetailsTxtBeds {
      text-align: left;
      color: #000;
      font-weight: 500;
    }
    .SelectStatus {
      display: flex;
    }
    .SelectStatusSub {
      width: 50%;
      margin-right: 8px;
      p {
        text-align: left;
        color: #8b8ea7;
        font-weight: 500;
      }
    }
    .SubHeaderStatusMain {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    .SubHeaderStatusSub {
      width: 49%;
    }
    .firePlaceBox {
      border: solid 1px #e0e0e0;
      border-radius: 4px;
      padding: 7px 14px;
      color: #212529;
      width: 100%;
    }
    .SubHeaderMain {
      display: flex;
      justify-content: space-between;
    }
    .SelectStatus {
      display: flex;
    }
    .SubHeaderTwo {
      font-size: 16px;
      text-align: left;
      padding: 20px 0 20px 0;
    }
    .ButtonsDateTwo {
      width: 192px;
      margin-right: 15px;
    }
    .dragText {
      font-size: 22px;
      letter-spacing: 0px;
      color: #000000;
      padding-top: 15px;
      font-weight: 200;
    }
    .Upload {
      color: #4d9fff;
    }
    .buttonSection {
      width: 100%;
    }
  }
  .backIcon {
    padding-top: 120px;
    svg > g {
      cursor: pointer;
      :hover {
        opacity: 0.6;
      }
    }
    svg {
      margin-bottom: 25px;
      margin-left: -8px;
    }
  }
  .backIcon:hover {
    // opacity: 0.6;
  }

  .spacing > div {
    margin-bottom: 0px;
  }
  .similar_LinkSection {
    margin-bottom: 10px;
  }
`;

const MainSection = styled.div`
  text-align: center;
  width: 100%;
  display: flex;

  .customSelectBox {
    background-color: #fff;
    border: 1px solid #d2d9e8;
    border-radius: 8px;
    height: 58px;
    line-height: normal;
  }
  .customSelectBox:hover {
    background-color: #fff;
  }
  .customSelectBox::before {
    border-bottom: none;
  }
  .customSelectBox:hover::before {
    border-bottom: none;
  }
  .customSelectBox:hover::after {
    border-bottom: none;
  }
  .customSelectBox select {
    color: #4f5270;
    padding-top: 20px;
    font-size: 18px;
    font-family: "Poppins";
  }
  .customSelectBox svg {
    // top: calc(50% - 8px);
    display: none;
  }
  .othersSelectBox {
    position: relative;
    width: 100%;
    margin: 20px 0;
    text-align: left !important;
    display: flex;
  }
  .selectIcons {
    cursor: pointer;
    right: 9px;
    top: 19px;
    position: absolute;
  }
  :focus {
    outline: none;
  }
  .placeholderSelect {
    font-size: 14px;
    font-family: "Poppins";
    color: #4f5270;
    text-align: center;
  }

  .cancel_btn {
    border: 1px solid #cacaca;
    background: none;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #cacaca;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
  }
  .cancel_btn:hover {
    background: none;
  }

  .First_btn {
    background: none;
    border: 1px solid #e45829;
    border-radius: 4px;
    padding: 10px 30px;
    height: 47px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
    margin: 10px 19px 10px 0;
  }
  .First_btn:hover {
    background: none;
  }
  .selected {
    background: #e4582a;
    border: 1px solid #e45829;
    border-radius: 4px;
    // width: 194px;
    height: 47px;
    color: #fff;
    font-size: 14px;
    padding: 10px 30px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
    margin: 10px 19px 10px 0;
  }
  .selected:hover {
    background: #e4582a;
  }

  .backIcon {
    text-align: left;
    img {
      cursor: pointer;
    }
  }
  .MainGrid {
    display: flex;
    margin: 0 0 22px;
  }
  .MainGridText {
    display: flex;
  }
  .MainGridcancel {
    display: flex;
    margin: 75px 0;
  }
  .MainGridSecond {
    display: flex;
    margin: 80px 0 12px;
  }

  .firstHeader {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
  }
  .DropHeader {
    font-size: 14px;
    font-weight: 300;
    padding: 10px 0;
  }
  .UploadHeader {
    font-size: 9px;
    font-weight: 300;
    padding: 20px 0;
  }
  .SubHeader {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 0;
  }

  .SubHeaderDesc {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 20px;
  }

  .TextArea {
    border-radius: 8px;
    width: 100%;
    border: 1px solid #cacaca;
  }
  textarea {
    resize: none;
  }

  .ButtonsNext {
    display: flex;
    justify-content: left !important;
    margin: 74px 0;
    width: 100%;
  }
  .backButtonSection {
    text-align: left;
  }
  .Forward {
    cursor: pointer;
  }
  .Forward:hover {
    background: none;
  }

  .TextAreaSection {
    position: relative;
  }

  .ComponentTeaxtArea {
    position: absolute;
    top: 9px;
    right: 6px;
    width: 37px;
    cursor: pointer;
  }

  .next_btn {
    background: #e4582a;
    box-shadow: 0px 20px 29px #73849326;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
    margin-left: 4%;
  }
  .next_btn:hover {
    background: #e45829 0% 0% no-repeat padding-box;
  }
  .fileNameList {
    padding-top: 10px;
    text-align: left;
  }
`;

const MainSectionTwo = styled.div`
  text-align: center;
  display: flex;

  .TextArea {
    border-radius: 8px;
    width: 100%;
    border: 1px solid #cacaca;
  }
  .TextAreaSection {
    position: relative;
  }
  .ComponentTeaxtArea {
    position: absolute;
    top: 9px;
    right: 6px;
    width: 37px;
    cursor: pointer;
  }
  textarea {
    resize: none;
  }

  .SubHeaderTwo {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 20px 0;
  }
  .SubHeaderOne {
    text-align: left;
    font-weight: 700;
    font-family: "Poppins";
    font-size: 34px;
    margin-bottom: 20px;
  }

  .next_btn {
    background: #e4582a;
    box-shadow: 0px 20px 29px #73849326;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
    margin-left: 4%;
  }
  .next_btn:hover {
    background: #e45829 0% 0% no-repeat padding-box;
  }
  .headerLogoSection {
    text-align: left;
  }
  .MainGridTwo {
    display: flex;
    margin: 0 0 22px;
  }

  .SubHeaderDescTwo {
    font-size: 18px;
    text-align: left;
  }
  .ButtonsNextTwo {
    display: flex;
    margin-top: 35px;
  }
  .back_btn {
    border: 1px solid #cacaca;
    background: none;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
  }
  .back_btn:hover {
    background: none;
  }

  .Increment_btn {
    background: #e4582a;
    border: 1px solid #e45829;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
    margin-top: 27px;
  }
  .Increment_btn:hover {
    background: #e4582a;
  }
  .TextAreaTwo {
    width: 100%;
    border: 1px solid #cacaca;
    padding-top: 25px;
  }
  .ButtonsCheckBoxTwo {
    display: flex;
    padding-top: 30px;
    padding-left: 6px;
  }
  .ButtonsCheckBoxTwo span {
    font-size: 15px;
  }

  .ButtonsTimeTwo {
    width: 200px;
  }

  .formSection {
    width: 100%;
  }
  .textAreaSection {
    padding-top: 25px;
  }
  .links_buttons {
    width: 90px;
  }
  .button_section {
    display: flex;
    margin-bottom: 58px;
  }
  .redfinBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e4582a;
    border-radius: 8px;
    margin-top: 20px;
  }
  .mlsBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacaca;
    border-radius: 8px;
    margin: 20px 20px 0;
  }
  .zillowBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacaca;
    border-radius: 8px;
    margin-top: 20px;
  }
  .backIcon {
    text-align: left;
    svg > g {
      cursor: pointer;
      :hover {
        opacity: 0.6;
      }
    }
  }
`;
const SecondContainer = styled.div`
  display: flex;
  width: 100%;
  .leftSection {
    flex: 1;
    padding: 0;
  }
  .rightSection {
    flex: 1;
    padding-left: 4%;
  }
`;

const Mainsection = styled.div`
  padding-right: 0 !important;
  .dashboard-container {
    display: block;
  }
`;
const ImageSection = styled.div`
  .image-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
  }
  .firstImagemain {
    width: 100%;
  }

  position: relative;
  padding: 10px;
  display: flex;
  .ant-image {
    height: 100%;
    z-index: 1;
    > img {
      object-fit: cover;
      opacity: 0.4;
      width: 100%;
      height: 100%;
    }
  }
  .previewWrapper {
    position: relative;
  }
  .imageText {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }
`;

export {
  Container,
  MainSection,
  MainSectionTwo,
  SecondContainer,
  Mainsection,
  ImageSection,
};
