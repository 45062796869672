import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Layout from "web/components/Layout";
import AlertModal from "web/components/AlertModal";
import Button from "web/components/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Step1 from "./Step1";
import Step2 from "./Step2";
import styles from "../form.module.scss";
import "./stepperStyle.scss";
import {
  getAuthInitialValues,
  getRegisterSchema,
} from "web/components/utils/propertyDetailsUtils";
import {
  addPropertyData,
  getPropertyAutoSave,
  deleteAutosaveProperty,
  getCoreLogicData,
} from "redux/actions/property";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "web/pages/private/PropertyForm/style";
import CongratulationScreen from "web/components/CongratulationsScreen";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import SubmitPropertyConfirmation from "web/components/submitPropertyConfirmation";
import Spinner from "web/components/Spinner";

const theme = createMuiTheme({
  overrides: {
    MuiStepLabel: {
      root: {
        marginBottom: "0px",
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: "34px",
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: "17px",
      },
    },
  },
});
const PropertyDetail = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [comparalableLinks, setComparalableLinks] = useState([null]);
  const [activeStepActive, setActiveStepActive] = React.useState(0);
  const [response, setResponse] = useState();
  const [getCoreLogicResponse, setCoreLogicResponse] = useState();
  const [step, setStep] = useState(0);
  const [userRole, setUserRole] = useState("HOMEOWNER");
  const { userManagement } = useSelector((state) => state);
  const [comingSoonModal, setcomingSoonModal] = useState(false);
  const togglePropertyModal = () => setcomingSoonModal(!comingSoonModal);
  const [submitPropertyConfirm, setSubmitPropertyConfirm] = useState(false);
  const toggleSubmitPropertyConfirmModal = () =>
    setSubmitPropertyConfirm(!submitPropertyConfirm);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalLink, setModalLink] = useState("/");
  const toggleModal = () => setModal(!modal);
  let title = "";
  const [buttonActive, setButtonActive] = useState("");
  const validationSchemaArray = getRegisterSchema();
  const [isVacantSelected, setIsVacantSelected] = useState(
    response?.data?.type == "VacantLand" ? true : false
  );
  const [stepperActive, setStepperActive] = useState({
    askingPrice: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const loaderVisible = useSelector((state) => state.appReducer.loaderVisible);

  let token = localStorage.getItem("userInfo");
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const [propsState, setPropsState] = useState();
  const [isValidState, setIsValidState] = useState(false);

  useEffect(() => {
    setUserRole(userManagement?.profileData?.role);
    const fetchData = async () => {
      const addressDataAll = location?.state;
      const response = await dispatch(
        getPropertyAutoSave(token, addressDataAll.allAddressData)
      );
      if (response?.data?.length !== 0) {
        setIsVacantSelected(
          response?.data?.type == "VacantLand" ? true : false
        );
        setResponse(response);
        setActive({
          ...active,
          askingPrice: response?.data?.askingPrice ? "activeClass" : "",
          sellTime: response?.data?.sellTime ? "activeClass" : "",
          type:
            response?.data?.type === "SFR"
              ? "activeClass"
              : response?.data?.type === "COMMERCIAL BUILDING"
              ? "activeClass"
              : response?.data?.type === "COMMERCIAL (NEC)"
              ? "activeClass"
              : response?.data?.type === "CONDOMINIUM"
              ? "activeClass"
              : response?.data?.type === "VACANT LAND (NEC)"
              ? "activeClass"
              : response?.data?.type === "MFR"
              ? "activeClass"
              : "",
          occupancyStatus: response?.data?.occupancyStatus ? "activeClass" : "",
          propertyDetail:
            response?.data?.bedrooms && response?.data?.bathrooms
              ? "activeClass"
              : "",
          yearBuilt: response?.data?.yearBuilt ? "activeClass" : "",
          poolInfo: response?.data?.poolInfo ? "activeClass" : "",

          // Agent
          askingPrice: response?.data?.askingPrice ? "activeClass" : "",
          propertyStatus: response?.data?.propertyStatus ? "activeClass" : "",
          fairMarketPrice: response?.data?.fairMarketPrice ? "activeClass" : "",
          afterRepairValue: response?.data?.afterRepairValue
            ? "activeClass"
            : "",
          similarLinks: response?.data?.similarLinks ? "activeClass" : "",
          suggestedRehab: response?.data?.suggestedRehab ? "activeClass" : "",
          comments: response?.data?.comments ? "activeClass" : "",
          fixAndFlip: response?.data?.fixAndFlip ? "activeClass" : "",
          cashOnlyRequest: response?.data?.cashOnlyRequest ? "activeClass" : "",
          offerDeadLineDate: response?.data?.offerDeadLineDate
            ? "activeClass"
            : "",
        });
      }
      if (
        response?.data?.length === 0 ||
        !response?.data?.yearBuilt?.length ||
        !response?.data?.bedrooms?.length ||
        !response?.data?.bathrooms?.length ||
        !response?.data?.yearBuilt?.length
      ) {
        const coreLoginData = await dispatch(
          getCoreLogicData(token, addressDataAll.allAddressData)
        );
        setCoreLogicResponse(coreLoginData);
        setActive({
          ...active,
          askingPrice: coreLoginData?.data?.askingPrice ? "activeClass" : "",
          sellTime: coreLoginData?.data?.sellTime ? "activeClass" : "",
          type:
            coreLoginData?.data?.type === "SFR"
              ? "activeClass"
              : coreLoginData?.data?.type === "COMMERCIAL BUILDING"
              ? "activeClass"
              : coreLoginData?.data?.type === "COMMERCIAL (NEC)"
              ? "activeClass"
              : coreLoginData?.data?.type === "CONDOMINIUM"
              ? "activeClass"
              : coreLoginData?.data?.type === "VACANT LAND (NEC)"
              ? "activeClass"
              : coreLoginData?.data?.type === "MFR"
              ? "activeClass"
              : "",
          occupancyStatus: coreLoginData?.data?.occupancyStatus
            ? "activeClass"
            : "",
          propertyDetail:
            coreLoginData?.data?.bedrooms && coreLoginData?.data?.bathrooms
              ? "activeClass"
              : "",
          yearBuilt: coreLoginData?.data?.yearBuilt ? "activeClass" : "",
          poolInfo: coreLoginData?.data?.poolInfo ? "activeClass" : "",

          // Agent
          askingPrice: coreLoginData?.data?.askingPrice ? "activeClass" : "",
          propertyStatus: coreLoginData?.data?.propertyStatus
            ? "activeClass"
            : "",
          fairMarketPrice: coreLoginData?.data?.fairMarketPrice
            ? "activeClass"
            : "",
          similarLinks: coreLoginData?.data?.similarLinks ? "activeClass" : "",
          suggestedRehab: coreLoginData?.data?.suggestedRehab
            ? "activeClass"
            : "",
          comments: coreLoginData?.data?.comments ? "activeClass" : "",
          fixAndFlip: coreLoginData?.data?.fixAndFlip ? "activeClass" : "",
          offerDeadLineDate: coreLoginData?.data?.offerDeadLineDate
            ? "activeClass"
            : "",
        });
      }
    };
    fetchData();
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  let steps;
  let subSteps = [];
  let subSteps2 = [];
  if (userRole === "HOMEOWNER") {
    subSteps = [
      "Deadline",
      "Asking Price",
      "After Repair Value",
      "Property Type",
      "Occupancy Status",
      "Property Details",
    ];
    steps = ["Property Details", "Complete"];
  } else if (userRole === "AGENT") {
    subSteps = [
      "Property Status",
      "Property Type",
      "Occupancy Status",
      "Property Details",
    ];
    subSteps2 = [
      "Asking Price",
      "Fair Market Price(As-Is)",
      "After Repair Value",
      "Similar Links",
      "Suggested Rehab",
      "Strategy",
      "Comments",
      "Offer Submission Deadline",
      "Cash Only Offer Request",
    ];
    steps = ["Property Details", "Pricing", "Complete"];
  } else if (userRole === "OTHER") {
    subSteps = [
      "Deadline",
      "Property Status",
      "Property Type",
      "Occupancy Status",
      "Property Details",
    ];
    subSteps2 = [
      "Asking Price",
      "Fair Market Price(As-Is)",
      "After Repair Value",
      "Similar Links",
      "Suggested Rehab",
      "Comments",
      "Offer Submission Deadline",
      "Cash Only Offer Request",
    ];
    steps = ["Property Details", "Pricing", "Complete"];
  }

  const handleNext = (props, isVacantSelected) => {
    setPropsState({ props });
    if (props.values.files.length === 0) {
      setModalContent("Please upload atleast one file.");
      setModalLink("");
      toggleModal();
    } else {
      const { submitForm, isValid, validateForm, setTouched } = props;
      submitForm().then(() => {
        if (isValid) {
          setIsValidState(isValid);
          setActiveStepActive(activeStep);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);

          if (userRole === "HOMEOWNER") {
            if (step < 0) {
              setStep(step + 1);
            } else {
              formSubmit(props);
            }
          } else {
            if (step === 1) {
              formSubmit(props, isVacantSelected);
            } else {
              if (step <= 1) {
                setStep(step + 1);
              }
            }
          }
          window.scrollTo(0, 0);
          validateForm();
          setTouched({});
        }
      });
    }
  };

  const formSubmit = async (props) => {
    if (userRole === "OTHER") {
      delete props.values.sellProperty;
      // delete props.values.fixAndFlip;
      if (isVacantSelected || props.values.type === "VacantLand") {
        delete props.values.bedrooms;
        delete props.values.bathrooms;
        delete props.values.yearBuilt;
        delete props.values.floors;
        delete props.values.poolInfo;
        delete props.values.carParking;
        delete props.values.firePlace;
        delete props.values.airCondition;
        delete props.values.firePlaceValue;
        delete props.values.squareFootage;
      }
      // if (props.values.cashOnlyRequest === true) {
      //   props.values["cashOnlyRequest"] = "1";
      // } else {
      //   props.values["cashOnlyRequest"] = "0";
      // }
    } else if (userRole === "HOMEOWNER") {
      if (!props?.values?.afterRepairValue) {
        delete props.values.afterRepairValue;
      }
      delete props.values.comments;
      delete props.values.fairMarketPrice;
      delete props.values.fixAndFlip;
      delete props.values.propertyStatus;
      delete props.values.suggestedRehab;
      delete props.values.offerDeadLineDate;
      delete props.values.offerDeadLineTime;
      delete props.values.cashOnlyRequest;
      delete props.values.similarLinks;
      if (isVacantSelected || props.values.type === "VacantLand") {
        delete props.values.bedrooms;
        delete props.values.bathrooms;
        delete props.values.yearBuilt;
        delete props.values.floors;
        delete props.values.poolInfo;
        delete props.values.carParking;
        delete props.values.firePlace;
        delete props.values.airCondition;
        delete props.values.firePlaceValue;
        delete props.values.squareFootage;
      }
    } else if (userRole === "AGENT") {
      delete props.values.sellTime;
      if (isVacantSelected || props.values.type === "VacantLand") {
        delete props.values.bedrooms;
        delete props.values.bathrooms;
        delete props.values.yearBuilt;
        delete props.values.floors;
        delete props.values.poolInfo;
        delete props.values.carParking;
        delete props.values.firePlace;
        delete props.values.airCondition;
        delete props.values.firePlaceValue;
        delete props.values.squareFootage;
      }
    }
    if (!props?.values?.firePlaceValue) {
      delete props.values.firePlaceValue;
    }
    let { allAddressData } = location?.state;
    delete allAddressData.googleAddress;
    let newPorpertyData = { ...props.values, ...allAddressData };
    let formData = new FormData();
    for (var prop in newPorpertyData) {
      if (prop === "files") {
        for (var index = 0; index < newPorpertyData[prop].length; index++) {
          formData.append("files", newPorpertyData[prop][index]);
        }
      } else if (prop === "similarLinks") {
        for (let i = 0; i < newPorpertyData[prop].length; i++) {
          formData.append("similarLinks", newPorpertyData[prop][i]);
        }
      } else {
        formData.append(prop, newPorpertyData[prop]);
      }
    }
    const response = await dispatch(addPropertyData(formData, token));
    if (!response.error) {
      await dispatch(deleteAutosaveProperty(allAddressData));
      setcomingSoonModal(true);
      setTimeout(() => history.push("/Dashboard"), 2000);
    } else if (
      response?.ex?.response?.data?.message === "File format not supported"
    ) {
      setModalContent(response?.ex?.response?.data?.message);
      setModalLink("");
      toggleModal();
    } else {
      setModalContent(
        response?.data?.response?.data?.message || response.ex.message
      );
      setModalLink("");
      toggleModal();
    }
  };
  const handleBack = ({ setErrors }) => {
    setPropsState({ props });
    if (step === 0) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStep(step - 1);
    window.scrollTo(0, 0);
    setErrors({});
  };

  const [active, setActive] = useState({
    // Step1
    askingPrice: "",
    sellTime: "",
    type: "",
    occupancyStatus: "",
    propertyDetail: "",
    bathrooms: "",
    yearBuilt: "",
    poolInfo: "",
    // Agent
    propertyStatus: "",
    // Step2
    fairMarketPrice: "",
    similarLinks: "",
    suggestedRehab: "",
    fixAndFlip: "",
    comments: "",
    cashOnlyRequest: "",
    offerDeadLineDate: "",
    askingPrice: "",
    afterRepairValue: "",
  });

  const setStepper = (name, obj, props) => {
    if (name === "askingPrice" && obj !== "") {
      setActive({ ...active, askingPrice: "activeClass" });
    }
    if (name === "askingPrice" && obj === "") {
      setActive({ ...active, askingPrice: "" });
    }
    if (name === "sellTime" && obj !== "") {
      setActive({ ...active, sellTime: "activeClass" });
    }
    if (name === "type" && obj !== "") {
      setActive({ ...active, type: "activeClass" });
    }
    if (name === "type" && obj === "") {
      setActive({ ...active, type: "" });
    }
    if (name === "type" && obj === "VacantLand") {
      setActive({
        ...active,
        occupancyStatus: "activeClass",
        type: "activeClass",
      });
    }
    if (name === "occupancyStatus" && obj !== "") {
      setActive({ ...active, occupancyStatus: "activeClass" });
    }
    if (name === "occupancyStatus" && obj === "") {
      setActive({ ...active, occupancyStatus: "" });
    }
    if (name === "bedrooms" || name === "bathrooms") {
      setActive({ ...active, propertyDetail: "activeClass" });
    }
    if (name === "propertyStatus" && obj !== "") {
      setActive({ ...active, propertyStatus: "activeClass" });
    }
    if (name === "propertyStatus" && obj === "") {
      setActive({ ...active, propertyStatus: "" });
    }
    //Step2
    if (name === "fairMarketPrice" && obj !== "") {
      setActive({ ...active, fairMarketPrice: "activeClass" });
    }
    if (name === "fairMarketPrice" && obj === "") {
      setActive({ ...active, fairMarketPrice: "" });
    }
    if (name === "afterRepairValue" && obj !== "") {
      setActive({ ...active, afterRepairValue: "activeClass" });
    }
    if (name === "afterRepairValue" && obj === "") {
      setActive({ ...active, afterRepairValue: "" });
    }
    if (name === "similarLinks" && obj !== "") {
      setActive({ ...active, similarLinks: "activeClass" });
    }
    if (name === "similarLinks" && obj === "") {
      setActive({ ...active, similarLinks: "" });
    }
    if (name === "suggestedRehab" && obj !== "") {
      setActive({ ...active, suggestedRehab: "activeClass" });
    }
    if (name === "suggestedRehab" && obj === "") {
      setActive({ ...active, suggestedRehab: "" });
    }
    if (name === "fixAndFlip" && obj !== "") {
      setActive({ ...active, fixAndFlip: "activeClass" });
    }
    if (name === "comments" && obj !== "") {
      setActive({ ...active, comments: "activeClass" });
    }
    if (name === "comments" && obj === "") {
      setActive({ ...active, comments: "" });
    }
    if (name === "offerDeadLineDate" && obj !== "") {
      setActive({ ...active, offerDeadLineDate: "activeClass" });
    }
    // if (name === "cashOnlyRequest" && obj !== "") {
    //   setActive({ ...active, cashOnlyRequest: "activeClass" });
    // }
    // if (name === "cashOnlyRequest" && obj === true) {
    //   setActive({ ...active, cashOnlyRequest: "" });
    // }
    if (name === "cashOnlyRequest" && obj !== "") {
      setActive({ ...active, cashOnlyRequest: "activeClass" });
    }
    if (name === "cashOnlyRequest" && obj === "") {
      setActive({ ...active, cashOnlyRequest: "" });
    }
  };
  const backPage = () => {
    history.push("/dashboard");
  };
  return (
    <>
      <CongratulationScreen
        isOpen={comingSoonModal}
        toggle={togglePropertyModal}
        title=""
      />
      <SubmitPropertyConfirmation
        data={handleNext}
        props={buttonActive}
        isOpen={submitPropertyConfirm}
        toggle={toggleSubmitPropertyConfirmModal}
        title=""
      />
      <Layout transparentHeader={false} pageType="Dashboard">
        {loaderVisible && <Spinner />}
        <Container>
          <div className={styles.section}>
            <ThemeProvider theme={theme}>
              <Stepper
                style={{ position: "sticky", top: 110 }}
                activeStep={activeStep}
                orientation="vertical"
              >
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => {}}>
                    <StepLabel>{label}</StepLabel>

                    <div className={styles.subStepsLabel}>
                      {index === 0 &&
                        subSteps.length !== 0 &&
                        subSteps.map((subLabel, subIndex) => (
                          <>
                            {userRole === "HOMEOWNER" && (
                              <>
                                {subIndex === 0 && (
                                  <div className={active.sellTime}>
                                    <span className="numbering">1.1</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 1 && (
                                  <div className={active.askingPrice}>
                                    <span className="numbering">1.2</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 2 && (
                                  <div className={active.afterRepairValue}>
                                    <span className="numbering">1.3</span>
                                    {subLabel}
                                  </div>
                                )}

                                {subIndex === 3 && (
                                  <div className={active.type}>
                                    <span className="numbering">1.4</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 4 && (
                                  <div className={active.occupancyStatus}>
                                    <span className="numbering">1.5</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 5 && !isVacantSelected && (
                                  <div className={active.propertyDetail}>
                                    <span className="numbering">1.6</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 6 && (
                                  <div className={active.yearBuilt}>
                                    <span className="numbering">1.7</span>
                                    {subLabel}
                                  </div>
                                )}
                              </>
                            )}

                            {userRole === "AGENT" && (
                              <>
                                {step !== 1 && subIndex === 0 && (
                                  <div className={active.propertyStatus}>
                                    <span className="numbering">1.1</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 && subIndex === 1 && (
                                  <div className={active.type}>
                                    <span className="numbering">1.2</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 && subIndex === 2 && (
                                  <div className={active.occupancyStatus}>
                                    <span className="numbering">1.3</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 &&
                                  subIndex === 3 &&
                                  !isVacantSelected && (
                                    <div className={active.propertyDetail}>
                                      <span className="numbering">1.4</span>
                                      {subLabel}
                                    </div>
                                  )}
                              </>
                            )}

                            {userRole === "OTHER" && (
                              <>
                                {step !== 1 && subIndex === 0 && (
                                  <div className={active.sellTime}>
                                    <span className="numbering">1.1</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 && subIndex === 1 && (
                                  <div className={active.propertyStatus}>
                                    <span className="numbering">1.2</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 && subIndex === 2 && (
                                  <div className={active.type}>
                                    <span className="numbering">1.3</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 && subIndex === 3 && (
                                  <div className={active.occupancyStatus}>
                                    <span className="numbering">1.4</span>
                                    {subLabel}
                                  </div>
                                )}
                                {step !== 1 &&
                                  subIndex === 4 &&
                                  !isVacantSelected && (
                                    <div className={active.propertyDetail}>
                                      <span className="numbering">1.5</span>
                                      {subLabel}
                                    </div>
                                  )}
                              </>
                            )}
                          </>
                        ))}

                      {index === 1 &&
                        step === 1 &&
                        subSteps2.map((subLabel, subIndex) => (
                          <>
                            {userRole === "AGENT" && (
                              <>
                                {subIndex === 0 && (
                                  <div className={active.askingPrice}>
                                    <span className="numbering">2.1</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 1 && (
                                  <div className={active.fairMarketPrice}>
                                    <span className="numbering">2.2</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 2 && (
                                  <div className={active.afterRepairValue}>
                                    <span className="numbering">2.3</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 3 && (
                                  <div className={active.similarLinks}>
                                    <span className="numbering">2.4</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 4 && (
                                  <div className={active.suggestedRehab}>
                                    <span className="numbering">2.5</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 5 && (
                                  <div className={active.fixAndFlip}>
                                    <span className="numbering">2.6</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 6 && (
                                  <div className={active.comments}>
                                    <span className="numbering">2.7</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 7 && (
                                  <div className={active.offerDeadLineDate}>
                                    <span className="numbering">2.8</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 8 && (
                                  <div className={active.cashOnlyRequest}>
                                    <span className="numbering">2.9</span>
                                    {subLabel}
                                  </div>
                                )}
                              </>
                            )}
                            {userRole === "OTHER" && (
                              <>
                                {subIndex === 0 && (
                                  <div className={active.askingPrice}>
                                    <span className="numbering">2.1</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 1 && (
                                  <div className={active.fairMarketPrice}>
                                    <span className="numbering">2.2</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 2 && (
                                  <div className={active.afterRepairValue}>
                                    <span className="numbering">2.3</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 3 && (
                                  <div className={active.similarLinks}>
                                    <span className="numbering">2.4</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 4 && (
                                  <div className={active.suggestedRehab}>
                                    <span className="numbering">2.5</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 5 && (
                                  <div className={active.comments}>
                                    <span className="numbering">2.6</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 6 && (
                                  <div className={active.offerDeadLineDate}>
                                    <span className="numbering">2.7</span>
                                    {subLabel}
                                  </div>
                                )}
                                {subIndex === 7 && (
                                  <div className={active.cashOnlyRequest}>
                                    <span className="numbering">2.8</span>
                                    {subLabel}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ))}
                    </div>
                  </Step>
                ))}
              </Stepper>
            </ThemeProvider>
          </div>
          <div className={styles.section}>
            {(response || getCoreLogicResponse) && userRole && (
              <Formik
                initialValues={getAuthInitialValues(
                  userRole,
                  response,
                  getCoreLogicResponse
                )}
                validationSchema={validationSchemaArray[step](
                  userRole,
                  isVacantSelected,
                  response
                )}
                onSubmit={(values) => {}}
              >
                {(props) => {
                  const { isValid, dirty, errors } = props;
                  return (
                    <>
                      <Form>
                        {step === 0 && (
                          <Step1
                            addressData={location?.state}
                            props={props}
                            dataResponse={response}
                            coreLogicResponse={getCoreLogicResponse}
                            userRole={userRole}
                            callBackFun={(name, obj) => {
                              setStepper(name, obj, props);
                            }}
                            vacantSelected={(value) => {
                              setIsVacantSelected(value);
                            }}
                            inputRef={inputRef}
                          />
                        )}
                        {step === 1 && (
                          <p>
                            <Step2
                              addressData={location?.state}
                              handleBack={() => {
                                handleBack(props);
                              }}
                              props={props}
                              errors={errors}
                              dataResponse={response}
                              coreLogicResponse={getCoreLogicResponse}
                              userRole={userRole}
                              comparalableLinks={comparalableLinks}
                              callBackFun={(name, obj) => {
                                if (name === "similarLinks") {
                                  setComparalableLinks(obj);
                                }
                                setStepper(name, obj, props);
                              }}
                            />
                          </p>
                        )}

                        <div className={styles.footer}>
                          <div className={styles.fieldGroup}>
                            {step !== 0 && (
                              <>
                                <div
                                  id="cancelBtns"
                                  className="cancelBtn"
                                  onClick={() => {
                                    handleBack(props);
                                  }}
                                >
                                  Back
                                </div>
                                <div
                                  id="cancelBtns"
                                  className="cancelBtn"
                                  onClick={() => {
                                    handleBack(props);
                                  }}
                                >
                                  Review
                                </div>
                              </>
                            )}
                            <div className={styles.field}>
                              {step === 1 ? (
                                <div>
                                  <Button
                                    type="app-orange-button submitBtn "
                                    disabled={!(isValid && dirty)}
                                    handleClick={() => {
                                      setSubmitPropertyConfirm(true);
                                      setButtonActive(props);
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              ) : (
                                <div className="button_section">
                                  <Button
                                    id="cancelBtns"
                                    className="cancelBtn"
                                    handleClick={() => {
                                      backPage();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  {userRole === "HOMEOWNER" && (
                                    <>
                                      <Button
                                        id="cancelBtn"
                                        className="cancelBtn"
                                        handleClick={() => {
                                          window.scrollTo(0, 0);
                                        }}
                                      >
                                        Review
                                      </Button>
                                    </>
                                  )}
                                  {!response ? (
                                    <Button
                                      type="app-orange-button submitBtn "
                                      disabled={!(isValid && dirty)}
                                      handleClick={() => {
                                        if (userRole === "HOMEOWNER") {
                                          if (props.values.files.length === 0) {
                                            setModalContent(
                                              "Please upload at least one file."
                                            );
                                            setModalLink("");
                                            toggleModal();
                                          } else {
                                            setSubmitPropertyConfirm(true);
                                            setButtonActive(props);
                                          }
                                        } else {
                                          handleNext(props, isVacantSelected);
                                        }
                                      }}
                                    >
                                      {userRole === "HOMEOWNER"
                                        ? "Submit"
                                        : "Next"}
                                    </Button>
                                  ) : (
                                    <Button
                                      type="app-orange-button submitBtn "
                                      disabled={!(isValid && dirty)}
                                      handleClick={() => {
                                        if (userRole === "HOMEOWNER") {
                                          if (props.values.files.length === 0) {
                                            setModalContent(
                                              "Please upload at least one file."
                                            );
                                            setModalLink("");
                                            toggleModal();
                                          } else {
                                            setSubmitPropertyConfirm(true);
                                            setButtonActive(props);
                                          }
                                        } else {
                                          handleNext(props, isVacantSelected);
                                        }
                                      }}
                                    >
                                      {userRole === "HOMEOWNER"
                                        ? "Submit"
                                        : "Next"}
                                    </Button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            )}
          </div>
        </Container>
        <AlertModal
          isOpen={modal}
          toggle={toggleModal}
          title={title}
          content={modalContent}
          link={modalLink}
        />
      </Layout>
    </>
  );
};

export default PropertyDetail;
