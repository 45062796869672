import * as Yup from 'yup';

export function getAuthInitialValues(isResponse) {
  return {
    withoutLicenseNumber: false,
  };
}

export function getValidationSchema() {
    let schemaAttributes = {
      Jurisdiction: Yup.string().trim().required('Jurisdiction is required.'),
      agentLicenseNumber: Yup.string().trim().required('License Number is required.'),
    };
    return Yup.object().shape(schemaAttributes);
}
