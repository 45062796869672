import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { GUEST, SECURED, FIRST, PUBLIC } from 'web/components/Router/constants';
import Route from 'web/components/Router/privateRoute';
import LandingPage from 'web/pages/public/landing-page';
import Auth from 'web/pages/account/Auth';
import ForgotPassword from 'web/pages/account/ForgotPassword';
import VerifyEmail from 'web/pages/account/emailVerify/index';
import CreatePassword from 'web/pages/account/CreatePassword';
import Dashboard from 'web/pages/private/Dashboard';
import Contact from 'web/pages/public/contactUs';
import Profile from 'web/pages/private/Profile';
import EditProperty from 'web/pages/private/EditProperty';
import PropertyDetail from 'web/pages/private/PropertyForm/PropertyDetail';
import Appearance from 'web/pages/private/Appearance';
import Registration from 'web/pages/account/Registration';
import UserRoles from 'web/pages/account/Registration/UserRoles';
import AgentVerification from 'web/pages/account/Registration/agentVerification';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'redux/store/index';
import { Provider } from 'react-redux';

const ReactRouter = () => {
  return (
    <div className='App'>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <Route exact path='/' component={LandingPage} type={GUEST} />
              <Route exact path='/contact' component={Contact} type={PUBLIC} />
              <Route exact path='/login' component={Auth} type={GUEST} />
              <Route exact path='/forgot-password' component={ForgotPassword} type={GUEST} />
              <Route exact path='/change-password' component={CreatePassword} type={GUEST} />
              <Route exact path='/dashboard' component={Dashboard} type={SECURED} />
              <Route exact path='/registration' component={Registration} type={GUEST} />
              <Route exact path='/user-roles' component={UserRoles} type={GUEST} />
              <Route exact path='/agent-verification' component={AgentVerification} />
              <Route exact path='/profile' component={Profile} type={SECURED} />
              <Route exact path='/appearance' component={Appearance} type={SECURED} />
              <Route exact path='/property-detail' component={PropertyDetail} type={SECURED} />
              <Route exact path='/verify-email' component={VerifyEmail} type={GUEST} />
              <Route exact path='/show-property' component={EditProperty} type={SECURED} />
              <Route exact path={'*'} type={FIRST} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default ReactRouter;
