import styled, { css } from "styled-components";

const ImageSection = styled.div`
  > img {
    cursor: pointer;
    top: 0;
    left: 0;
    transform: translate(0%, 15%);
    padding-left: 28px;
    padding-top: 7px;
  }
`;
const MainSection = styled.div`
  justify-items: center;
  padding: 107px 0 210px 0;
  display: grid;
  align-items: center;
  text-align: center;
  .textA {
    color: ${({ theme }) => theme.colors.homePageTextA};
    font-size: 36px;
    font-weight: bold;
    font-family: "Poppins";
    width: fit-content;
    border-bottom: 2px solid ${({ theme }) => theme.colors.homePageTextA};
    padding-bottom: 5px;
  }
`;

const SelectTypeSection = styled.div`
  display: flex;
  align-items: center;
  justify-self: center;
  max-width: 960px;
  width: 100%;
  justify-content: center;
  margin: 60px auto;
  .selectTypeBox {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 29px #73849326;
    border-radius: 22px;
    height: 370px;
    cursor: pointer;
    position: relative;
    width: 370px;
  }
  .image {
    width: 370px;
  }
  .sectionMain {
  }
  .sectionType {
    width: 285px;
    cursor: pointer;
    float: left;
    transition: transform 0.2s;
    margin-bottom: 10px;
    margin-left: 17px;
    margin-right: 17px;
  }
  .sectionType:hover {
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1.1);
  }
  .textBox {
    position: absolute;
    bottom: 0;
  }
  .boxes {
    padding: 40px 0 14px 0;
    position: relative;
    background: #6f6c6c;
    box-shadow: 0px 0px 25px #e458293d;
    border-radius: 6px;
    min-height: 313px;
    height: 100%;
  }
  .desc_text {
    color: #4d4949;
    font-size: 12px;
    font-family: "Poppins";
    padding-bottom: 7px;
  }
  .type_text {
    font-size: 24px;
    font-weight: bold;
    font-family: "Poppins";
    color: #656565;
    padding-bottom: 10px;
  }
  .registerIcon {
    width: 85px;
    height: 85px;
  }
  .whiteShape {
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
`;

export { MainSection, SelectTypeSection, ImageSection };
