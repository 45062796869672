import styled from 'styled-components';
import img from 'web/assets/images/tick.png';

const Container = styled.div`
  display: flex;
  flex: 1;
  grid-gap: 22px;
  padding-top: 100px;
  > div:first-child {
    width: 20%;
    min-width: 240px;
  }
  > div:nth-child(2) {
    flex: 1;
    width: 80%;
  }
  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiStepLabel-label.MuiStepLabel-active,
  .MuiStepIcon-root.MuiStepIcon-completed,
  .MuiStepLabel-label.MuiStepLabel-completed {
    color: #e45829;
    position: relative;
  }
  .button_section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  #cancelBtn {
    border: 1px solid #cacaca;
    border-radius: 8px;
    font-size: 16px;
    color: #8b8ea7;
    background-color: #fff;
  }
  #cancelBtns {
    border: 1px solid #cacaca;
    border-radius: 8px;
    font-size: 16px;
    color: #8b8ea7;
    background-color: #fff;
    width: 100%;
    height: 48px;
    // margin-right: 3%;
    text-align: center;
    padding-top: 11px;
    margin-top: 5px;
    cursor: pointer;
  }
  #cancelBtn:hover {
    background-color: #eee;
  }
  #cancelBtns:hover {
    background-color: #eee;
  }
`;

const MainSection = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  .customSelectBox {
    background-color: #fff;
    border: 1px solid #d2d9e8;
    border-radius: 8px;
    height: 58px;
    line-height: normal;
  }
  .customSelectBox:hover {
    background-color: #fff;
  }
  .customSelectBox::before {
    border-bottom: none;
  }
  .customSelectBox:hover::before {
    border-bottom: none;
  }
  .customSelectBox:hover::after {
    border-bottom: none;
  }
  .customSelectBox select {
    color: #4f5270;
    padding-top: 20px;
    font-size: 18px;
    font-family: 'Poppins';
  }
  .customSelectBox svg {
    // top: calc(50% - 8px);
    display: none;
  }
  .othersSelectBox {
    position: relative;
    width: 100%;
    margin: 20px 0;
    text-align: left !important;
    display: flex;
  }
  .selectIcons {
    cursor: pointer;
    right: 9px;
    top: 19px;
    position: absolute;
  }
  :focus {
    outline: none;
  }
  .placeholderSelect {
    font-size: 14px;
    font-family: 'Poppins';
    color: #4f5270;
    text-align: center;
  }

  .cancel_btn {
    border: 1px solid #cacaca;
    background: none;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #cacaca;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
  }
  .cancel_btn:hover {
    background: none;
  }

  .First_btn {
    background: none;
    border: 1px solid #e45829;
    border-radius: 4px;
    padding: 10px 30px;
    height: 47px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 10px 19px 10px 0;
  }
  .First_btn:hover {
    background: none;
  }
  .selected {
    background: #e4582a;
    border: 1px solid #e45829;
    border-radius: 4px;
    // width: 194px;
    height: 47px;
    color: #fff;
    font-size: 14px;
    padding: 10px 30px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 10px 19px 10px 0;
  }
  .selected:hover {
    background: #e4582a;
  }

  .backIcon {
    text-align: left;
    svg > g {
      cursor: pointer;
      :hover {
        opacity: 0.6;
      }
    }
    svg {
      margin-left: -10px;
    }
  }
  .backIcon:hover {
    // opacity: 0.6;
  }
  .MainGrid {
    display: flex;
    margin: 0 0 22px;
  }
  .MainGridText {
    display: flex;
  }
  .MainGridcancel {
    display: flex;
    margin: 75px 0;
  }
  .MainGridSecond {
    display: flex;
    margin: 80px 0 12px;
  }
  .firstHeader {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
  }
  .DropHeader {
    font-size: 14px;
    font-weight: 300;
    padding: 10px 0;
  }
  .UploadHeader {
    font-size: 9px;
    font-weight: 300;
    padding: 20px 0;
  }
  .SubHeader {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 22px;
  }
  .SubHeaderSecond {
    font-size: 18px;
    text-align: left;
    padding: 25px 0 20px;
  }
  .SubHeaderStatus {
    font-size: 18px;
    text-align: left;
    padding: 30px 0 20px;
  }
  .SubHeaderDesc {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 20px;
  }

  .TextArea {
    border-radius: 8px;
    width: 100%;
    border: 1px solid #cacaca;
  }
  textarea {
    resize: none;
  }
  .loginWrapper {
    justify-content: center;
    width: 38%;
    margin-left: 20%;
    margin-right: auto;
  }

  .buttonsSection {
    text-align: -webkit-left;
  }
  .buttonsSectionMain {
    display: flex;
  }
  .ButtonsNext {
    display: flex;
    justify-content: left !important;
    margin: 74px 0;
    width: 100%;
  }
  .backButtonSection {
    text-align: left;
  }
  .Forward {
    cursor: pointer;
  }
  .Forward:hover {
    background: none;
  }

  .TextAreaSection {
    position: relative;
  }

  .ComponentTeaxtArea {
    position: absolute;
    top: 9px;
    right: 6px;
    width: 37px;
    cursor: pointer;
  }

  .SubHeaderTwo {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 20px 0;
  }

  .propertyDetailsTxt {
    text-align: left;
    padding: 30px 0;
    font-size: 18px;
  }
  .propertyDetailsTxtBeds {
    text-align: left;
    color: #000;
    font-weight: 500;
  }
  .SelectStatus {
    display: flex;
  }
  .SelectStatusSub {
    width: 50%;
    margin-right: 8px;
    p {
      text-align: left;
      color: #8b8ea7;
      font-weight: 500;
    }
  }
  .next_btn {
    background: #e4582a;
    box-shadow: 0px 20px 29px #73849326;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin-left: 4%;
  }
  .next_btn:hover {
    background: #e45829 0% 0% no-repeat padding-box;
  }
  .fileNameList {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 4;
    justify-content: flex-start;
    align-items: center;
    div {
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .dropOrInputSection {
    padding: 35px 0 20px 0;
    border: 1px dashed #e45829;
    border-radius: 8px;
    text-align-last: center;
  }
  .MainText {
    font-size: 30px;
    font-weight: 600;
    text-align: left;
    padding-bottom: 30px;
    padding-top: 40px;
  }
  .dragText {
    font-size: 22px;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 15px;
    font-weight: 200;
  }
`;

const MainSectionTwo = styled.div`
  text-align: center;
  display: flex;

  .TextArea {
    border-radius: 8px;
    width: 100%;
    border: 1px solid #cacaca;
  }
  .Checkbox_section {
    margin: 20px 0 0 0;
  }
  .TextAreaSection {
    position: relative;
  }
  .ComponentTeaxtArea {
    position: absolute;
    top: 9px;
    right: 6px;
    width: 37px;
    cursor: pointer;
  }
  textarea {
    resize: none;
  }
  .loginWrapper {
    justify-content: center;
    width: 38%;
    margin-left: 20%;
    margin-right: auto;
  }
  .SubHeaderTwo {
    font-size: 18px;
    text-align: left;
    padding: 20px 0 20px 0;
  }
  .SubHeaderOne {
    text-align: left;
    font-weight: 700;
    font-family: 'Poppins';
    font-size: 27px;
    margin: 50px 0 20px 0;
  }
  .SubHeaderSecond {
    font-size: 18px;
    text-align: left;
    padding: 22px 0 28px;
  }
  .EyeIcons {
    border: 1px solid #e4582a;
    width: 40px inherit;
    width: 67px;
    height: 48px;
    padding: 8px 8px;
    border-radius: 6px;
    margin-left: 10px;
  }
  .SubHeaderIcons {
    display: flex;
  }
  .SubHeaderInput {
    width: 100%;
    margin-right: 20px;
  }
  .MinusIcons {
    background: #e4582a;
    border-radius: 8px;
    width: 59px;
    height: 49px;
    padding: 12px 18px 0 18px;
  }
  .next_btn {
    background: #e4582a;
    box-shadow: 0px 20px 29px #73849326;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin-left: 4%;
  }
  .next_btn:hover {
    background: #e45829 0% 0% no-repeat padding-box;
  }
  .headerLogoSection {
    text-align: left;
  }
  .MainGridTwo {
    display: flex;
    margin: 0 0 22px;
  }
  .loginWrapperTwo {
    justify-content: center;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }

  .SubHeaderDescTwo {
    font-size: 18px;
    text-align: left;
  }
  .ButtonsNextTwo {
    display: flex;
    margin-top: 30px;
  }
  .back_btn {
    border: 1px solid #cacaca;
    background: none;
    border-radius: 8px;
    width: 48%;
    height: 50px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
  }
  .back_btn:hover {
    background: none;
  }

  .Increment_btn {
    background: #e4582a;
    border: 1px solid #e45829;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin-top: 27px;
  }
  .Increment_btn:hover {
    background: #e4582a;
  }
  .TextAreaTwo {
    width: 100%;
    border: 1px solid #cacaca;
    padding-top: 25px;
  }
  .ButtonsCheckBoxTwo {
    display: flex;
    padding-top: 30px;
    padding-left: 6px;
  }
  .ButtonsCheckBoxTwo span {
    font-size: 15px;
  }
  .ButtonsDateTimeTwo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    padding: 7px 0;
    color: #8b8ea7;
    > div {
      width: 100%;
      max-width: 315px;
      > div {
        input {
          border: none;
          box-shadow: none;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #e0e0e0 !important;
        border-radius: 10px;
        height: 56px;
      }
    }
  }
  .timeCard {
    padding: 6px 0;
    margin-left: 20px;
    > div {
      padding: 9px 18px;
      width: 100%;
      max-width: 237px;
      margin-left: 20px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
    }
  }
  .offerDeadlineSection {
    display: flex;
  }
  .ButtonsTimeTwo {
    width: 200px;
  }

  .ButtonsDateTwo {
    width: 192px;
    margin-right: 15px;
  }
  .formSection {
    width: 100%;
  }
  .textAreaSection {
    padding-top: 25px;
  }
  .links_buttons {
    width: 80px;
  }
  .MuiInputBase-input {
    border-radius: 4px;
  }
  .similarLinkSection {
    margin-bottom: 25px;

    > div {
      display: flex;
      align-items: center;
    }
  }
  .similarLinkSection > div > div {
    width: 100%;
    height: 47px;
  }
  .similarLinkSection > div > div > div {
    height: 47px;
  }

  .similarLinkSection > div > .AddCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e45829;
    height: 47px;
    width: 60px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 11px 4px;
    cursor: pointer;
    color: #fff;
  }
  .input_section_one {
    width: 100% !important;
    display: inline-flex;
    align-items: center;
    .app-input-text {
      width: 100%;
    }
    .Circle {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e45829;
      height: 47px;
      width: 60px;
      margin-left: 6px;
      border-radius: 5px;
      padding: 11px 4px;
      cursor: pointer;
      color: #fff;
    }
  }
  .button_section {
    display: flex;
    width: 20%;
    margin-left: 11px;
  }
  .similarLinkSection > div > div > div > input {
    border-radius: 4px;
  }
  .redfinBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e4582a;
    border-radius: 8px;
    padding: 0;
  }
  .mlsBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacaca;
    border-radius: 8px;
    margin: 20px 20px 0;
  }
  .zillowBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cacaca;
    border-radius: 8px;
    margin-top: 20px;
  }
  .backIcon {
    text-align: left;
    margin-left: -11px;
    svg > g {
      cursor: pointer;
      :hover {
        opacity: 0.6;
      }
    }
  }
`;

export { Container, MainSection, MainSectionTwo };
