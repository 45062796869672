import styled from "styled-components";

export const LoginPage = styled.div`
	max-width: 400px;
	width: 100%;
	margin: 0 auto;
	padding-top: 20%;
	.innerSection h3 {
		font-size: 32px;
		font-weight: bold;
		color: ${({ theme }) => theme.colors.loginTextA};
		padding-bottom: 10%;
	}
	.body {
	}
	.footer {
		margin-top: 30px;
	}
	.footer button {
		width: 100%;
		height: 50px;
	}
	.rememberSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		margin: 15px 0;
		font-family: "poppins";
	}
	.rememberSection label {
		font-weight: 400;
		color: ${({ theme }) => theme.colors.loginTextA};
	}
	.rememberSection > div {
		margin: 0px;
	}

	.field.googleButton {
		margin-top: 33px;
	}
	.authPage .fieldGroup {
		display: flex;
	}
	.authPage .fieldGroup .field {
		flex: 1;
	}
	.authPage .fieldGroup .field button {
		width: 95%;
	}
	.authPage .fieldGroup .field button div {
		background: none !important;
		padding: 0 !important;
		padding-left: 30% !important;
	}
	.authPage .fieldGroup .field button:hover {
		background: #eee !important;
	}
	.registerBox {
		font-size: 14px;
		margin: 5px 0;
		font-family: "museo_sans300";
		display: flex;
		justify-content: center;
	}
	.registerBox span {
		font-size: 20px;
		font: normal normal bold 20px/20px Poppins;
		letter-spacing: 0px;
		color: ${({ theme }) => theme.colors.loginTextB};
	}
	.mode--Dark span.account_span {
		color: #fff;
	}
`;

export const ForgotPassword = styled.div`
	font: normal normal bold 12px/17px Poppins;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.loginTextA};
	cursor: pointer;
`;
export const Register = styled.div`
	font: normal normal bold 20px/20px Poppins;
	letter-spacing: 0px;
	color: #157dff;
	margin-left: 8px;
	cursor: pointer;
`;
