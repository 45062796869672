import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import App from 'web/components/App/App';
import reducers from 'redux/reducers';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import reportWebVitals from './reportWebVitals';
import './web/styles/_form.scss';
import './web/styles/index.scss';

import * as themes from 'web/theme/schema.json';
import { setToLS } from 'web/utils/storage';

const store = createStore(reducers, applyMiddleware(thunk));

const Index = () => {
  setToLS('all-themes', themes.default);
  return <App />;
};
ReactDOM.render(
  <Provider store={store}>
    <Index />
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
