import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import TextInput from "web/components/form-elements/TextInput";
import NumberInput from "web/components/form-elements/NumberInput";
import Button from "web/components/Button";
import CloseIcon from "web/assets/images/CloseIcon.png";
import { showAppLoader, hideAppLoader } from "redux/actions/app";
import {
	getAuthInitialValues,
	VALIDATION_SCHEMA,
} from "web/components/utils/propertyModalUtils";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import "web/pages/private/Dashboard/PropertyModal/property.scss";
import { useDispatch } from "react-redux";
import MapContainer from "web/components/GoogleMaps2";
import CheckboxInput from "web/components/form-elements/CheckboxInput";
import CrossIcon from "web/assets/svg/CrossIcon"

const PropertyModal = ({
	isOpen,
	toggle,
	title,
	addressLineData,
	data,
	...props
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [formattedData, setFormattedData] = useState(data);
	const redirectLink = (link) => {
		toggle(!isOpen);
	};

	const verfiyAddress = async (data) => {
		const allAddressData = data;
		delete data.correctAddress;

		history.push({
			pathname: "/property-detail",
			state: { allAddressData: allAddressData },
		});
	};

	useEffect(() => {
		getFormattedAddress();
	}, []);

	const getFormattedAddress = async (value, setFieldValue) => {
		let newformattedData = {
			zipcode: null,
			state: null,
			city: null,
			addressLine1: null,
			addressLine2: null,
		};

		let addressData = value;
		if (!addressData?.results && !addressData?.results.length) {
			addressData = formattedData;
		}
		addressData?.results &&
			addressData?.results[0]?.address_components?.map((item) => {
				if (item.types[0] === "postal_code") {
					newformattedData.zipcode = item.long_name;
					if (setFieldValue) setFieldValue("zipCode", item.long_name);
				} else if (item.types[0] === "administrative_area_level_1") {
					newformattedData.state = item.long_name;
				} else if (item.types[0] === "locality") {
					newformattedData.city = item.long_name;
				} else if (
					item.types[2] === "sublocality_level_1" ||
					item.types[2] === "sublocality_level_2" ||
					item.types[0] === "street_number" ||
					item.types[0] === "premise " ||
					item.types[0] === "street_address"
				) {
					newformattedData.addressLine1 = item.long_name;
					if (setFieldValue) setFieldValue("addressLine1", item.long_name);
				} else if (item.types[0] === "route") {
					newformattedData.addressLine2 = item.long_name;
					if (setFieldValue) setFieldValue("addressLine2", item.long_name);
				}
			});
		await setFormattedData(newformattedData);
		getAuthInitialValues(newformattedData);
	};

	const getAddress = (value, setFieldValue) => {
		setFormattedData({});
		getFormattedAddress(value, setFieldValue);
	};
	return (
		<div className="property-modal">
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				backdrop={"static"}
				centered={true}
				className="propertyModal"
			>
				<div className="modal-content">
					<div className="header">
						{/* <Button
							handleClick={() => {
								redirectLink("");
							}}
						> */}
						<div className="CrossDiv">
							<CrossIcon onClick={() => {
								redirectLink("");
							}} />
							</div>
						{/* </Button> */}
					</div>
					<ModalBody>
						<div>
							<h3 className="modal-title">{title}</h3>
						</div>
						<div className="propertyContainer">
							<div className="mapSection">
								{formattedData && (
									<Formik
										initialValues={getAuthInitialValues(formattedData)}
										validationSchema={VALIDATION_SCHEMA}
										onSubmit={(values) => {
											verfiyAddress(values);
										}}
									>
										{(props) => {
											const { isValid, dirty, setFieldValue } = props;
											return (
												<>
													<div id="map_section" className="mapSection">
														<MapContainer
															lat={data?.lat?.lat}
															lng={data?.lat?.lng}
															getAddressData={(value) =>
																getAddress(value, setFieldValue)
															}
														/>
													</div>
													<Form className="formSection">
														<div className="map_fields">
															<TextInput
																name="addressLine1"
																placeholder="Address line 1*"
																showErrorMessages={true}
																onChange={(e) =>
																	setFormattedData({
																		...formattedData,
																		addressLine1: e.target.value,
																	})
																}
															/>
															<TextInput
																name="addressLine2"
																placeholder="Address line 2*"
																showErrorMessages={true}
																onChange={(e) =>
																	setFormattedData({
																		...formattedData,
																		addressLine2: e.target.value,
																	})
																}
															/>
															<TextInput
																name="city"
																placeholder="City*"
																showErrorMessages={true}
																onChange={(e) =>
																	setFormattedData({
																		...formattedData,
																		city: e.target.value,
																	})
																}
															/>
															<TextInput
																name="state"
																placeholder="State*"
																showErrorMessages={true}
																onChange={(e) =>
																	setFormattedData({
																		...formattedData,
																		state: e.target.value,
																	})
																}
															/>
															<NumberInput
																name="zipCode"
																placeholder="Zip Code*"
																showErrorMessages={true}
																maxLength="6"
																onChange={(e) =>
																	setFormattedData({
																		...formattedData,
																		zipcode: e.target.value,
																	})
																}
															/>
															<CheckboxInput
																name="correctAddress"
																label="Is this the correct property address?"
																handleSelect={(e) => {}}
															/>
															<ModalFooter className="modal-footer">
																{formattedData.addressLine2 !== null ? (
																	<Button
																		type="app-orange-button submit"
																		disabled={!(isValid && dirty)}
																	>
																		Verify
																	</Button>
																) : (
																	<Button
																		type="app-orange-button submit"
																		disabled={!(isValid && dirty)}
																	>
																		Verify
																	</Button>
																)}
															</ModalFooter>
														</div>
													</Form>
												</>
											);
										}}
									</Formik>
								)}
							</div>
						</div>
					</ModalBody>
				</div>
			</Modal>
		</div>
	);
};

export default PropertyModal;
