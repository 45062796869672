import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'web/components/Button';
import CloseIcon from 'web/assets/images/CloseIcon.png';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import 'web/components/AlertModal/alert.scss';


const AlertModal = ({ isOpen, toggle, title, content, link, postType }) => {
  let history = useHistory();
  const redirectLink = (link) => {
    if (link === 'manageAccount') {
      window.location.reload(false);
    } else {
      if (link) {
        if (postType) {
          history.push({
            pathname: link,
            search: '?postType=' + postType.toString(),
          });
        } else {
          history.push(link);
        }
      }
    }
    toggle(!isOpen);
  };
  return (
    <div className='alert-modal'>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={'static'}
        centered={true}
        className='alertModal'>
        <div className='modal-content'>
          {/* <div className='header'>
            <Button
              handleClick={() => {
                redirectLink(link);
              }}>
              <img src={CloseIcon} className='closeIcon' alt='close-icon' />
            </Button>
          </div> */}
          <ModalBody>
            <div>
              <h3 className='modal-title'>{title}</h3>
            </div>
            <div className=''>
              <p>{content}</p>
            </div>
          </ModalBody>
          <ModalFooter className='modal-footer'>
            <Button
              type='app-green-button submitBtn'
              handleClick={() => {
                redirectLink(link);
              }}>
              OK
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default AlertModal;
