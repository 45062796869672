import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from 'web/components/Button';
import blackLogo from 'web/assets/images/blackLogo.png';
import { CustomNavbar } from 'web/components/PublicNavbar/styles';

const PublicNavbar = () => {
  let history = useHistory();

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);

  let token = localStorage.getItem('userInfo');
  const closeMobileMenu = () => {
    if (token === null) {
      history.push('/');
    } else {
      history.push('/dashboard');
    }
    setClick(false);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  return (
    <>
      <CustomNavbar>
        <nav className='navbar'>
          <div className='navbar-container'>
            <img
              src={blackLogo}
              width='170px'
              height='auto'
              onClick={closeMobileMenu}
              alt='logo'
            />
            <ul className='right-menu'>
              {history?.location?.pathname === '/contact' ? (
                ''
              ) : (
                <li
                  onClick={() => history.push('/contact')}
                  className='contactUs'>
                  Contact Us
                </li>
              )}

              <li className='nav-item'>
                <Button
                  handleClick={() => history.push('/login')}
                  type='app-orange-button submitBtn'>
                  Log In/Register
                </Button>
              </li>
            </ul>
          </div>
        </nav>
      </CustomNavbar>
    </>
  );
};

export default PublicNavbar;
