import {
  EDIT_PROFILE,
  userInitiate,
  GET_PROFILE,
  EDIT_PASSWORD,
} from 'redux/actions/action_types';

const INITIAL_STATE = {
  user: null,
  userManagement: null,
  profileData: null,
  changePassword: null,
};
const userManagement = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case userInitiate:
      return INITIAL_STATE;
    case EDIT_PROFILE:
      return { ...state, userManagement: payload };
    case GET_PROFILE:
      return { ...state, profileData: payload };
    case EDIT_PASSWORD:
      return { ...state, changePassword: payload };
    default:
      return state;
  }
};
export default userManagement;
