import styled from 'styled-components';

const MainSection = styled.div`
  float: left;
  .sectionUnderCard {
    display: flex;
    padding-top: 131px;
    padding-left: 20px;
  }
  .statusSection {
    display: flex;
    align-items: center;
  }
  .subText {
    padding-left: 15px;
    font-size: 22px;
    color: #000;
    font-family: 'Poppins';
    padding-right: 42px;
  }
`;

export { MainSection };
