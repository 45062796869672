import 'date-fns';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Field } from 'formik';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';

export const customTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E4582A !important',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000 !important',
        fontFamily: '"Do Hyeon", sans-serif',
        backgroundColor: 'white',
        borderRadius: '0px',
      },
      container: {
        backgroundColor: 'black',
      },
      daySelected: {
        backgroundColor: '#E45829',
        color: '#fff !important',
        borderRadius: '25px',
        '&:hover': {
          background: '#E45829 !important',
        },
      },
      dayActive: {
        backgroundColor: '#fff',
        color: '#fff',
        borderRadius: '25px',
      },
      dayDisabled: {
        color: 'black',
      },
      dayDisabled: {
        color: 'black',
        backgroundColor: '#fff',
      },
      current: {
        color: '',
      },
      label: {
        color: '#000',
      },
      labelSelected: {
        backgroundColor: '#000',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#000',
        textAlign: 'center',
      },
    },
  },
});
const ReactCalender = ({ disabled, name, placeholder, onBlur = () => {} }) => {
  const [value, onChange] = useState();
  const handleChange = (name, setFieldValue, date) => {
    let time = date?.toLocaleDateString('en-US');
    onChange(time);
    setFieldValue(name, time);
    onBlur(time);
  };
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              placeholder={placeholder}
              margin='normal'
              id='date-picker-dialog'
              inputVariant='outlined'
              format='MM/dd/yyyy'
              minDate={new Date()}
              value={field?.value || null}
              onChange={(date) => {
                handleChange(name, form.setFieldValue, date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        );
      }}
    </Field>
  );
};
export default ReactCalender;
