import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { MainSection } from './style';
import TextInput from 'web/components/use-form/TextInput';

const GoogleAddressComponent = ({
  value,
  onChange,
  inputRef,
  name,
  onSelect,
  onClick,
  onSuggestionClick,
}) => {
  const searchOptions = {
    componentRestrictions: { country: ['us'] },
  };

  return (
    <MainSection>
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={value}
        onClick={onClick}
        onChange={onChange}
        onSelect={onSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='googleAddressBar'>
            <TextInput
              name={name}
              inputRef={inputRef}
              id='property_group'
              {...getInputProps({
                placeholder: 'Enter property address',
              })}
            />
            <div className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}>
                    <span
                      onClick={() => onSuggestionClick(suggestion.description)}>
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </MainSection>
  );
};
export default GoogleAddressComponent;
