import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import "web/components/Button/button.scss";

const Button = ({ handleClick, type, children, disabled, id }) => {
  return (
    <button
      type="submit"
      id={id}
      className={`${type ? `button ${type}` : "button"}`}
      onClick={(e) => _.isFunction(handleClick) && handleClick(e)}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

Button.defaultProps = {
  id: "",
};

export default Button;
