import React, { useState } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import MapGeoCode from 'web/components/GoogleMaps2/GeoCode/index';

export const MapContainer = ({ lat, lng, getAddressData }) => {

  const [getCordinates, setCordinates] = useState({});
  const getAddressValue = (value) => {
    getAddressData(value);
  };
  const mapLoaded = (mapProps, map)=> {
    map.setOptions({
      tilt : 0
    })
 }
  return (
    <>
      <Map
        onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        google={window.google}
        initialCenter={{ lat: lat, lng: lng }}
        zoom={18}>
        <Marker
          draggable={true}
          position={getCordinates.lat ? getCordinates : { lat: lat, lng: lng }}
          onDragend={(t, map, coord) =>
            setCordinates({ lat: coord.latLng.lat(), lng: coord.latLng.lng() })
          }
          onClick={null}
          name={'Current location'}
        />

        <InfoWindow onClose={null}>
          <div>
            <h1>map</h1>
          </div>
        </InfoWindow>
      </Map>

      {getCordinates.lat && (
        <MapGeoCode data={getCordinates} getAddress={getAddressValue} />
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCvCHq2kxo2JnYPTy9rciSjpIKrXr_8Ytk',
})(MapContainer);
