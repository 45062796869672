import React from "react";
import Button from "web/components/Button";
import CloseIcon from "web/assets/svg/CrossIcon";
import { Modal, ModalBody } from "reactstrap";
import Congrats from "web/assets/images/check.svg";
import { CloseSection } from "web/components/CongratulationsScreen/style";
import { useHistory } from "react-router-dom";

const PropertyModal = ({ isOpen, toggle, title }) => {
	const history = useHistory();

	return (
		<>
			<div className="property-modal">
				<Modal
					isOpen={isOpen}
					toggle={toggle}
					backdrop={"static"}
					centered={true}
					className="propertyModal"
				>
					<div className="modal-content">
						<div className="header">
							<CloseSection>
								<CloseIcon
									onClick={() => {
										history.push("/dashboard");
									}}
								></CloseIcon>
							</CloseSection>
						</div>
						<ModalBody>
							<div>
								<h3 className="modal-title">{title}</h3>
							</div>

							<div
								style={{ paddingTop: 25, paddingBottom: 70 }}
								className="comingSoon"
							>
								<img width="250px" src={Congrats} alt="Congrats" />

								<h2 style={{ paddingTop: 20 }}>
									Congratulations! Your property has been uploaded for review.
								</h2>
							</div>
						</ModalBody>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default PropertyModal;
