import React from 'react';
import { Field } from 'formik';

const Textarea = ({
  name,
  placeholder,
  showErrorMessages = true,
  disabled,
  inputRef,
  rows,
  onBlur = () => { },
}) => {
  if (disabled === undefined) disabled = false;
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className='app-input-text'>
          <textarea
            onBlurCapture={(values) => {
              onBlur(values.target.value)
            }}
            className={`${meta.touched && meta.error ? 'error' : ''}`}
            name={field.name}
            ref={inputRef}
            disabled={disabled}
            placeholder={placeholder}
            value={field.value || ''}
            rows={rows}
            {...field}
          />
          {meta.touched && meta.error && showErrorMessages && (
            <p className='error-message'>{meta.error}</p>
          )}
        </div>
      )}
    </Field>
  );
};

export default Textarea;
