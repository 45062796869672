import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  > div:first-child {
    max-width: 210px;
    width: 100%;
  }
  .selectFireplace {
    width: 46%;
    > div {
      margin-top: 10px;
    }
  }
`;
