import React, { useEffect } from 'react';
import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyCvCHq2kxo2JnYPTy9rciSjpIKrXr_8Ytk');

Geocode.setLanguage('en');

Geocode.setRegion('US');

Geocode.setLocationType('ROOFTOP');

Geocode.enableDebug();

const MapGeoCode = (props) => {
  const { data, getAddress } = props;

  const getCords = () => {
    Geocode.fromLatLng(data.lat, data.lng).then(
      (response) => {
        getAddress(response);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getCords(data);
  }, [data]);
  return <></>;
};
export default MapGeoCode;
