import * as React from "react";

const FacebookIcon = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...props}>
			<defs>
				<filter
					id="Ellipse_133"
					x={0}
					y={0}
					width={68}
					height={68}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={3} result="blur" />
					<feFlood floodColor="#d1d1d1" floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="Component 75 \u2013 6">
				<g filter="url(#Ellipse_133)">
					<circle
						data-name="Ellipse 133"
						cx={25}
						cy={25}
						r={25}
						transform="translate(9 6)"
						fill="#e25728"
					/>
				</g>
				<path
					data-name="Icon awesome-facebook-square"
					d="M42.609 19H24.473A2.473 2.473 0 0022 21.473v18.136a2.473 2.473 0 002.473 2.473h7.072v-7.847H28.3v-3.694h3.245v-2.815c0-3.202 1.906-4.976 4.826-4.976a19.664 19.664 0 012.861.249v3.151H37.62a1.847 1.847 0 00-2.083 2v2.4h3.544l-.567 3.694h-2.976v7.847h7.071a2.473 2.473 0 002.473-2.473V21.473A2.473 2.473 0 0042.609 19z"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default FacebookIcon;
