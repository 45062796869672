import { combineReducers } from 'redux';
import authReducer from 'redux/reducers/authReducer';
import appReducer from 'redux/reducers/appReducer';
import facebookGoogle from 'redux/reducers/facebookGoogle';
import property from 'redux/reducers/property';
import userManagement from 'redux/reducers/userManagement';
import appearanceMode from 'redux/reducers/appearanceMode';

export default combineReducers({
  authReducer,
  appReducer,
  facebookGoogle,
  property,
  userManagement,
  appearanceMode
});
