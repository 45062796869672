import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backIcon from "web/assets/images/backIcon.png";
import TextInput from "web/components/form-elements/TextInput";
import Button from "web/components/Button";
import Spinner from "web/components/Spinner";
import AlertModal from "web/components/AlertModal";
import AccountContainer from "web/components/AccountContainer";
import Layout from "web/components/Layout";
import EmailIcon from "web/assets/svg/EmailIcon";
import { IconInputField } from "web/styles/Global";
import { forgotPassword } from "redux/actions/auth";
import { ForgotPasswordPage } from "web/pages/account/ForgotPassword/styles";
import BackButton from "web/assets/svg/BackButton.js";

const ForgotPassword = () => {
	const loaderVisible = useSelector(
		(state: any) => state.appReducer.loaderVisible
	);

	let history = useHistory();
	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [modalLink, setModalLink] = useState("/");
	const toggleModal = () => setModal(!modal);
	let title = "";

	const saveHandler = async (props) => {
		let obj = {
			email: props.email,
		};
		const response = await dispatch(forgotPassword(obj));
		if (!response.error) {
			setModalContent("Reset link sent successfully.");
			setModalLink("");
			toggleModal();
		} else {
			if (response?.data.status === 404) {
				setModalContent("User not found.");
				setModalLink("");
				toggleModal();
			} else {
				setModalContent(response?.data.message);
				setModalLink("");
				toggleModal();
			}
		}
	};

	const INITIAL_VALUES = {
		email: "",
	};
	const VALIDATION_SCHEMA = Yup.object().shape({
		email: Yup.string()
			.email("Please enter a valid email.")
			.required("Email is required."),
	});
	const formFields = () => {
		return (
			<ForgotPasswordPage>
				<Formik
					initialValues={INITIAL_VALUES}
					validationSchema={VALIDATION_SCHEMA}
					onSubmit={(values) => {
						saveHandler(values);
					}}
				>
					{(props) => {
						const { isValid, dirty } = props;
						return (
							<>
								<Form className="form">
									<div className="innerSection">
										<div>
											<BackButton
												onClick={() => {
													history.push("/login");
												}}
											/>
											{/* <img
												className="headerLogo"
												src={backIcon}
												style={{ cursor: "pointer", marginLeft: -5 }}
												alt="logo"
												onClick={() => {
													history.push("/login");
												}}
											/> */}
											<h3>Enter Your Email Address</h3>
											<h6>
												Enter your email address associated with your account
											</h6>
											<IconInputField>
												<TextInput
													name="email"
													placeholder="Email"
													showErrorMessages={true}
												/>
												<EmailIcon className="emailIcon" />
											</IconInputField>

											<div className="footer">
												<Button
													type="app-orange-button submitBtn"
													disabled={!(isValid && dirty)}
												>
													Send
												</Button>
											</div>
										</div>
									</div>
								</Form>
							</>
						);
					}}
				</Formik>

				<AlertModal
					isOpen={modal}
					toggle={toggleModal}
					title={title}
					content={modalContent}
					link={modalLink}
				/>
			</ForgotPasswordPage>
		);
	};
	return (
		<Layout transparentHeader={false}>
			<AccountContainer>
				{loaderVisible && <Spinner />}
				{formFields()}
			</AccountContainer>
		</Layout>
	);
};

export default ForgotPassword;
