import styled from 'styled-components';

const ButtonSection = styled.div`
  .buttonsSection {
    text-align: -webkit-left;
  }
  .SubHeaderSecond {
    font-size: 18px;
    text-align: left;
    padding: 8px 0 8px;
  }
  .First_btn {
    background: none;
    border: 1px solid #e45829;
    border-radius: 4px;
    // width: fit-content;
    padding: 10px 30px;
    height: 47px;
    color: #8b8ea7;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 10px 19px 10px 0;
  }
  .First_btn:hover {
    background: none;
  }
  .selected {
    background: #e4582a;
    border: 1px solid #e45829;
    border-radius: 4px;
    // width: 194px;
    height: 47px;
    color: #fff;
    font-size: 14px;
    padding: 10px 30px;
    text-transform: capitalize;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 10px 19px 10px 0;
  }
  .selected:hover {
    background: #e4582a;
  }
`;

export { ButtonSection };
