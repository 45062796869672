import React, { useState, useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from 'web/components/form-elements/TextInput';
import Button from 'web/components/Button';
import Spinner from 'web/components/Spinner';
import AlertModal from 'web/components/AlertModal';
import { changePassword } from 'redux/actions/auth'
import AccountContainer from 'web/components/AccountContainer';
import Layout from 'web/components/Layout';
import { INITIAL_VALUES, VALIDATION_SCHEMA } from 'web/components/utils/createPassword';
import LockIcon from 'web/assets/svg/LockIcon';
import EndIcon from 'web/assets/svg/PasswordShowIcon';
import PasswordIconText from 'web/assets/svg/PasswordIconText';
import { IconInputField } from 'web/styles/Global';
import styles from 'web/pages/account/CreatePassword/createPassword.module.scss';


const CreatePassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [getParamState, setParamState] = useState({
    email: '',
    token: '',
  });

  const loaderVisible = useSelector(
    (state: any) => state.appReducer.loaderVisible
  );
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalLink, setModalLink] = useState('/');
  const toggleModal = () => setModal(!modal);
  let title = '';

  const fetchMyAPI = useCallback(async () => { }, []);

  useEffect(() => {
    const { location } = props;
    const queryParams = new URLSearchParams(location.search)
    for (const param of queryParams) {
    }

    setParamState({
      email: queryParams.get("email"),
      token: queryParams.get("token"),
    });
    fetchMyAPI()
  }, [fetchMyAPI]);

  const saveHandler = async (props) => {
    let obj = {
      password: props.password,
      token: getParamState.token,
      email: getParamState.email,
    }
    const response = await dispatch(changePassword(obj));
    if (!response?.error) {
      setModalContent('Password changed successfully.');
      setModalLink('/login');
      toggleModal();
    } else {
      setModalContent(response?.data?.data?.message);
      setModalLink('');
      toggleModal();
    }
  };

  const formFields = () => {
    return (
      <div className={styles.body}>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values) => { saveHandler(values) }}>
          {(props) => {
            const { isValid, dirty } = props;
            return (
              <>
                <Form className={styles.form}>
                  <div className={styles.innerSection}>
                    <div>
                      <h3>Create Password</h3>
                      <IconInputField>
                        <TextInput
                          name='password'
                          type={showPassword ? 'text' : 'password'}
                          placeholder='New Password&#42;'
                        />
                        <LockIcon className='startIcon' />
                        {!showPassword ? (
                          <EndIcon
                            className='passwordIcon'
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          />
                        ) : (
                            <PasswordIconText
                              className='passwordIcon'
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            />
                          )}
                      </IconInputField>
                      <IconInputField>
                        <TextInput
                          name='confirmPassword'
                          type={confirmPassword ? 'text' : 'password'}
                          placeholder='Confirm Password&#42;'
                        />
                        <LockIcon className='startIcon' />
                        {!confirmPassword ? (
                          <EndIcon
                            className='passwordIcon'
                            onClick={() => {
                              setConfirmPassword(!confirmPassword);
                            }}
                          />
                        ) : (
                            <PasswordIconText
                              className='passwordIcon'
                              onClick={() => {
                                setConfirmPassword(!confirmPassword);
                              }}
                            />
                          )}
                      </IconInputField>

                      <div className={styles.footer}>
                        <Button
                          type='app-orange-button submitBtn'
                          disabled={!(isValid && dirty)}>
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>

        <AlertModal
          isOpen={modal}
          toggle={toggleModal}
          title={title}
          content={modalContent}
          link={modalLink}
        />
      </div>
    );
  };
  return (
    <Layout transparentHeader={false}>
      <AccountContainer>
        {loaderVisible && <Spinner />}
        {formFields()}
      </AccountContainer>
    </Layout>
  );
};

export default CreatePassword;
