import styled from "styled-components";

const MainSection = styled.div`
	padding: 150px 0 50px 48px;
	width: 100%;
	.verifyBtn {
		background: #e45829 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 0px 8px 8px 0px;
		width: 180px;
		color: #fff;
		font-size: 18px;
		text-transform: capitalize;
		font-family: "Poppins";
		font-weight: 500;
		margin-left: -4px;
		margin-top: 25px;
		height: 57px;
	}
	.verifyBtn:hover {
		background: #e45829 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
	}
	.mode--Dark .Status_text {
		color: #fff !important;
	}
	.dashboard_verify_group > div {
		margin-top: 0;
	}
	.dashboard_verify_group {
		width: 40%;
		display: flex;
	}
	.cardSectionA {
		padding-top: 147px;
		display: flex;
	}
	.acceptedA {
		width: 30px;
		height: 30px;
		background-color: #27ae60;
		transition: transform 0.2s;
		border-radius: 20px;
		cursor: pointer;
	}
	.acceptedA:hover {
		transform: scale(1.5);
	}
	.passA {
		width: 30px;
		height: 30px;
		background-color: #ff7474;
		transition: transform 0.2s;
		border-radius: 20px;
		cursor: pointer;
	}
	.passA:hover {
		transform: scale(1.5);
	}

	.submittedA {
		width: 30px;
		height: 30px;
		background-color: #a4c1f8;
		transition: transform 0.2s;
		border-radius: 20px;
		cursor: pointer;
	}

	.submittedA:hover {
		transform: scale(1.5);
	}
	.under_reviewA {
		width: 30px;
		height: 30px;
		background-color: #f7ca64;
		transition: transform 0.2s;
		border-radius: 20px;
		cursor: pointer;
	}

	.under_reviewA:hover {
		transform: scale(1.5);
	}
	.property_cards {
		width: 267px;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 7px 20px #a3b7df21;
		border-radius: 8px;
		margin: 22px;
		position: relative;
	}
	.underReview {
		padding: 6px 10px;
		color: #fff;
		font-size: 12px;
		font-family: "Poppins";
		border-radius: 3px;
		position: absolute;
		margin-top: 8px;
		margin-left: 11px;
		text-transform: capitalize;
		font-weight: 600;
		background: #f7ca64;
		width: fit-content;
	}
	.underReview:hover {
		background: #f7ca64;
	}
	.accepted {
		padding: 6px 10px;
		color: #fff;
		font-size: 12px;
		font-family: "Poppins";
		border-radius: 3px;
		position: absolute;
		margin-top: 8px;
		margin-left: 11px;
		text-transform: capitalize;
		font-weight: 600;
		background: #27ae60;
		width: fit-content;
	}
	.accepted:hover {
		background: #27ae60;
	}
	.submitted {
		background: #a4c1f8;
		padding: 6px 10px;
		color: #fff;
		font-size: 12px;
		font-family: "Poppins";
		border-radius: 3px;
		position: absolute;
		margin-top: 8px;
		margin-left: 11px;
		text-transform: capitalize;
		font-weight: 600;
		width: fit-content;
	}
	.submitted:hover {
		background: #a4c1f8;
	}
	.pass {
		background: #ff7474;
		padding: 6px 10px;
		color: #fff;
		font-size: 12px;
		font-family: "Poppins";
		border-radius: 3px;
		position: absolute;
		margin-top: 8px;
		margin-left: 11px;
		text-transform: capitalize;
		font-weight: 600;
		width: fit-content;
	}
	.pass:hover {
		background: #ff7474;
	}

	.propertyImg {
		// width: 100%;
		width: 239px;
		height: 121px;
		border-radius: 8px;
	}
	.card_content {
		padding: 21px 0 0 0;
	}
	.cards_inner {
		padding: 14px;
	}
	.card_textA {
		font-size: 16px;
		font-family: "Poppins";
		font-weight: 500;
		color: #171340;
		display: block;
		white-space: nowrap;
		width: auto;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.card_textB {
		font-size: 14px;
		font-family: "Poppins";
		font-weight: 300;
		text-transform: capitalize;
		color: #8b8ea7;
		padding-top: 15px;
	}
	.card_textC {
		font-size: 24px;
		font-family: "Poppins";
		font-weight: 600;
		color: #171340;
		padding-top: 20px;
	}
	.outerSection {
		width: 100%;
	}
	.mapSection {
		width: 40%;
		float: left;
	}

	.form_feild_section {
		width: 75%;
		display: flex;
	}
	.card_section {
		margin-top: 53px;
	}
	.verifyBtn:disabled {
		pointer-events: all;
		cursor: not-allowed;
	}
	.verifyBtn:disabled:hover {
		background: #e45829;
	}
	.noPropertyHereText {
		text-align: center;
		p {
			font-size: 25px;
			font-weight: 500;
			padding-top: 110px;
		}
	}
`;

const StatusSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 0;
	.submittedLarge {
		width: 31px;
		height: 31px;
		background-color: #a4c1f8;
		transition: transform 0.2s;
		border-radius: 20px;
		cursor: pointer;
		border: 2px solid black;
	}
	.under_reviewLarge {
		width: 30px;
		height: 30px;
		background-color: #f7ca64;
		transition: transform 0.2s;
		border-radius: 20px;
		cursor: pointer;
		border: 2px solid black;
	}
	.showALl {
		cursor: pointer;
	}
	.showAllimgSection {
		transition: transform 0.2s;
	}

	.showAllimgSection:hover {
		transform: scale(1.5);
	}
	.Status_text {
		font-size: 22px;
		font-weight: 500;
		color: #000;
		font-family: "Poppins";
		padding: 0 40px 0 15px;
	}
	.section {
		display: flex;
	}
`;

export { MainSection, StatusSection };
