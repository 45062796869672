import {
  userInitiate,
  FACEBOOK_DATA,
  GOOGLE_DATA,
} from 'redux/actions/action_types';

const INITIAL_STATE = {
  user: null,
  fbUserData: null,
  googleUserData: null,
};

const facebookGoogle = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case userInitiate:
      return INITIAL_STATE;
    case FACEBOOK_DATA:
      return { ...state, fbUserData: payload };
    case GOOGLE_DATA:
      return { ...state, googleUserData: payload };
    default:
      return state;
  }
};
export default facebookGoogle;
