import React from 'react';
import { useSelector } from 'react-redux';
import { GUEST, SECURED, FIRST, PUBLIC } from 'web/components/Router/constants';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { isLogin } from 'security';
import PublicNavbar from 'web/components/PublicNavbar';
import Footer from 'web/components/Footer';
import Navbar from 'web/components/Navbar';
import fb from 'web/assets/images/facebook-icon.png';
import inst from 'web/assets/images/insta-icon.png';
import tw from 'web/assets/images/twitter-icon.png';

// to create custom routes

const Routers = ({ type, role, component: Component, ...rest }) => {
  const history = useHistory();
  const auth = useSelector((state) => state?.authReducer);
  const facebookGoogle = useSelector((state) => state?.facebookGoogle);
  const { push, goBack } = history;

  const routeProps = {
    goToRoute: push,
    goBack,
  };

  switch (type) {
    case SECURED: {
      if (
        facebookGoogle?.fbUserData ||
        facebookGoogle?.googleUserData ||
        auth.allUserData
      ) {
        return (
          <Route
            {...rest}
            render={(props) => (
              <div>
                <Component {...props} {...routeProps} />
              </div>
            )}
          />
        );
      } else {
        return <Redirect to={'/login'} />;
      }
    }
    case GUEST: {
      if (!isLogin()) {
        return (
          <Route
            {...rest}
            render={(props) => (
              <div>
                <Component {...props} {...routeProps} />
              </div>
            )}
          />
        );
      } else {
        return <Redirect to={'/dashboard'} />;
      }
    }
    case PUBLIC: {
      if (isLogin()) {
        return (
          <Route
            {...rest}
            render={(props) => (
              <div>
                <Navbar />
                <Component {...props} {...routeProps} />
                <Footer>
                  <Footer.Wrapper>
                    <Footer.Row>
                      <Footer.Column>
                        <Footer.Title>Company</Footer.Title>
                        {history.location.pathname !== '/contact' && (
                          <Footer.Link onClick={() => history.push('/contact')}>
                            Contact us
                          </Footer.Link>
                        )}
                      </Footer.Column>
                      <Footer.Column>
                        <Footer.Title>Contact</Footer.Title>
                        <Footer.Heading>info@ladbrook.com</Footer.Heading>
                        <Footer.Heading>(818) 851-9322</Footer.Heading>

                        <Footer.Heading>
                          <Footer.SocialIcon>
                            <img src={inst} alt='' />
                          </Footer.SocialIcon>
                          <Footer.SocialIcon>
                            <img src={fb} alt='' />
                          </Footer.SocialIcon>
                          <Footer.SocialIcon>
                            <img src={tw} alt='' />
                          </Footer.SocialIcon>
                        </Footer.Heading>
                      </Footer.Column>
                      <Footer.Column>
                        <Footer.Title>Address</Footer.Title>
                        <Footer.Heading>
                          31352 Via Colinas,
                          <br />
                          Suite 101, Westlake
                          <br /> Village, CA 91362
                        </Footer.Heading>
                        {/* <Footer.Heading></Footer.Heading> */}
                        {/* <Footer.Heading>91362</Footer.Heading> */}
                      </Footer.Column>
                    </Footer.Row>
                  </Footer.Wrapper>
                </Footer>
              </div>
            )}
          />
        );
      } else {
        return (
          <Route
            {...rest}
            render={(props) => (
              <div>
                <PublicNavbar />
                <Component {...props} {...routeProps} />
                <Footer>
                  <Footer.Wrapper>
                    <Footer.Row>
                      <Footer.Column>
                        <Footer.Title>Company</Footer.Title>
                        <Footer.Link>
                          <span onClick={() => history.push('/login')}>
                            Log In/Register
                          </span>
                        </Footer.Link>
                      </Footer.Column>
                      <Footer.Column>
                        <Footer.Title>Contact</Footer.Title>
                        <Footer.Link href='#'>info@ladbrook.com</Footer.Link>
                        <Footer.Link href='#'>(818) 851-9322</Footer.Link>

                        <Footer.Link href='#'>
                          <Footer.SocialIcon>
                            <img src={inst} alt='' />
                          </Footer.SocialIcon>
                          <Footer.SocialIcon>
                            <img src={fb} alt='' />
                          </Footer.SocialIcon>
                          <Footer.SocialIcon>
                            <img src={tw} alt='' />
                          </Footer.SocialIcon>
                        </Footer.Link>
                      </Footer.Column>
                      <Footer.Column>
                        <Footer.Title>Address</Footer.Title>
                        <Footer.Link href='#'>
                          31352 Via Colinas,
                          <br />
                          Suite 101, Westlake
                          <br /> Village, CA 91362
                        </Footer.Link>
                        {/* <Footer.Link href='#'></Footer.Link> */}
                        {/* <Footer.Link href='#'>91362</Footer.Link> */}
                      </Footer.Column>
                    </Footer.Row>
                  </Footer.Wrapper>
                </Footer>
              </div>
            )}
          />
        );
      }
    }
    case FIRST: {
      return <Redirect to={isLogin() ? '/dashboard' : '/'} />;
    }
    default: {
    }
  }
  return null;
};

export default Routers;
